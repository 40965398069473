import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import { EuiProvider } from "@elastic/eui"
import ConstantConfig from "./utils/constants.config"
import 'react-phone-input-2/lib/style.css'
import "react-notifications/lib/notifications.css"
import 'antd/dist/reset.css';
import '@elastic/eui/dist/eui_theme_light.css'
import './index.css'



ReactDOM.render(
  <BrowserRouter basename={ConstantConfig()?.BASE_PATH}>
    <EuiProvider colorMode="light">
      <App />
    </EuiProvider>
  </BrowserRouter>,
  document.getElementById("root")
)
