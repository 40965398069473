import create from "zustand";
import { persist } from "zustand/middleware";
import { PRESTATION } from "../utils/constants";
const store = (set) => ({
  dataType: PRESTATION,
  createDataType: (dataType) => set({ dataType }),
});

const dataTypeStore = create(
  persist(store, { name: "data-type-store" })
);

export default dataTypeStore;
