import React, { useState, useEffect } from 'react'
import favorisStore from '../stores/favorisStore'
import { isValidToken } from '../utils/utilsFunction'
import meStore from '../stores/meStore'
import tokenStore from '../stores/tokenStore'
import { FAVORIS_ROUTE } from '../utils/api.route'
import { NotificationManager } from 'react-notifications'
import { Spin } from 'antd'
import HomeCustumCard from '../components/HomeCustumCard'
import { ORANGE } from '../theme/couleurs'
import { PRESTATION } from '../utils/constants'


const HomeFavoris = () => {
    const favoris = favorisStore(state => state.favoris)
    const addFavoris = favorisStore(state => state.addFavoris)
    const me = meStore(state => state.me)
    const token = tokenStore(state => state.token)
    const [loadingFavoris, setLoadingFavoris] = useState(false)

    const loadFavoris = () => {
        setLoadingFavoris(true)
        fetch(FAVORIS_ROUTE.concat("?userId=").concat(me.id), {
            method: 'get',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer '.concat(token)
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.error)
                    throw new Error(response.message ? response.message : "Erreur de récupération de la liste des favoris !")
                addFavoris(response.favoris)
                setLoadingFavoris(false)
            })
            .catch(err => {
                setLoadingFavoris(false)
                NotificationManager.error(err.message)
            })
    }

    useEffect(() => {
        if (me && token && isValidToken(token)) {
            loadFavoris()
        }
    }, [token, me]);

    return (
        <div className="my-container" style={{ marginTop: "20px", minHeight: "80vh" }}  >
            <div className="fw-bold" style={{ color: ORANGE, fontSize: "16px", textDecoration: "underline" }}> Favoris</div>
            <div className="row">
                {loadingFavoris ? (
                    <div><Spin tip="Loading..." /></div>
                ) : favoris.length !== 0 ? favoris.map(item => (
                    <div key={item.id} className="col-xs-1 col-sm-6 col-md-4 col-lg-3 col-xxl-2 my-1">
                        <HomeCustumCard
                            type={PRESTATION}
                            hasFavoris={true}
                            service={{ ...item.prestation, user: { ...item.user, image: item.user.userImage } }}
                            loadFavoris={loadFavoris}
                        />
                    </div>
                ))
                    : <div> Vous n'avez pas de favoris disponible ! </div>}
            </div>
        </div>
    )
}




export default HomeFavoris