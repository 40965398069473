import { Select, Button, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useParams } from 'react-router'
import tokenStore from '../stores/tokenStore'
import PageNotFound from './404'
import { BASE_PATH, LISTE_USERS } from '../utils/api.route'
import { ORANGE, RED } from '../theme/couleurs'

const AdminDetailUserAdministrateurPage = () => {
    const { userId } = useParams()
    const token = tokenStore(state => state.token)
    const [user, setUser] = useState(null)
    const [profilStatus, setProfilStatus] = useState(null)

    const [loadingUser, setLoadingUser] = useState(false)
    const [loadingChangeProfil, setLoadingChangeProfil] = useState(false)
    const [loadingChangeAccountStatus, setLoadingChangeAccountStatus] = useState(false)

    const loadUserDetail = () => {
        setLoadingUser(true)
        fetch(
            LISTE_USERS
                .concat('/')
                .concat(userId)
                .concat("/admin"),
            {
                method: "get",
                headers: {
                    "Authorization": "Bearer ".concat(token)
                }
            }
        )
            .then(response => response.json())
            .then(response => {
                if (response.err)
                    throw new Error(response.message ? response.message : "Erreur de récuperation des informations de l'utilisateur ")
                setUser(response.user)
                setProfilStatus(response.user.profil.nom)
                setLoadingUser(false)
            })
            .catch(err => {
                setLoadingUser(false)
                
            })
    }

    const handleChangeProfil = (value) => {
        if (value) {
            setLoadingChangeProfil(true)
            fetch(
                LISTE_USERS.concat('/admin').concat('?id=').concat(userId),
                {
                    method: "put",
                    body: JSON.stringify({ profil: profilStatus }),
                    headers: {
                        "content-type": "application/json",
                        "Authorization": "Bearer ".concat(token)
                    }
                }
            )
                .then(response => response.json())
                .then(response => {
                    if (response.error)
                        throw new Error(response.message ? response.message : "Erreur de récuperation des informations de l'utilisateur ")
                    setProfilStatus(user.profil.nom)
                    setLoadingChangeProfil(false)
                    NotificationManager.success("Le profile à été changé avec success...")
                    loadUserDetail()
                })
                .catch(err => {
                    setLoadingChangeProfil(false)
                    
                })
        }
    }


    useEffect(() => {
        userId && token && loadUserDetail()
    }, [])

    return loadingUser ? <div className="w-100 text-center"> <Spin /></div> :
        user ? <div className="mt-2">
            <div style={{ fontSize: "16px", color: ORANGE, textDecoration: "underline", fontWeight: "bold" }}>Détail du profil</div>
            <div className="mt-2 bg-white border rounded-lg my-shadow p-2">
                <div className="row">
                    <div className="col-md-4 text-center">
                        <img src={user.userImage && user.userImage.url ? user.userImage.url : BASE_PATH.concat('/img/noProfile.jpg')} alt={user.userImage && user.userImage.nom} className="rounded-circle" style={{ objectFit: "cover", height: "200px", width: "200px" }} />
                    </div>
                    <div className="col-md-8 py-2">
                        <div >
                            <div><div style={{ fontSize: "16px" }} className="font-weight-bold"> {user.nom} {user.prenom} </div></div>
                            {user.precisionCompetence && <p> {user.precisionCompetence}</p>}
                            {
                                user.tels && <div>
                                    <div>Telephone:</div>
                                    <p className="text-muted">{user.tels} </p>
                                </div>
                            }
                            {
                                user.detail && <div>
                                    <div>Detail:</div>
                                    <p className="text-muted">{user.detail} </p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="d-flex mt-2">
                    <div className="mr-2"><Button>Désactiver le compte</Button></div>
                    <div className="mr-2"><Button style={{ background: RED, border: "none", color: "#fff" }}>Supprimer le compte</Button></div>
                    <div className="mr-2">
                        <Select value={profilStatus} onChange={(value) => setProfilStatus(value)} style={{ minWidth: "150px" }}>
                            <Select.Option value="VISITEUR">Visiteur</Select.Option>
                            <Select.Option value="USER">Prestataire</Select.Option>
                            <Select.Option value="ADMIN" >Administrateur</Select.Option>
                        </Select>
                        <span className="ml-1"><Button loading={loadingChangeProfil} onClick={handleChangeProfil}> Mèttre à jour</Button></span>
                    </div>
                </div>
            </div>
            <div className="mt-4 bg-white border rounded shadow-sm  p-2">
                Une autre section
            </div>
        </div>
            : <PageNotFound />
}


export default AdminDetailUserAdministrateurPage