import create from "zustand";
import { persist } from "zustand/middleware";

const store = (set) => ({
    services: [],
    addServices: services => set({ services }),
});

const panierStore = create(persist(store, { name: "services-panier-store" }));

export default panierStore;
