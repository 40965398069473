export const PRESTATION = "PRESTATION"
export const PRESTATAIRE = "PRESTATAIRE"
export const DEMANDEUR = "DEMANDEUR"
export const VARIATION = "VARIATION"

export const USER = 'USER'
export const VISITEUR = 'VISITEUR'
export const ADMIN = 'ADMIN'

export const CLIENT = "CLIENT"
export const SELLER = "SELLER"

export const BUSNESS = "BUSNESS"
export const OWNER = "OWNER"

export const NOUVELLE = "NOUVELLE"
export const REFUSER = "REFUSER"
export const CONFIRMER = "CONFIRMER"
export const ATTENTE = "ATTENTE"
export const TERMINER = "TERMINER"
export const COMMENCER = "COMMENCER"
export const PAUSE = "PAUSE"
export const ACCEPTER = "ACCEPTER"
export const DEMARRER = "DEMARRER"

export const POSITIVE = "POSITIVE"
export const NEGATIVE = "NEGATIVE"
export const NEUTRE = "NEUTRE"

export const TYPE_FEEDBACK_VENDEUR = "VENDEUR"
export const TYPE_FEEDBACK_CLIENT = "CLIENT"
export const TYPE_FEEDBACK_PRESTATION = "PRESTATION"

export const EXPLORER = "EXPLORER"
export const A_PROPOS = "A_PROPOS"
export const EVALUATION = "EVALUATION"

export const CHANGEMENT_DE_PROFIL = "CHANGEMENT_DE_PROFIL"
export const DEMANDE_DE_CERTIFICATION = "DEMANDE_DE_CERTIFICATION"

export const ALL = "ALL"
export const CHANGEMENT_PROFIL = 'CHANGEMENT_PROFIL'
export const NOUVELLE_COMMANDE = 'NOUVELLE_COMMANDE'
export const COMMANDE_REFUSER = 'COMMANDE_REFUSER'
export const COMMANDE_ACCEPTER = 'COMMANDE_ACCEPTER'
export const COMMANDE_TERMINER = 'COMMANDE_TERMINER'
export const SUGGESTION_PRESTATAIRE_DELAIS_DE_LIVRAISON_COMMANDE = 'SUGGESTION_PRESTATAIRE_DELAIS_DE_LIVRAISON_COMMANDE'
export const SUGGESTION_CLIENT_DELAIS_DE_LIVRAISON_COMMANDE = 'SUGGESTION_CLIENT_DELAIS_DE_LIVRAISON_COMMANDE'
export const SYSTEM = 'SYSTEM'

export const ASK_MESSAGE = "Demande de changement de profil visiteur en profil prestataire"
export const ASK_CERTIFICATION_MESSAGE = "Demande de Certification"


export const PRESTATIONS_ELEMENTS_SIZE = 60
export const PRESTATAIRES_ELEMENTS_SIZE = 60


export const APP_NAME  = "Afrika Talent"