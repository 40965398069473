import React from "react";
import { Button, Col, Row } from "antd";
const DeleteConfirmationPopup = ({ id, nom, handleDelete }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: 200,
      }}
    >
      <p>
        Vous-etes sur le point de supprimer:{" "}
        <span style={{ fontWeight: "bold" }}> {nom} </span> !
      </p>

      <p style={{fontWeight: "bold"}}>Voulez-vous vraiment continuer ? </p>

      <Row>
        <Col span={4} offset={20}>
          <Button size="small" onClick={() => handleDelete(id)} danger>
            Oui
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default DeleteConfirmationPopup;
