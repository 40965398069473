import React, { useState, useEffect } from "react"
import TopBar from '../TopBar'
import { Link, NavLink, Redirect, Route, useLocation } from "react-router-dom"
import Dashboard from "../../pages/AdminDashboard"
import Account from "../../pages/AdminAccount"
import Setting from "../../pages/AdminSetting"
import jwt from "jsonwebtoken"
import tokenStore from "../../stores/tokenStore"
import meStore from "../../stores/meStore"
import { Layout } from "antd"
import NotFound from "../../pages/404"
import AdminCommande from '../../pages/AdminCommande'
import AdminMessageList from "../../pages/AdminMessageList"
import AdminAdd from "../../pages/AdminAdd"
import AdminListes from "../../pages/AdminListes"
import AdminDemande from "../../pages/AdminDemande"
import AdminUser from '../../pages/AdminUser'
import AdminProfil from '../../pages/AdminProfile'
import { GrTasks } from "react-icons/gr"
import { AiOutlineUser } from "react-icons/ai"
import { GoDatabase } from "react-icons/go"
import { MdFormatListNumbered } from "react-icons/md"
import { BiCategory } from "react-icons/bi"
import { AiOutlineFileAdd } from "react-icons/ai"
import { FaUserCircle } from "react-icons/fa"
import { IoCodeDownloadSharp } from "react-icons/io5"
import { SiBuymeacoffee } from "react-icons/si"
import { GrNotes } from "react-icons/gr"
import { IoSettingsOutline } from "react-icons/io5"
import { FaRegQuestionCircle } from "react-icons/fa"
import AdminDetailCommande from '../../pages/AdminDetailCommande'
import AdminQuestion from "../../pages/AdminQuestion"
import { NotificationManager } from "react-notifications"
import { DEMANDE_ROUTE } from "../../utils/api.route"
import AdminDetails from "../../pages/AdminDetails"
import { connectToSocket, isValidToken } from "../../utils/utilsFunction"
import Domaine from "../../pages/AdminDomaine"

import socketStore from "../../stores/socketStore"

import { JAUNE, RED, GREEN } from "../../theme/couleurs"
import './AdminLayout.css'
import { ASK_MESSAGE, CHANGEMENT_DE_PROFIL, CHANGEMENT_PROFIL, DEMANDE_DE_CERTIFICATION } from "../../utils/constants"


const NavItem = ({ href, Icon, title }) => (
  <NavLink to={href} className="d-flex align-items-center admin-left-menu-item" activeClassName="admin-menu-item-active" >
    <span>
      {Icon && <Icon style={{ fontWeight: "none", fontSize: "16px" }} />}
      <span style={{ fontSize: 12, paddingLeft: 5 }}>{title}</span>
    </span>
  </NavLink>
);

const Sidebar = () => {
  const token = tokenStore(state => state.token);
  const decodedToken = jwt.decode(token);
  const profil = decodedToken?.profil;

  return (
    <Layout.Sider
      style={{ background: "#F1F1F1", paddingTop: 70, borderRight: "1px solid #cccccc60" }}
      breakpoint="lg"
      collapsedWidth="0"
    >
      <div className="container" style={{ position: 'sticky', top: 70 }}>

        {profil?.nom === "VISITEUR" &&
          (
            <NavItem
              href="/dashboard/commandes/demandeur"
              title="Mes commandes"
              Icon={GrNotes}
            />
          )
        }

        {profil?.nom === "USER" &&
          (
            <NavItem
              href="/dashboard/commandes/prestataire"
              title="Commandes reçues"
              Icon={SiBuymeacoffee}
            />
          )
        }
        {profil?.nom === "USER" &&
          (
            <NavItem
              href="/dashboard/commandes/demandeur"
              title="Mes commandes"
              Icon={GrNotes}
            />
          )
        }
        {profil?.nom === "ADMIN" &&
          (
            <NavItem
              href="/dashboard/profil"
              title="Profil"
              Icon={FaUserCircle}
            />
          )
        }

        {profil?.nom === "ADMIN" &&
          (
            <NavItem
              href="/dashboard/user"
              title="Utilisateur"
              Icon={AiOutlineUser}
            />
          )
        }

        {profil?.nom === "USER" &&
          (
            <>

              <div>
                <NavItem
                  href="/dashboard/add/service"
                  title="Nouvelle prestation"
                  Icon={AiOutlineFileAdd}
                />
                <NavItem
                  href="/dashboard/list/services"
                  title="Mes Prestations"
                  Icon={MdFormatListNumbered}
                />
              </div>
            </>
          )
        }

        {profil?.nom === "ADMIN" &&
          <NavItem
            href="/dashboard/questions"
            title="Questions"
            Icon={FaRegQuestionCircle}
          />
        }

        {profil?.nom === "ADMIN" &&
          <NavItem
            href="/dashboard/demandes"
            title="Demandes"
            Icon={FaRegQuestionCircle}
          />
        }

        {profil?.nom === "ADMIN" &&
          <NavItem
            href="/dashboard/domaines"
            title="Categories"
            Icon={BiCategory}
          />
        }

        {profil?.nom === "ADMIN" &&
          <>
            <div data-bs-toggle="collapse" data-bs-target="#setting_menu" >
              <NavItem
                href="/dashboard/settings"
                title="Configuration"
                Icon={IoSettingsOutline}
              />
            </div>
            <div className="collapse bg-light rounded border" id="setting_menu" >
              <NavItem
                href="/dashboard/settings/pubs"
                title="Publicité"
                Icon={IoCodeDownloadSharp}
              />

              <NavItem
                href="/dashboard/settings/db"
                title="Base de donnée"
                Icon={GoDatabase}
              />
            </div>
          </>
        }
      </div>
    </Layout.Sider>
  );
};

const AdminFooter = () => <div className="home-footer" >
  <div
    style={{
      textAlign: "center",
      borderTop: "1px solid #cccccc90",
    }}
  >
    <p className="text-white">© 2021 - 2025 Copyright AfrikaTalent</p>
  </div>
</div>

const ChangeProfil = () => {
  const token = tokenStore(state => state.token)
  const me = meStore(state => state.me)
  const [loadingUpdateDemande, setLoadingUpdateDemande] = useState(false)
  const [loadingDemande, setLoadingDemande] = useState(false)
  const [currentDemande, setCurrentDemande] = useState(null)

  const handleDemande = () => {
    setLoadingUpdateDemande(true)
    fetch(DEMANDE_ROUTE, {
      method: "post",
      body: JSON.stringify({
        userId: me?.id,
        message: ASK_MESSAGE,
        type: CHANGEMENT_DE_PROFIL
      }),
      headers: {
        "content-type": "application/json",
        "Authorization": "Bearer " + token
      }
    })
      .then(response => response.json())
      .then(res => {
        if (res.error)
          throw res

        setLoadingUpdateDemande(false)
        loadDemandeUser()
        NotificationManager.success("Votre demande à bien été envoyée !",null, 9000)
      })
      .catch(err => {
        setLoadingUpdateDemande(false)
        NotificationManager.error(err.message, null, 9000)
      })
  }

  const loadDemandeUser = () => {
    setLoadingDemande(true)
    fetch(DEMANDE_ROUTE
      .concat("/")
      .concat(me.id)
      .concat('?type='.concat(CHANGEMENT_DE_PROFIL)), {
      method: "get",
      headers: {
        "Authorization": "Bearer " + token
      }
    })
      .then(response => response.json())
      .then(res => {
        if (res.error)
          throw new Error(res.message ? res.message : "Erreur lors de l'envoie !")
        setCurrentDemande(res.demande)
        setLoadingDemande(false)
      })
      .catch(err => {
        setLoadingDemande(false)
        NotificationManager.error(err.message,null, 9000)
      })
  }

  const handleCancleDemande = () => {
    if (currentDemande) {
      setLoadingUpdateDemande(true)
      fetch(DEMANDE_ROUTE.concat('/').concat(currentDemande.id), {
        method: "delete",
        headers: {
          "Authorization": "Bearer " + token
        }
      })
        .then(response => response.json())
        .then(res => {
          if (res.error)
            throw new Error(res.message ? res.message : "Erreur d'annulation !")
          setLoadingUpdateDemande(false)
          loadDemandeUser()
          NotificationManager.success("La demande à été annuler !",null, 9000)
        })
        .catch(err => {
          setLoadingUpdateDemande(false)
          NotificationManager.error(err.message, null,9000)
        })
    }
  }


  const DisplaySendDemande = () => me && <div style={{ background: JAUNE.concat(40), maxWidth: "900px", padding: "20px", border: "1px solid ".concat(JAUNE) }}>
    <div>
      <div style={{ fontWeight: "bold", fontSize: "16px", marginBottom: "10px" }}> Je veux devenir prestataire</div>
      <div className="mt-2">En devenant Prestataire, vous aurez la possibilité de créer et de publier des services sur la plateforme. Cliquer sur le boutton <span className="fw-bold"> ci-dessous</span>
        , afin que votre compte soit mise à jour .</div>
    </div>
    <button className="btn mt-3" style={{ background: '#fff', color: "#000", fontWeight: "bold", border: '1px solid '.concat(JAUNE) }} onClick={handleDemande} disabled={loadingUpdateDemande ? true : false} > <span>Envoyer maintenant la demande</span> {loadingUpdateDemande && <span className="my-spinner spinner-red ms-2"> </span>} </button>
  </div>

  const DisplayCancelDemande = () => me && <div className="alert my-2" style={{ background: GREEN.concat(40), maxWidth: "900px", padding: "20px", border: "1px solid ".concat(GREEN) }}>
    <div>
      <div style={{ fontWeight: "bold", fontSize: "16px", marginBottom: "10px" }}> Je veux devenir prestataire</div>
      <div className="mt-2">Votre demande à été envoyer avec success. Elle sera traitée dans les 48 heures. Une fois que votre demande serai traité, vous aurez une notification via votre adresse mail </div>
    </div>
    <button className="btn mt-2" style={{ background: '#fff', color: "#000", fontWeight: "bold", border: '1px solid '.concat(GREEN) }} onClick={handleCancleDemande} disabled={loadingUpdateDemande ? true : false} > <span>Annuler ma demande</span> {loadingUpdateDemande && <span className="my-spinner spinner-white ms-2"> </span>} </button>
  </div>

  useEffect(() => {
    loadDemandeUser()
  }, []);

  if (currentDemande && currentDemande.status !== "REFUSER") {
    return me && <DisplayCancelDemande />
  }

  if (currentDemande && currentDemande.status === "REFUSER") {
    return me && <DisplaySendDemande />
  }

  if (!currentDemande) {
    return me && <DisplaySendDemande />
  }

  return <></>
}

const AdminLayout = () => {
  const token = tokenStore(state => state.token)
  const me = meStore(state => state.me)
  const location = useLocation()
  const createSocket = socketStore(state => state.createSocket)


  connectToSocket(me, createSocket)


  const NoCompetencesPage = () => <div style={{ width: "100%", height: "100%", background: "#ffffff", display: "flex", justifyContent: "center", alignItems: "center" }}>
    <div style={{ padding: 10, borderRadius: 10, border: "1px solid #ccc" }}>
      <p>
        Vous devez completer certaines de vos informations avant de continuer cette opération en occurence  [ COMPTÉTENCES, NOM, PRENOM , PSEUDO ]
      </p>
      <div className="d-flex">
        <Link to="/">retourner sur la page d'accueil</Link>
        <Link to="/dashboard/compte">Mètre à jour mon profil</Link>
      </div>
    </div>
  </div>

  return token && isValidToken(token) ?
    (
      <Layout style={{ background: "#FFFFFF" }}>
        <TopBar />
        <Sidebar />
        <Layout.Content>
          <div
            style={{
              padding: "70px 10px",
              minHeight: "100vh"
            }}
          >
            {me?.profil?.nom === "VISITEUR" && <div>
              <ChangeProfil />
            </div>}
            <Route exact path="/dashboard/" component={Dashboard} />
            {me?.profil?.nom === "ADMIN" && <Route exact path="/dashboard/user" component={AdminUser} />}
            {me?.profil?.nom === "ADMIN" && <Route exact path="/dashboard/profil" component={AdminProfil} />}
            {me?.profil?.nom === "ADMIN" && <Route exact path="/dashboard/questions" component={AdminQuestion} />}
            {me?.profil?.nom === "ADMIN" && <Route exact path="/dashboard/demandes" component={AdminDemande} />}
            <Route
              path="/dashboard/messages/:nom"
              component={AdminMessageList}
            />
            <Route exact path="/dashboard/commandes/prestataire" component={() => me?.competences?.length === 0 && me?.profil?.nom === "USER" ? <NoCompetencesPage /> : <AdminCommande title="Commandes Reçues" typeUser="PRESTATAIRE" />} />
            <Route exact path="/dashboard/commandes/demandeur" component={() => me?.competences?.length === 0 && me?.profil?.nom === "USER" ? <NoCompetencesPage /> : <AdminCommande title="Mes commandes" typeUser="DEMANDEUR" />} />
            <Route exact path="/dashboard/commandes/details/:id/:typeUser" component={AdminDetailCommande} />
            <Route path="/dashboard/add" component={AdminAdd} />
            <Route path="/dashboard/list" component={AdminListes} />
            <Route path="/dashboard/detail" component={AdminDetails} />
            <Route exact path="/dashboard/compte" component={Account} />
            <Route path="/dashboard/settings/:page" component={Setting} />
            <Route exact path="/dashboard/domaines" component={Domaine} />
            <Route exact path="/dashboard/notFound" component={NotFound} />
          </div>
        </Layout.Content>
      </Layout>
    ) :
    (
      <Redirect
        to={{ pathname: "/login", state: { from: location.pathname } }}
      />
    )
}

export default AdminLayout
