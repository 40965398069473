
import React from "react"
import { BASE_PATH } from "../utils/api.route"
import { BsFacebook } from 'react-icons/bs'
import { FiYoutube } from 'react-icons/fi'
import { AiOutlineInstagram } from 'react-icons/ai'
import { RiTwitterLine } from 'react-icons/ri'
import { BLUE, DARK_GRAY, GREEN, ORANGE, RED } from "../theme/couleurs"
import { Link } from "react-router-dom"
import moment from "moment"

import '../pages/Home.css'
import { APP_NAME } from "../utils/constants"



const HomeFooter = () => {

    const DisplayBecomeOffer = () => <div className="my-container px-3 py-5 home-become-offer text-white text-center rounded" >
        <h1 style={{ fontSize: "25px" }} className="text-white font-weight-bold">Devenir prestataire</h1>
        <div className="text-white" style={{ fontSize: "15px" }}> Rejoignez les autres prestataires en s'inscrivant pour un compte prestataire.</div>
        <div className="text-white" style={{ fontSize: "15px" }}> Offrez des prestations de services et partagez vos meilleurs réalisations en fin que les gens puissent s'avoir de quoi vous-êtes capable. </div>
        <button className="become-prestataire-btn" data-toggle="modal" data-target="#becomeOfferPopup" >M'inscrire pour devenir prestataire </button>
    </div>


    const DisplayFaqs = () => {
        return <div className="bg-white border py-3">
            <div className="my-container">
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>Foire Aux Questions </div>
                <div className="accordion accordion-flush mt-4" id="accordionFlushExample">
                    <div className="accordion-item">
                        <div className="accordion-header" id="flush-headingOne" style={{ backgroundColor: "#09BFB8", padding: "15px 5px" }}>
                            <div style={{ cursor: "pointer", fontWeight: "bold", color: "#000" }} className="collapsed" data-bs-toggle="collapse" data-bs-target="#devenirPrestataire" aria-expanded="false" aria-controls="devenirPrestataire">
                                Comment devenir Prestataire ?
                            </div>
                        </div>
                        <div id="devenirPrestataire" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                <div className="mt-2" style={{ fontWeight: "bold" }}>
                                    <div style={{ fontWeight: "bold" }}>
                                        Créer un Compte simple et faire une demande de changement de profil.</div>
                                    <div className="mt-2">
                                        Pour ce faire , vous allez dans les menus en Haut à droite de la page, vous cliquer sur <span style={{ fontWeight: "bold" }}> connexion </span> puis sur
                                        <span style={{ fontWeight: "bold" }} >  Inscrivez-vous. </span>
                                    </div>
                                    <span>Arriver sur la page d'inscription , vous remplisser les informations, puis vous saisisser le code de vérification pour finaliser l'étape de la création du compte. </span>
                                    <div >Une fois le compte creer, vous allez dans votre profil en cliquant sur <span style={{ fontWeight: "bold" }}> Mon profil </span> en Haut a droit dans la bar de navigation.</div>
                                    <div >En suite, remplisser tous les informations et cliquer sur le boutton <span style={{ fontWeight: "bold" }}> Mèttre à jour  </span> au niveau de chaque sections.</div>
                                    <div > Après cela , vous pouvez cliquer sur le boutton <span style={{ fontWeight: "bold" }}> Envoyer maintenant la demande.</span></div>

                                    <div className="mt-1"><span style={{ fontWeight: "bold" }}>NB:</span> Notez bien que,  Votre compte serait soumis à des vérifications en fin de permettre son activation</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mt-2">
                        <div className="accordion-header" id="flush-heading-creerPrestations" style={{ backgroundColor: "#09BFB8", padding: "15px 5px" }}>
                            <div style={{ cursor: "pointer", fontWeight: "bold", color: "#000" }} className="collapsed" data-bs-toggle="collapse" data-bs-target="#creerPrestations" aria-expanded="false" aria-controls="creerPrestations">
                                Comment créer ses Prestations de service ?
                            </div>
                        </div>
                        <div id="creerPrestations" className="accordion-collapse collapse" aria-labelledby="flush-heading-creerPrestations" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                <div style={{ fontWeight: "bold" }}> Pour créer ses prestations de service,</div>
                                <div>
                                    Vous vous-connectez avec votre compte prestataire, puis vous rentrer dans votre interface d'administration en cliquant, depuis la page d'accueil sur, <span className="fw-bold"> votre avatar </span>  , en suite sur <span style={{ fontWeight: "bold" }}>Administration</span>.
                                </div>
                                <div>
                                    Arriver sur cette page, vous cliquer dans le menu de gauche, sur <span style={{ fontWeight: "bold" }}> Service </span> , puis vous choisisser <span style={{ fontWeight: "bold" }} > Nouveau Service</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mt-2">
                        <div className="accordion-header" id="flush-heading-what-is-variation" style={{ backgroundColor: "#09BFB8", padding: "15px 5px" }}>
                            <div style={{ cursor: "pointer", fontWeight: "bold", color: "#000" }} className="collapsed" data-bs-toggle="collapse" data-bs-target="#what-is-variation" aria-expanded="false" aria-controls="what-is-variation">
                                C'est quoi une variation ?
                            </div>
                        </div>
                        <div id="what-is-variation" className="accordion-collapse collapse" aria-labelledby="flush-heading-what-is-variation" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                <div style={{ fontWeight: "bold" }}> Une variation dans {APP_NAME}, </div>
                                <div>C'est une prestation créée à partir d'une autre prestation ( prestation parent ).</div>
                                <div>exemple: fabrication des pains de 100fr ,200fr , 300fr, etc...</div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mt-2">
                        <div className="accordion-header" id="flush-heading-create-variation" style={{ backgroundColor: "#09BFB8", padding: "15px 5px" }}>
                            <div style={{ cursor: "pointer", fontWeight: "bold", color: "#000" }} className="collapsed" data-bs-toggle="collapse" data-bs-target="#create-variation" aria-expanded="false" aria-controls="create-variation">
                                Comment créer proprement ses variations ?
                            </div>
                        </div>
                        <div id="create-variation" className="accordion-collapse collapse" aria-labelledby="flush-heading-create-variation" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                <div style={{ fontWeight: "bold" }}> Pour créer ses prestations de service,</div>
                                <div>Allez dans votre page d'administration, puis vous cliquez sur <span className="fw-bold"> Nouvelle prestation</span> , et ensuite vous remplissez le formulaire en lisant attentivement les petites descriptions.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }


    const DisplayCGUPopup = () => <div id="cgu" className="modal fade" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title" style={{ fontSize: "16px" }}> Conditions général d'utilisation et Politique de confidentialité </h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" style={{ fontSize: "18px" }}>&times; </span>
                    </button>
                </div>
                <div className="modal-body">
                    Condition général d'utilisation
                </div>
                <div className="modal-footer">
                    <button className="btn btn-sm" data-dismiss="modal" style={{ border: "none", background: "#ccc", borderRadius: "10px", padding: "5px 10px" }} >Voir plus </button>
                    <button className="btn btn-sm" data-dismiss="modal" style={{ border: "none", background: ORANGE, color: "#fff", borderRadius: "10px", padding: "5px 10px" }} >Accepter</button>
                </div>
            </div>
        </div>
    </div>

    const DisplayFooterContent = () => <div className="border-top py-5" style={{ background: DARK_GRAY }} >
        <div className="my-container">
            <div className="row">
                <div className="col-md">
                    <h4 className="text-white">Offres</h4>
                    <div style={{ color: "#ffffff90" }}> Créations des applications desktop </div>
                    <div style={{ color: "#ffffff90" }}> Créations des applications android </div>
                    <div style={{ color: "#ffffff90" }}> Créations des sites web</div>
                    <div style={{ color: "#ffffff90" }}> Créations des applications web</div>
                </div>

                <div className="col-md">
                    <h4 className="text-white">A Propos </h4>
                    <div ><Link style={{ color: "#ffffff90" }} to="#">{APP_NAME}</Link></div>
                    <div ><a style={{ color: "#ffffff90" }} href="https://www.hispwca.org/" target="_blank">Hispwca</a></div>
                    <div ><Link style={{ color: "#ffffff90" }} to="#">Nous Contacter</Link></div>
                </div>
                <div className="col-md text-white">
                    <h4 className="text-white">Réseaux sociaux</h4>
                    <div>
                        <span className="me-2"><BsFacebook className="facebook-social" style={{ fontSize: "25px" }} /></span>
                        <span className="me-2"><FiYoutube className="youtube-social" style={{ fontSize: "25px" }} /></span>
                        <span className="me-2"><AiOutlineInstagram className="instagram-social" style={{ fontSize: "25px" }} /></span>
                        <span><RiTwitterLine className="twitter-social" style={{ fontSize: "25px" }} /></span>
                    </div>
                </div>
            </div>
        </div>
        <div className="pt-3 mt-3">
            <div className="my-container">
                <div className="row">
                    <div className="col-md text-white" >
                        <a href="#" style={{ color: "#ffffff90" }} data-toggle="modal" data-target="#cgu">Conditions d'utilisation</a>
                        <span className="mx-1">|</span>
                        <a href="#" style={{ color: "#ffffff90" }} data-toggle="modal" data-target="#cgu">Politique de confidentialité</a>
                    </div>
                    <div className="col-md text-white">
                        <a>&copy; {moment().format('YYYY')} Copyright Hisp West and Central Africa</a>
                    </div>
                    <div className="col-md text-white">
                        <a ><img src={BASE_PATH.concat("/img/playstore.png")} alt="liens playstore" style={{ width: "80px" }} /></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    return <div className="home-footer" >
        {/* <DisplayBecomeOffer /> */}
        {/* <DisplayCGUPopup /> */}
        {/* <BecomeOfferPopup /> */}
        {/* <DisplayFaqs /> */}
        <DisplayFooterContent />
    </div>
}


export default HomeFooter