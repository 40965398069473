import { FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa'


const MyPagination = ({
    currentPage,
    setCurrentPage,
    totalPages
}) => (
    <>
        <div className="d-flex justify-content-center align-items-center mt-2">
            <button
                disabled={currentPage + 1 === 1 ? true : false}
                onClick={() => {
                    if (currentPage + 1 > 1) {
                        setCurrentPage(currentPage - 1)
                    }
                }}
                style={{ border: "none", outline: "none", background: "transparent" }}
            >
                <FaLongArrowAltLeft
                    className="rounded"
                    style={{
                        fontSize: "30px",
                        color: "#fff",
                        background: (currentPage + 1 === 1) ? "#22222C50" : "#22222C",
                        padding: "5px", cursor: "pointer"
                    }
                    }
                />
            </button>
            <button
                disabled={currentPage + 1 === totalPages || currentPage + 1 > totalPages ? true : false}
                onClick={() => {
                    if (currentPage + 1 < totalPages) {
                        setCurrentPage(currentPage + 1)
                    }
                }}
                style={{ border: "none", outline: "none", background: "transparent" }}
            >
                <FaLongArrowAltRight
                    className="rounded ms-2"
                    style={{
                        fontSize: "30px",
                        color: "#fff",
                        background: (currentPage + 1 === totalPages || currentPage + 1 > totalPages) ? "#22222C50" : "#22222C",
                        padding: "5px",
                        cursor: "pointer"
                    }}
                />
            </button>
        </div>
    </>
)


export default MyPagination