import { Popover } from "antd";

const Popovers = ({ content, children }) => {
  return (
    <Popover title="Confirmation" content={content}>
      {children}
    </Popover>
  );
};

export default Popovers;
