import create from "zustand";
import { devtools, persist } from "zustand/middleware";

const store = (set) => ({
  me: null,
  createMe: (me) => set({ me }),
});

const meStore = create(persist(store, { name: "me-store" }));

export default meStore;
