import React, { useState, useEffect } from 'react'
import { Input, Form, Select, InputNumber, TreeSelect, Upload, Button, Table } from 'antd'
import { Route, useHistory } from 'react-router-dom'
import TextArea from 'antd/lib/input/TextArea'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { IMAGES_ROUTE, LISTE_INVITE_UNITES, LISTE_SERVICES } from '../utils/api.route'
import tokenStore from '../stores/tokenStore'
import jwt from 'jsonwebtoken'
import meStore from '../stores/meStore'
import { TreeNode } from 'antd/lib/tree-select'
import { LoadingOutlined } from '@ant-design/icons'
import { CgCloseO } from 'react-icons/cg'
import imageCatalogueListesStore from '../stores/imageCatalogueListesStore'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { FiEdit } from 'react-icons/fi'
import { NotificationManager } from 'react-notifications'
import { GRAY, GREEN, ORANGE, ORANGE_LIGNT, RED, WHITE } from '../theme/couleurs'
import { PRESTATION, VARIATION } from '../utils/constants'

const ImageListModale = ({ setSelectedImages }) => {

    const [selectedImagesListes, setSelectedImagesListes] = useState([])
    const imageCatalogueListes = imageCatalogueListesStore(state => state.imageCatalogueListes)

    const handleChoosedImages = (event) => {
        setSelectedImages(selectedImagesListes)
        event.target.setAttribute("data-dismiss", "modal")
        event.target.click()
        setSelectedImagesListes([])
    }

    return (
        <div className="modal fade" id="imageListModal" aria-labelledby="modale" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title fw-bold">Catalogues d'images </div>
                        <CgCloseO data-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            {imageCatalogueListes.length !== 0 ? imageCatalogueListes.map((item, index) => <div className="col-sm-3" style={{ maxWidth: 150, height: 102, border: "1px solid #ccc", borderRadius: "2px" }} key={index} onClick={() => {
                                if (selectedImagesListes.includes(item)) {
                                    return setSelectedImagesListes(selectedImagesListes.filter(e => e !== item))
                                }
                                return setSelectedImagesListes(selectedImagesListes.concat(item))
                            }}>
                                <img alt="" src={item?.url} style={{ width: "100%", border: selectedImagesListes.includes(item) ? "1px solid green" : "none", opacity: selectedImagesListes.includes(item) ? "0.6" : 1, height: "100%", width: "100%", objectFit: "cover" }} />
                            </div>) : <div> Pas d'image a sélèctionner . Veuillez ajouter les images dans votre catalogue !</div>}
                        </div>

                    </div>
                    <div className="modal-footer">
                        <Button data-dismiss="modal" size="small">Annuler</Button>
                        <Button type="primary" size="small" onClick={handleChoosedImages} disabled={selectedImagesListes.length !== 0 ? false : true}>Terminer</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const AddUser = () => <div>Add User </div>
const AddProfil = () => <div>Add User </div>

export const AddService = ({ statut = "CREATE", parent = null }) => {
    const history = useHistory()
    const token = tokenStore(state => state.token)
    const decodedToken = jwt.decode(token);
    const userId = decodedToken?.id
    const me = meStore(state => state.me);
    const [form] = Form.useForm();
    const [hasVariation, setHasVariation] = useState(parent && parent.variations?.length !== 0 ? true : false)
    const [unites, setUnites] = useState([])
    const [loadingUnites, setLoadingUnites] = useState(false)
    const imageCatalogueListes = imageCatalogueListesStore(state => state.imageCatalogueListes)
    const setImageCatalogueListes = imageCatalogueListesStore(state => state.addImageCatalogueListes)
    const [loadingImageUploaded, setLoadingImageUploaded] = useState(false)
    const [loadingCreatePrestation, setLoadingCreatePrestation] = useState(false)
    const [loadingUpdatePrestation, setLoadingUpdatePrestation] = useState(false)
    const [loadingCreateVariation, setLoadingCreateVariation] = useState(false)
    const [variationListes, setVariationListes] = useState(parent ? parent.variations : [])
    const [selectedVariation, setSelectedVariation] = useState(parent && parent.variations?.[0])
    const [selectedPrestationImages, setSelectedPrestationImages] = useState(parent && parent.images?.length !== 0 ? parent.images : [])
    const [selectedVariationImages, setSelectedVariationImages] = useState(parent && parent.variations?.length !== 0 ? parent.variations[0].images : [])
    const [typeImage, setTypeImage] = useState(PRESTATION)
    const [loadingDeletePrestation, setLoadingDeletePrestation] = useState(false)


    const { getFieldsValue, setFields } = form

    const initialValues = {
        titre: parent && parent.titre,
        description: parent && parent.description,
        cout: parent && parent.cout,
        unite: parent && parent.unite ? parent.unite : "FCFA",
        categorieId: parent && parent.categorie.id,
        delaisJoursLivraison: parent && parent.delaisJoursLivraison,
        optionTitre: parent && parent.variations?.[0]?.titre,
        optionDescription: parent && parent.variations?.[0]?.description,
        optionPrix: parent && parent.variations?.[0]?.cout,
        optionDevise: parent && parent.variations?.[0]?.unite ? parent.variations?.[0].unite : "FCFA",
        optionQuantite: parent && parent.variations?.[0]?.quantite,
        optionDelaisJoursLivraison: parent && parent.variations?.[0]?.delaisJoursLivraison,
    }

    const loadUnites = () => {
        setLoadingUnites(true)
        fetch(LISTE_INVITE_UNITES, {
            method: "get",
        })
            .then(response => response.json())
            .then(datas => {
                if (datas.error)
                    throw new Error(datas.message ? datas.message : datas.error);
                setUnites(datas);
                setLoadingUnites(false)
            })
            .catch(err => {
                NotificationManager.error(err.message);
                setLoadingUnites(false)
            });
    }

    const handleDeletePrestation = () => {
        if (parent) {
            setLoadingDeletePrestation(true)
            fetch(LISTE_SERVICES.concat('/').concat(parent.id), {
                method: "delete",
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer ".concat(token)
                }
            })
                .then(response => response.json())
                .then(datas => {
                    if (datas.error)
                        throw new Error(datas.message ? datas.message : datas.error);
                    NotificationManager.success("Suppression Oki")
                    setLoadingDeletePrestation(false)
                    history.push("/dashboard/list/services")
                })
                .catch(err => {
                    NotificationManager.error(err.message);
                    setLoadingDeletePrestation(false)
                });
        }
    }


    const handleSubmit = (value) => {
        // Si le component est en mode creation 
        if (statut === "CREATE") {
            setLoadingCreatePrestation(true)
            if (hasVariation) {
                return fetch(LISTE_SERVICES.concat("/users/").concat(userId), {
                    method: "post",
                    body: JSON.stringify({
                        parent: null,
                        titre: value.titre,
                        description: value.description,
                        categorieId: value.categorieId,
                        images: selectedPrestationImages?.map(item => item.id),
                    }),
                    headers: {
                        "content-type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                })
                    .then((response) => response.json())
                    .then(datas => {
                        if (datas.error)
                            throw new Error(datas.message ? datas.message : "Erreur de création de la prestation parent");

                        if (datas.prestation?.id) {
                            if (variationListes.length !== 0) {
                                var count = 0

                                for (const item of variationListes) {
                                    count++
                                    fetch(LISTE_SERVICES.concat("/users/").concat(userId), {
                                        method: "post",
                                        body: JSON.stringify({
                                            parent: datas.prestation.id,
                                            titre: item.titre,
                                            description: item.description,
                                            categorieId: value.categorieId,
                                            cout: item.cout,
                                            unite: item.unite,
                                            delaisJoursLivraison: item.delaisJoursLivraison,
                                            quantite: item.quantite,
                                            images: item.images?.map(item => item.id),
                                        }),
                                        headers: {
                                            "content-type": "application/json",
                                            Authorization: "Bearer " + token,
                                        },
                                    })
                                        .then((response) => response.json())
                                        .then(datas => {
                                            if (datas.error)
                                                throw datas;

                                            NotificationManager.success("La Variation ".concat(item.titre).concat(" à été créer avec success... "))
                                        })
                                        .catch((err) => {
                                            setLoadingCreatePrestation(false)
                                            NotificationManager.error(err.message, null, 8000)
                                        });

                                }

                                if (count === variationListes.length) {
                                    setLoadingCreatePrestation(false)
                                    setImageCatalogueListes([])
                                    return history.push("/dashboard/list/services")
                                }
                            }
                        }
                    })
                    .catch(err => {
                        
                        setLoadingCreatePrestation(false)
                        NotificationManager.error(err.message, null, 5000)

                    });
            } else {


                return fetch(LISTE_SERVICES.concat("/users/").concat(userId), {
                    method: "post",
                    body: JSON.stringify({
                        parent: null,
                        titre: value.titre,
                        description: value.description,
                        categorieId: value.categorieId,
                        cout: value.cout,
                        unite: value.unite,
                        quantite: value.quantite,
                        delaisJoursLivraison: value.delaisJoursLivraison,
                        images: selectedPrestationImages?.map(item => item.id),
                    }),
                    headers: {
                        "content-type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                })
                    .then((response) => response.json())
                    .then(datas => {
                        if (datas.error)
                            throw new Error(datas.message ? datas.message : datas.error);

                        setLoadingCreatePrestation(false)
                        setImageCatalogueListes([])
                        NotificationManager.success("La prestation à été créer avec success...", null , 8000)
                        return history.push("/dashboard/list/services")
                    })
                    .catch((err) => {
                        setLoadingCreatePrestation(false)
                        NotificationManager.error(err.message, null, 8000)
                    });


            }
        }

        // Si le component est en mode Mise à jour
        if (statut === "UPDATE") {
            setLoadingUpdatePrestation(true)
            if (hasVariation) {
                return fetch(LISTE_SERVICES.concat("/").concat(parent?.id).concat("/users/").concat(userId), {
                    method: "put",
                    body: JSON.stringify({
                        titre: value.titre,
                        description: value.description,
                        categorieId: value.categorieId,
                        images: selectedPrestationImages?.map(item => item.id),
                    }),
                    headers: {
                        "content-type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                })
                    .then((response) => response.json())
                    .then(datas => {
                        if (datas.error)
                            throw new Error(datas.message ? datas.message : "Erreur de Mise à jour de la prestation parent");

                        if (variationListes.length !== 0) {
                            var count = 0
                            variationListes.forEach(item => {
                                count++
                                fetch(LISTE_SERVICES.concat("/").concat(item.id).concat("/users/").concat(userId), {
                                    method: "put",
                                    body: JSON.stringify({
                                        titre: item.titre,
                                        description: item.description,
                                        categorieId: value.categorieId,
                                        cout: item.cout,
                                        unite: item.unite,
                                        delaisJoursLivraison: item.delaisJoursLivraison,
                                        quantite: item.quantite,
                                        images: item.images?.map(item => item.id),
                                    }),
                                    headers: {
                                        "content-type": "application/json",
                                        Authorization: "Bearer " + token,
                                    },
                                })
                                    .then(response => response.json())
                                    .then(datas => {

                                        if (datas.error)
                                            throw new Error(datas.message ? datas.message : datas.error);

                                        NotificationManager.success("La Variation ".concat(item.titre).concat(" à été Mise à jour avec success... "))
                                        if (count === variationListes.length) {
                                            setLoadingUpdatePrestation(false)
                                            setImageCatalogueListes([])
                                            return history.push("/dashboard/list/services")
                                        }
                                    })
                                    .catch((err) => {
                                        setLoadingUpdatePrestation(false)
                                        NotificationManager.error(err.message, null, 5000)
                                    });
                            })
                        }
                    })
                    .catch((err) => {
                        setLoadingUpdatePrestation(false)
                        NotificationManager.error(err.message, null, 5000)
                    });
            }
            return fetch(LISTE_SERVICES.concat("/").concat(parent?.id).concat("/users/").concat(userId), {
                method: "put",
                body: JSON.stringify({
                    titre: value.titre,
                    description: value.description,
                    categorieId: value.categorieId,
                    cout: value.cout,
                    unite: value.unite,
                    quantite: value.quantite,
                    delaisJoursLivraison: value.delaisJoursLivraison,
                    images: selectedPrestationImages?.map(item => item.id),
                }),
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((response) => response.json())
                .then((datas) => {

                    if (datas.error)
                        throw new Error(datas.message ? datas.message : datas.error);

                    setLoadingUpdatePrestation(false)
                    NotificationManager.success("La prestation à été Mise à jour avec success...")
                    setImageCatalogueListes([])
                    return history.push("/dashboard/list/services")
                })
                .catch((err) => {
                    setLoadingUpdatePrestation(false)
                    NotificationManager.error(err.message)
                });
        }
    };

    const handleDeleteVariation = (value) => {
        setVariationListes(variationListes.filter(item => item.titre !== value.titre))
    }

    const setZeroVariationFields = () => {
        setSelectedVariationImages([])
        setFields([
            { name: "optionTitre", value: "", errors: [] },
            { name: "optionDescription", value: "", errors: [] },
            { name: "optionDevise", value: "", errors: [] },
            { name: "optionPrix", value: "", errors: [] },
            { name: "optionQuantite", value: "", errors: [] },
            { name: "optionDelaisJoursLivraison", value: "", errors: [] },
        ])
    }

    const handleAddVariation = () => {
        const optionTitre = form.getFieldValue("optionTitre")
        const optionDescription = form.getFieldValue("optionDescription")
        const optionDevise = form.getFieldValue("optionDevise")
        const optionPrix = form.getFieldValue("optionPrix")
        const optionQuantite = form.getFieldValue("optionQuantite")
        const optionDelaisJoursLivraison = form.getFieldValue("optionDelaisJoursLivraison")

        if (!optionTitre) return setFields([{ name: "optionTitre", value: "", errors: ["vous devez entrer le titre"] }])
        if (!optionDevise) return setFields([{ name: "optionDevise", value: "", errors: ["vous devez choisisr l' unité"] }])
        if (!optionPrix) return setFields([{ name: "optionPrix", value: "", errors: ["vous devez entrer le prix"] }])
        if (!optionDelaisJoursLivraison) return setFields([{ name: "optionDelaisJoursLivraison", value: "", errors: ["vous définir un delais de livraison"] }])
        if (selectedVariationImages.length === 0) return NotificationManager.error("Veuillez choisir au moin une image ! ")

        if (statut === "CREATE") {
            if (!variationListes?.map(item => item.titre).includes(optionTitre)) {
                setVariationListes(variationListes.concat({
                    id: Math.round(Math.random() * 9999),
                    images: selectedVariationImages,
                    titre: optionTitre,
                    description: optionDescription,
                    unite: optionDevise,
                    cout: optionPrix,
                    quantite: optionQuantite,
                    delaisJoursLivraison: optionDelaisJoursLivraison,
                }))
            }
            return setZeroVariationFields()
        }

        if (statut === "UPDATE" && parent) {
            setLoadingCreateVariation(true)
            return fetch(LISTE_SERVICES.concat("/").concat("/users/").concat(userId), {
                method: "post",
                body: JSON.stringify({
                    images: selectedVariationImages.map(item => item.id),
                    titre: optionTitre,
                    description: optionDescription,
                    unite: optionDevise,
                    cout: optionPrix,
                    quantite: optionQuantite,
                    delaisJoursLivraison: optionDelaisJoursLivraison,
                    parent: parent.id,
                }),
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((response) => response.json())
                .then((datas) => {

                    if (datas.error)
                        throw new Error(datas.message ? datas.message : datas.error);

                    setLoadingCreateVariation(false)
                    NotificationManager.success("La variation à été ajouter avec success...")
                    setZeroVariationFields()
                    return history.push("/dashboard/detail/service/".concat(parent.id))
                })
                .catch((err) => {
                    setLoadingCreateVariation(false)
                    NotificationManager.error(err.message)
                });
        }
    }

    const variationColumn = [
        {
            key: 'titre',
            title: 'Titre',
            dataIndex: 'titre',
        },
        {
            key: 'prix',
            title: 'Prix',
            render: value => <span><span>{value.cout}</span><span style={{ marginLeft: "5px" }}>{value.unite}</span> </span>
        },

        {
            key: 'action',
            title: "Actions",
            render: item => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div title='Editer'>
                    <FiEdit color="#34a0a4" style={{ fontSize: 15, cursor: "pointer" }}
                        onClick={() => {
                            setSelectedVariation(item)
                            setSelectedVariationImages(item.images)
                            setFields([
                                { name: "optionTitre", value: item.titre },
                                { name: "optionDescription", value: item.description },
                                { name: "optionPrix", value: item.cout },
                                { name: "optionDevise", value: item.unite },
                                { name: "optionQuantite", value: item.quantite },
                                { name: "optionDelaisJoursLivraison", value: item.optionDelaisJoursLivraison },
                            ])
                        }} />
                </div>
                <div title="Supprimer" content="Enlever la variation de la liste" style={{ marginLeft: '10px' }}>
                    <RiDeleteBin6Line style={{ fontSize: 15, cursor: "pointer" }} color="#ff006e"
                        onClick={() => handleDeleteVariation(item)} />
                </div>
            </div>

        }
    ]

    const handleUpdateVariation = () => {
        setVariationListes(variationListes.map(item => {
            if (item?.id === selectedVariation?.id) {
                const { optionTitre, optionDescription, optionDelaisJoursLivraison, optionQuantite, optionPrix, optionDevise } = getFieldsValue()
                const images = selectedVariationImages
                setZeroVariationFields()
                NotificationManager.success("Variante mise à jour !")
                return {
                    id: item.id,
                    titre: optionTitre,
                    description: optionDescription,
                    delaisJoursLivraison: optionDelaisJoursLivraison,
                    quantite: optionQuantite,
                    unite: optionDevise,
                    cout: optionPrix,
                    images: images
                }
            }
            return item;
        }))
    }
    const handleCreationNouvelleVariationButton = () => {
        setSelectedVariation(null)
        setFields([
            { name: "optionTitre", value: '' },
            { name: "optionDescription", value: '' },
            { name: "optionDelaisJoursLivraison", value: '' },
            { name: "optionPrix", value: '' },
            { name: "optionDevise", value: '' },
        ])
    }


    useEffect(() => {
        loadUnites()
    }, []);



    return <div className='create-prestation-container'>
        <Form form={form} layout="vertical"
            initialValues={initialValues} onFinish={handleSubmit} >

            {statut === "CREATE" &&
                (
                    <div>
                        <div className='my-3' style={{ color: ORANGE, fontSize: "16px", textDecoration: "underline", fontWeight: "bold" }}>Nouvelle Prestation</div>
                        <div>Renseigner les informations nécessaires à la création de votre prestation,  afin d'aider les clients à mieux comprendre votre prestation !</div>
                    </div>
                )
            }

            {statut === "UPDATE" &&
                (
                    <div>
                        <div className='my-3' style={{ color: ORANGE, fontSize: "16px", textDecoration: "underline", fontWeight: "bold" }}>Détails Prestation de service</div>
                        <div className="my-2"  >Tous les détails d'une prestation . Vous avez également la possibilité de les mettre à jour . </div>
                    </div>
                )
            }

            <div className="row">
                <div className="col-sm-7">

                    <div className="my-shadow p-3 border bg-white rounded mt-4">
                        <div>
                            <Form.Item
                                label={
                                    <span>
                                        <span>Titre</span>
                                        <div className='my-2' >Si votre prestation à des options, alors donnez un titre global à cette prestation parent</div>
                                    </span>
                                }
                                name="titre"
                                rules={[{ required: true, message: "Veuillez entrer le titre de la prestation !" }]}
                            >
                                <Input placeholder="Entrer le  titre" size='large' />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label={<span>Description</span>}
                                name="description"
                            >
                                <TextArea placeholder="Entrer la description" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="my-shadow p-3 border bg-white rounded mt-4">
                        <div>
                            <div className='fw-bold'>Domaine de compétence</div>
                            <div className='my-2' >Choisissez le domaine dans lequel vous voulez créer votre prestation </div>
                        </div>
                        <div>
                            <Form.Item
                                name="categorieId"
                                label={<span>Domaine</span>}
                                rules={[
                                    {
                                        required: true,
                                        message: "Veuillez choisir une domaine !",
                                    },
                                ]}
                            >
                                <TreeSelect
                                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                    placeholder="choisir"
                                    allowClear
                                    treeDefaultExpandAll
                                    size='large'
                                >
                                    {me && me?.competences?.map((cat) => <TreeNode key={cat.id} value={cat.id} title={cat.nom} />
                                    )}
                                </TreeSelect>
                            </Form.Item>
                        </div>
                    </div>

                    {/*  Si la prestation n'a pas des variations  */}
                    {!hasVariation && <div className=" my-shadow p-3 border bg-white rounded mt-4">

                        <div className='mb-2'>
                            <div className='fw-bold'>Prix</div>
                            <div className='my-2' > Si vous avez l'intention de créer des variations, alors vous n'avez pas bésoin de renseigner les informations de cette partie.</div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <Form.Item
                                    label={<span>Prix</span>}
                                    name="cout"
                                    required={[{ required: true, message: "Quel est le prix ? " }]}
                                >
                                    <InputNumber size='large' style={{ width: "100%" }} placeholder="Entrer le prix" />
                                </Form.Item>
                            </div>
                            <div className="col-sm-6">
                                <Form.Item
                                    label={<span>Devise</span>}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Entrer l'unité !",
                                        },
                                    ]}
                                    name="unite"
                                >
                                    <Select
                                        style={{ width: "100%" }}
                                        loading={loadingUnites}
                                        placeholder="Devise"
                                        size='large'
                                    >
                                        {unites?.map((unite) => (
                                            <Select.Option key={unite} style={{ fontSize: 10 }}>
                                                {unite}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-sm-12">
                                <Form.Item
                                    label={<span>Quantité</span>}
                                    name="quantite"
                                >
                                    <InputNumber size='large' style={{ width: "100%" }} placeholder="Entrer la quantitée" />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    }
                    <div className="my-shadow p-3 border rounded mt-4">
                        <div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div style={{ fontWeight: 'bold', margin: '10px 0px' }}>Variations</div>
                                <button onClick={handleCreationNouvelleVariationButton} style={{ padding: "5px 10px", fontWeight: 'bold', background: GREEN, color: "#fff", borderRadius: "5px" }}> + Créer une nouvelle</button>
                            </div>
                            <div className='my-2'>Si vous voulez créer des variations à partir de cette prestation, alors vous pouvez cocher la case et complèter les informations pour les variations .</div>
                            <div><Checkbox checked={hasVariation} onChange={value => setHasVariation(value.target.checked)} /> <span className="ml-1 my-2 fw-bold"> Cette prestation contient des variations ? </span></div>
                        </div>
                        {hasVariation && <div className='my-4'>
                            <div className="row">
                                <div className="col-md-3">
                                    <a data-toggle="modal" href="#imageListModal" role="button" style={{ textDecoration: "none" }} onClick={() => setTypeImage(VARIATION)}>
                                        <div style={{ height: "100%", minHeight: "100px", border: "1px solid #ccc", padding: "2px", borderRadius: "2px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }} >
                                            <img src={selectedVariationImages?.[0]?.url} alt="" style={{ objectFit: "cover", height: "100%", width: "100%" }} />
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-9">
                                    <div>
                                        <Form.Item
                                            label={
                                                <span>
                                                    <span>Titre</span>
                                                    <div className="my-2" >Donner un titre bien précis pour cette variation</div>
                                                </span>}
                                            name="optionTitre"
                                        >
                                            <Input size='large' placeholder="Entrez le titre" />
                                        </Form.Item>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <Form.Item
                                                label={<span>Prix</span>}
                                                name="optionPrix"
                                            >
                                                <InputNumber size='large' style={{ width: "100%" }} placeholder="Entrer le prix" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                label={<span>Devise</span>}
                                                name="optionDevise"
                                            >
                                                <Select
                                                    style={{ width: "100%" }}
                                                    loading={loadingUnites}
                                                    placeholder="Devise"
                                                    size='large'
                                                >
                                                    {unites?.map((unite) => (
                                                        <Select.Option key={unite} style={{ fontSize: 10 }}>
                                                            {unite}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Item
                                                label={<span>Quantité</span>}
                                                name="optionQuantite"
                                            >
                                                <InputNumber size='large' style={{ width: "100%" }} placeholder="Entrer la quantitée" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className='mt-3'>
                                <Form.Item
                                    label={<span>Description</span>}
                                    name="optionDescription"
                                >
                                    <TextArea placeholder="Description de la variation" />
                                </Form.Item>
                            </div>

                            <div className="mt-4">
                                <div style={{ fontWeight: 'bold' }} className="my-2">Délai de livraison</div>
                                <div className='my-2'>
                                    Préciser le délai de livraison de votre prestation de service. Noter bien que ce délai serait en terme de nombre de jours.
                                </div>
                                <div style={{ fontWeight: "bold", color: ORANGE }}><span style={{ color: '#000', fontWeight: "bold", textDecoration: 'underline' }}>NB:</span> La livraison de cette prestation serait effectuée par le prestataire lui même.</div>
                                <div className='mt-3'>
                                    <div>
                                        <Form.Item
                                            label={<div>Délai ( en jours ) </div>}
                                            name="optionDelaisJoursLivraison"
                                        >
                                            <InputNumber style={{ minWidth: '200px' }} min={1} placeholder="Delai de livraison" size='large' />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>

                            {!selectedVariation && <Button style={{ background: GREEN, color: "#ffffff" }} loading={loadingCreateVariation} onClick={handleAddVariation}>Créer la variation</Button>}
                            {selectedVariation && <Button style={{ "border": "none", "background": GREEN, "color": "#fff" }} onClick={handleUpdateVariation}>Mettre à jour</Button>}

                        </div>
                        }
                    </div>

                    {hasVariation && (
                        <div className="my-shadow p-3 border bg-white rounded mt-4">
                            <div style={{ fontWeight: 'bold' }} className="my-2">Listes des Variations</div>

                            {variationListes.length === 0 ? (
                                <div>Liste vide !</div>
                            ) : (
                                <Table
                                    columns={variationColumn}
                                    dataSource={variationListes.map((item, index) => ({ ...item, key: index }))}
                                    size="small"
                                    bordered
                                />
                            )}

                        </div>
                    )}

                    {
                        !hasVariation && (
                            <div className="my-shadow p-3 border bg-white rounded mt-4">
                                <div style={{ fontWeight: 'bold' }} className="my-2">Délai de livraison</div>
                                <div className='my-2'>
                                    Préciser le délai de livraison de votre prestation de service. Noter bien que ce délai serait en terme de nombre de jours.
                                </div>
                                <div style={{ fontWeight: "bold", color: ORANGE }}><span style={{ color: '#000', fontWeight: "bold", textDecoration: 'underline' }}>NB:</span> La livraison de cette prestation serait effectuée par le prestataire lui même.</div>
                                <div className='mt-3'>
                                    <div>
                                        <Form.Item
                                            label={<div>Délai ( en jour ) </div>}
                                            name="delaisJoursLivraison"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ce champ est obligatoire ",
                                                },
                                            ]}                                >
                                            <InputNumber style={{ minWidth: '200px' }} min={1} placeholder="Delais de livraison" size='large' />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <div style={{ margin: '30px 0px' }}>
                        <div>
                            {!parent && <Button type="primary" loading={loadingCreatePrestation} htmlType="submit" className="ml-2 fw-bold"> Créer la prestation </Button>}
                            {parent && <Button style={{ background: RED, color: WHITE, border: "none" }} loading={loadingDeletePrestation} htmlType="button" onClick={handleDeletePrestation} className="ml-2"> Supprimer la prestation </Button>}
                            {parent && <Button style={{ background: GREEN, color: WHITE, border: "none" }} loading={loadingUpdatePrestation} htmlType="submit" className="ml-2"> Mettre à jour la prestation </Button>}
                        </div>
                    </div>
                </div>

                <div className="col-sm-5">
                    <div className="my-shadow p-3 border rounded" style={{ background: ORANGE_LIGNT, position: 'sticky', top: '70px' }}>
                        <div>
                            <div className="fw-bold">Catalogues d'images</div>
                            <div className='my-2' >Ajouter dans la catalogue toutes les images que vous allez utiliser pour créer vos prestations et vos variations, afin de vous faciliter le remplissage et le choix des images. </div>
                        </div>
                        <div className='my-2'>
                            <Upload
                                name="files"
                                previewFile={false}
                                action={IMAGES_ROUTE.concat('/multiples?nom=prestation')}
                                method="post"
                                headers={{ authorization: "Bearer " + token }}
                                listType="picture-card"
                                onChange={(info) => {
                                    if (info.file.status === "uploading") {
                                        return;
                                    }
                                    if (info.file.status === "done") {
                                        setImageCatalogueListes(imageCatalogueListes.concat(info.file.response[0]))
                                    }
                                }
                                }
                            >
                                <div>
                                    {loadingImageUploaded ? <LoadingOutlined /> : <div className="my-2" />}
                                    <div>Images</div>
                                </div>
                            </Upload>
                        </div>
                        <div>
                            <hr />
                            {hasVariation ? <div className='fw-bold'> Image de couverture</div> : <div className='fw-bold my-2'>Image de la prestation</div>}
                            {hasVariation && <div className="my-2"  >Cliquer pour choisir l'image de votre prestation. Cette image servira d'image de base lors de l'affichage de votre prestation. </div>}
                            {!hasVariation && <div className="my-2"  >Cliquer pour choisir l'image de votre prestation  </div>}
                            <a data-toggle="modal" href="#imageListModal" role="button" style={{ textDecoration: "none" }} onClick={() => setTypeImage(PRESTATION)}>
                                <div style={{ height: "100%", minHeight: "100px", border: "1px solid #ccc", padding: "2px", borderRadius: "2px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }} >
                                    <img src={selectedPrestationImages?.[0]?.url} alt="" style={{ objectFit: "cover", height: "100%", width: "100%" }} />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
        <ImageListModale setSelectedImages={typeImage === VARIATION ? setSelectedVariationImages : setSelectedPrestationImages} />
    </div>
}

const AdminAdd = () => {
    return <div >
        <Route path="/dashboard/add/user" component={AddUser} />
        <Route path="/dashboard/add/profil" component={AddProfil} />
        <Route path="/dashboard/add/service" component={() => <AddService statut="CREATE" />} />
    </div>
}


export default AdminAdd