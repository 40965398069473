import React, { useEffect, useState } from "react"
import meStore from "../stores/meStore"
import tokenStore from "../stores/tokenStore"
import { Link, useHistory } from "react-router-dom"
import { Avatar, Badge, Dropdown, Button } from "antd"
import { ShoppingCartOutlined } from "@ant-design/icons"
import panierStore from "../stores/panierStore"
import { NotificationManager } from "react-notifications"
import { isValidToken } from "../utils/utilsFunction"
import { LISTE_INVITE_CATEGORIES, BASE_PATH } from "../utils/api.route"
import { MdFavoriteBorder } from "react-icons/md"
import { FaRegUser } from "react-icons/fa"
import { ORANGE } from "../theme/couleurs"
import { CgProfile } from "react-icons/cg"
import pathStore from "../stores/pathStore"
import favorisStore from "../stores/favorisStore"
import { AiOutlineDashboard } from "react-icons/ai"
import filtreStore from "../stores/filtresStore"
import categoriesStores from "../stores/categoriesStore"
import { TiThMenuOutline } from 'react-icons/ti'
import { EuiComboBox, EuiFieldSearch } from "@elastic/eui"
import currentCategorieStore from "../stores/currentCategorieStore"
import "../pages/Home.css"
import { ALL, APP_NAME } from "../utils/constants"

const HomeHeader = () => {
  const token = tokenStore((state) => state.token)
  const createMe = meStore((state) => state.createMe)
  const me = meStore((state) => state.me)
  const addServices = panierStore((state) => state.addServices)
  const createToken = tokenStore((state) => state.createToken)
  const history = useHistory()
  const [originalCategories, setOriginalCategories] = useState([])
  const [loadingCategories, setLoadingCategories] = useState(true)
  const saveCurrentPath = pathStore((state) => state.addCurrentPath)
  const panierListes = panierStore((state) => state.services)
  const favoris = favorisStore((state) => state.favoris)
  const addFavoris = favorisStore((state) => state.addFavoris)
  const router = useHistory()
  const [inputText, setInputText] = useState("")
  const filtres = filtreStore((state) => state.filtres)
  const setFiltres = filtreStore((state) => state.addFiltres)
  const createCategories = categoriesStores((state) => state.createCategories)
  const selectedCategorie = currentCategorieStore(state => state.selectedCategorie)
  const setSelectedCategorie = currentCategorieStore(state => state.setSelectedCategorie)



  const loadCategorieListes = () => {
    setLoadingCategories(true)
    fetch(LISTE_INVITE_CATEGORIES, { method: "get" })
      .then((response) => response.json())
      .then((response) => {
        if (response.error)
          throw new Error(
            response.message
              ? response.message
              : "Erreur de chargement des catégories !"
          )
        setOriginalCategories(response)
        setLoadingCategories(false)
        createCategories(response)
      })
      .catch((err) => {
        setLoadingCategories(false)
        NotificationManager.error(err.message)
      })
  }

  const handleSearch = (event) => {
    event && event.preventDefault()
    if (inputText && inputText.trim() !== "") {
      let newFiltre = filtres.filter((item) => item.titre !== "Recherche")
      newFiltre = newFiltre.concat({
        nom: inputText,
        titre: "Recherche",
        value: inputText,
      })
      return setFiltres(newFiltre)
    } else {
      return setFiltres(filtres.filter((item) => item.titre !== "Recherche"))
    }
  }

  const menuLogin = () => (
    <div
      style={{ minWidth: "250px", maxWidth: "250px" }}
      className="text-center bg-white p-4 shadow rounded"
    >
      <div className='my-2 fw-bold'>Authentification</div>
      <div className="my-2">
        Connectez-vous pour avoir accès à toutes les fonctionalités
      </div>
      <button
        style={{ background: ORANGE, color: "#fff", width: "100%", padding: "5px 0px", fontWeight: "bold", focus: "none", border: "none" }}
        onClick={() => history.push("/login")}
        size="small"
      >
        Se connecter
      </button>
      <div className="my-2">Vous n'avez pas encore de compte ? </div>
      <div>
        <Link to="/register" style={{ fontWeight: "bold" }}>
          Inscrivez-vous
        </Link>
      </div>
    </div>
  )

  const handleLoggout = () => {
    createToken(null)
    createMe(null)
    addServices([])
    addFavoris([])
  }

  const menuAuthentication = () => (
    <div
      className="text-center p-4 shadow rounded"
      style={{ minWidth: "250px", maxWidth: "250px", background: "#fff" }}
    >
      <div className="my-2">
        <Avatar
          size="large"
          src={
            me?.userImage
              ? me?.userImage?.url
              : BASE_PATH.concat("/img/noProfile.jpg")
          }
        />
        <div>{me.username}</div>
      </div>
      <hr />
      <div className="text-left d-flex align-items-center my-2">
        <CgProfile style={{ fontSize: "22px" }} className="item-menu" />
        <Link
          to="/dashboard/compte"
          className="item-menu ml-3 font-weight-normal"
        >
          Mon profil
        </Link>
      </div>

      <div className="text-left d-flex align-items-center my-2">
        <AiOutlineDashboard
          style={{ fontSize: "22px" }}
          className="item-menu"
        />
        <Link
          to={
            me?.profil?.nom === "ADMIN"
              ? "/dashboard/user"
              : "/dashboard/commandes/demandeur"
          }
          className="item-menu ml-3 font-weight-normal"
        >
          Administration
        </Link>
      </div>
      <div className="pt-2">
        <Button
          size="small"
          onClick={handleLoggout}
          style={{
            background: ORANGE,
            color: "#fff",
            border: "none",
            width: "100%",
          }}
        >

          Se Déconnecter
        </Button>
      </div>
    </div>
  )

  const handleGotoFavoris = () => {
    if (!token || !isValidToken(token)) {
      saveCurrentPath("/favoris")
      handleToggleLeftMenuBar()
      return history.push("/login")
    }
    return history.push("/favoris")
  }



  const handleCategorieFilter = (cat, parentCat) => {
    if (cat) {
      const newObject = { nom: cat.nom, titre: "Catégorie", value: cat.id }

      let my_categorie_filter = []
      const other_filtre = filtres.filter(f => f.titre !== "Catégorie")

      if (parentCat && filtres.filter(f => f.titre === "Catégorie" && cat.parentId !== originalCategories.find(o => o.id === f.value)?.parentId)?.length > 0) {
        my_categorie_filter = filtres.filter(f => f.titre === "Catégorie" && cat.parentId === originalCategories.find(o => o.id === f.value)?.parentId)
      } else {
        my_categorie_filter = filtres.filter(f => f.titre === "Catégorie")
      }

      if (!my_categorie_filter
        .map(f => f.value)
        .includes(newObject.value)
      ) {
        setFiltres([...other_filtre, ...my_categorie_filter, newObject])
        setSelectedCategorie([{ value: parentCat.id, label: parentCat.nom }])
        return router.push(`/`)
      }

      const newFilterList = my_categorie_filter.filter(
        item => item.nom !== cat.nom && item.value !== cat.id
      )

      setFiltres([...other_filtre, ...newFilterList])
      return router.push(`/`)
    }
    else {
      const newFilterList = filtres.filter(
        item => item.titre !== "Catégorie"
      )
      return setFiltres(newFilterList)
    }
  }

  const handleChangeInput = (value) => {
    setInputText(value.target.value)
    if (!value.target.value || value.target.value?.trim()?.length === 0) {
      let newFiltre = filtres.filter((item) => item.titre !== "Recherche")
      setFiltres(newFiltre)
    }
  }

  const DisplaySeachForm = () => (
    <form onSubmit={handleSearch} className="w-100" >
      <div className="d-flex align-items-center" style={{ margin: "0px auto", width: "90%" }}>
        <div className="input-search-container">
          {DisplayBrowserCategories()}
          <EuiFieldSearch
            placeholder={`Rechercher dans ${selectedCategorie[0]?.label}`}
            fullWidth
            onKeyUp={event => {
              if (event && event.keyCode === 13) {
                handleSearch()
              }
            }}
            isClearable={false}
            onChange={handleChangeInput}
            value={inputText}
            className="input-field"
          />
          {DisplayCategories()}
          <span className="btn-recherche" type="submit" style={{ cursor: "pointer", backgroundColor: "#44444E", color: "#fff", padding: "10px 15px" }} onClick={handleSearch}>
            Rechercher
          </span>
        </div>
      </div>
    </form>
  )

  const DisplayAndroidSeachForm = () => (
    <form onSubmit={handleSearch} className="w-100" >
      <div className="d-flex align-items-center" style={{ margin: "0px auto", width: "90%" }}>
        <div className="input-search-container">
          {DisplayAndroidBrowserCategories()}
          <EuiFieldSearch
            placeholder={`Rechercher dans ${selectedCategorie[0]?.label}`}
            fullWidth
            isClearable={false}
            onChange={value => setInputText(value.target.value)}
            value={inputText}
            className="input-field"
          />
          {DisplayCategories()}
          <span className="btn-recherche" type="submit" style={{ cursor: "pointer", backgroundColor: "#44444E", color: "#fff", padding: "10px 15px" }} onClick={handleSearch}>
            Rechercher
          </span>
        </div>
      </div>
    </form>
  )

  const DisplayLeftSide = () => (
    <Link to="/" className="navbar-brand logo-AF">
      <span style={{ fontSize: 20, fontWeight: "bold", color: "#22222C" }}>
        {APP_NAME}
      </span>
    </Link>
  )

  const DisplayAndroidLeftSide = () => (
    <div className="ps-4">
      <Link to="/" className="navbar-brand logo-AF text-start">
        <span style={{ fontSize: 20, fontWeight: "bold", color: "#22222C" }}>
        {APP_NAME}
        </span>
      </Link>
    </div>
  )

  const DisplayRightMenu = () => (

    <div className="d-flex align-items-center text-md-right">
      <div>
        <div className="text-center">
          <Badge
            count={favoris.length}
            children={
              <MdFavoriteBorder
                onClick={handleGotoFavoris}
                className="item-menu"
                style={{ fontSize: "26px" }}
              />
            }
          />
          <div>
            <div
              className="item-menu"
            >
              Favoris
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex text-center">
        <div
          className="ml-3 p-2 "
        >
          <Badge
            count={panierListes.length}
            children={
              <ShoppingCartOutlined
                className="item-menu"
                onClick={() => history.push("/panier")}
                style={{ fontSize: 26 }}
              />
            }
          />
          <div className="item-menu"> Panier </div>
        </div>
      </div>

      {token && isValidToken(token) && me ? (
        <div className="ml-3 p-2 ">
          <Dropdown
            dropdownRender={menuAuthentication}
            placement="bottomLeft"
            arrow
          >
            <div className="text-center">
              <Avatar
                src={
                  me?.userImage
                    ? me?.userImage?.url
                    : BASE_PATH.concat("/img/noProfile.jpg")
                }
              />
              <div className="item-menu" style={{ fontSize: "13px" }}>
                {me?.username}
              </div>
            </div>
          </Dropdown>
        </div>
      ) : (
        <div className="text-center">
          <Dropdown dropdownRender={menuLogin} placement="bottomLeft" arrow>
            <FaRegUser className="item-menu" style={{ fontSize: 26 }} />
          </Dropdown>
          <div>

            <div
              // to="/login"
              className="item-menu ml-1"
            >
              Connexion
            </div>
          </div>
        </div>
      )
      }

    </div>
  )

  const DisplayAndroidRightMenu = () => (

    <div className="d-flex align-items-center justify-content-center">
      <div>
        <div className="text-center">
          <Badge
            count={favoris.length}
            children={
              <MdFavoriteBorder
                onClick={handleGotoFavoris}
                className="item-menu"
                style={{ fontSize: "26px" }}
              />
            }
          />
          <div>
            <div
              className="item-menu"
            >
              Favoris
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex text-center">
        <div
          className="ml-3 p-2 "
        >
          <Badge
            count={panierListes.length}
            children={
              <ShoppingCartOutlined
                className="item-menu"
                onClick={() => history.push("/panier")}
                style={{ fontSize: 26 }}
              />
            }
          />
          <div className="item-menu"> Panier </div>
        </div>
      </div>

      {token && isValidToken(token) && me ? (
        <div className="ml-3 p-2 ">
          <Dropdown
            dropdownRender={menuAuthentication}
            placement="bottomLeft"
            arrow
          >
            <div className="text-center">
              <Avatar
                src={
                  me?.userImage
                    ? me?.userImage?.url
                    : BASE_PATH.concat("/img/noProfile.jpg")
                }
              />
              <div className="item-menu" style={{ fontSize: "13px" }}>
                {me?.username}
              </div>
            </div>
          </Dropdown>
        </div>
      ) : (
        <div className="text-center">
          <Dropdown dropdownRender={menuLogin} placement="bottomLeft" arrow>
            <FaRegUser className="item-menu" style={{ fontSize: 26 }} />
          </Dropdown>
          <div>

            <div
              // to="/login"
              className="item-menu ml-1"
            >
              Connexion
            </div>
          </div>
        </div>
      )
      }

    </div>
  )

  const DisplayDesktopCategoryMenus = () => originalCategories
    ?.filter(cat => !cat.parentId)?.length > 0 &&
    (
      <div className="categorie-mega-menu-content-desktop">
        <div>
          <div className="my-container">
            <div className="row">
              {
                originalCategories
                  ?.filter(cat => !cat.parentId)
                  .map(cat => (
                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2" key={cat.id}>
                      <div style={{ margin: "3px 10px" }}>
                        <span style={{ fontWeight: "bold", fontSize: "15px", color: "#000", marginBottom: "5px", display: "block" }}> {cat.nom} </span>
                        {
                          originalCategories
                            ?.filter(child => child.parentId === cat.id).length > 0 && originalCategories
                              ?.filter(child => child.parentId === cat.id).map(child => (
                                <div
                                  key={child.id}
                                  onClick={_ => handleCategorieFilter(child, cat)}
                                >
                                  <span className="categorie-item" style={getChangeSelectedItemColor(child)}>{child.nom} </span>
                                </div>
                              ))
                        }
                      </div>
                    </div>
                  ))
              }
            </div>
          </div>
        </div>
      </div>
    )

  const getChangeSelectedItemColor = (cat) => {
    let style = { color: "#00000080" }

    const allcategories = filtres.filter(c => c.titre === 'Catégorie')
    if (allcategories.length > 0 && allcategories.map(c => c.value).includes(cat.id)) {
      style = { color: '#ffd000', textDecoration: 'underline' }
    }

    return style
  }

  const DisplayAndroidCategoryMenus = () => originalCategories
    ?.filter(cat => !cat.parentId)?.length > 0 &&
    (
      <div className="categorie-mega-menu-content-android" id="categorie-mega-menu-content-android">
        <div>
          <div className="my-container">
            <div>
              {
                originalCategories
                  ?.filter(cat => !cat.parentId)
                  .map(cat => (
                    <div key={cat.id} className="mt-3">
                      <div style={{ margin: "3px 10px" }}>
                        <span style={{ fontWeight: "bold", fontSize: "15px", color: "#000", marginBottom: "5px", display: "block" }}> {cat.nom} </span>
                        {
                          originalCategories
                            ?.filter(child => child.parentId === cat.id).length > 0 && originalCategories
                              ?.filter(child => child.parentId === cat.id).map(child => (
                                <div
                                  key={child.id}
                                  onClick={_ => handleCategorieFilter(child, cat)}
                                >
                                  <span className="categorie-item" style={getChangeSelectedItemColor(child)}>{child.nom} </span>
                                </div>
                              ))
                        }
                      </div>
                    </div>
                  ))
              }
            </div>
          </div>
        </div>
      </div>
    )

  const DisplayBrowserCategories = () => (
    <div style={{ minWidth: "200px", marginRight: "5px" }}>
      <div className="categorie-mega-menu-container">
        <span
          style={{
            backgroundColor: "#44444C",
            width: "100%",
            textAlign: "center",
            cursor: "pointer",
            border: "1px solid #ccc",
            padding: "10px 15px",
            display: "block",
            fontWeight: "bold",
            color: "#fff"
          }}
        >Explorer les catégories</span>
        {DisplayDesktopCategoryMenus()}
      </div>
    </div>
  )


  const DisplayAndroidBrowserCategories = () => (
    <div style={{ minWidth: "200px", marginRight: "5px" }}>
      <div className="categorie-mega-menu-container">
        <span
          style={{
            backgroundColor: "#44444C",
            width: "100%",
            textAlign: "center",
            cursor: "pointer",
            border: "1px solid #ccc",
            padding: "10px 15px",
            display: "block",
            fontWeight: "bold",
            color: "#fff"
          }}
          onClick={() => toggleMenu()}
        >Explorer les catégories</span>
        {DisplayAndroidCategoryMenus()}
      </div>
    </div>
  )

  const handleSelectCategory = value => {
    if (value.length > 0) {
      setSelectedCategorie(value)
    } else {
      setSelectedCategorie([{ value: ALL, label: "Toutes les catégories" }])
    }
    setFiltres(filtres.filter(item => item.titre !== "Catégorie"))
    router.push('/')
  }

  const DisplayCategories = () => (
    <div className="me-2 categorie-menu">
      <EuiComboBox
        style={{ minWidth: "200px" }}
        placeholder="Catégories"
        options={
          [{ value: ALL, label: "Toutes les catégories" }]
            .concat(
              originalCategories
                .filter(cat => !cat.parentId)
                .map(cat => ({ label: cat.nom, value: cat.id }))
            )
        }
        selectedOptions={selectedCategorie}
        onChange={handleSelectCategory}
        isClearable
        singleSelection={{ asPlainText: true }}
      />

    </div>
  )

  const DisplayDesktopTopBar = () => (
    <div className="desktop-header bg-white border-bottom" style={{ position: "relative" }}>
      <div className="my-container">
        <div className="d-flex align-items-center py-1" >
          {DisplayLeftSide()}
          {DisplaySeachForm()}
          {DisplayRightMenu()}
        </div>
      </div>
    </div>
  )

  const toggleMenu = () => {
    const element = document.getElementById('categorie-mega-menu-content-android')
    if (element) {
      element.classList.toggle('active')
    }
  }

  const DisplayAndroidTopBar = () => (
    <div className="android-header" id="android-header">
      <div className="my-container">
        <div className="d-flex flex-column py-1" style={{ position: 'relative' }}>
          <span onClick={handleToggleLeftMenuBar} className="close-btn">x</span>
          {DisplayAndroidLeftSide()}
          {DisplayAndroidSeachForm()}
          {DisplayAndroidRightMenu()}
        </div>
      </div>
    </div>
  )

  const handleToggleLeftMenuBar = () => {
    const leftMenu = document.getElementById('android-header')
    if (leftMenu) {
      leftMenu.classList.toggle('open')
    }
  }

  const DisplayAndroidToggleTopBar = () => (
    <>
      <div className="my-container toggle-android-top-bar">
        <div className="d-flex justify-content-between align-items-center py-3" >
          <div>
            <Link to="/" className="navbar-brand logo-AF">
              <span style={{ fontSize: 20, fontWeight: "bold", color: "#22222C" }}>
              {APP_NAME}
              </span>
            </Link>
          </div>
          <div>
            <TiThMenuOutline onClick={handleToggleLeftMenuBar} style={{ fontSize: '25px', cursor: 'pointer' }} />
          </div>
        </div>
      </div>
    </>
  )


  useEffect(() => {
    loadCategorieListes()
    return () => {
      createCategories([])
    }
  }, [])

  return (
    <>
      <div>
        {DisplayAndroidToggleTopBar()}
        {DisplayDesktopTopBar()}
        {DisplayAndroidTopBar()}
      </div>
    </>
  )
}

export default HomeHeader
