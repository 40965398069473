import create from "zustand";

const store = (set) => ({
    favoris: [],
    addFavoris: favoris => set({ favoris }),
});

const favorisStore = create(store);

export default favorisStore;
