const ConstantConfig = () => {
    return {
        // BASE_PATH: "",
        BASE_PATH: "/afrikatalent",
    }
}

export const GetServerURL = () => {
    return {
        SOCKET_URL: "wss://afrikatalent.com"
        // SERVER_URL: "https:/",
    }
}

export default ConstantConfig