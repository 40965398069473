

import Jwt from "jsonwebtoken";
import { NotificationManager } from "react-notifications";
import { ME_ROUTE, SOCKET_ROUTE } from './api.route'

export const isValidToken = (token) => {
  if (token !== "") {
    const currentTime = new Date().getTime();
    const decoded = Jwt.verify(token, "hispsuiviemetier");
    const expiredTime = decoded.exp
    if (currentTime <= expiredTime) {
      return true;
    }
  }
  return false

};

export const genereTree = (datas) => {
  // inject children propert
  const datasOverrides = datas?.map(d => ({
    ...d,
    title: d.nom,
    key: d.nom,
    id: d.id,
    value: d.nom,
    children: [],
    parentId: d.parentId
  }));

  let nodes = datasOverrides.filter(p => p.parentId === null)

  nodes.forEach(a => {
    a.children = datasOverrides.filter(b => b.parentId === a.id)
    a.children.forEach(c => {
      c.children = datasOverrides.filter(d => d.parentId === c.id)
      c.children.forEach(e => {
        e.children = datasOverrides.filter(f => f.parentId === e.id)
        e.children.filter(g => {
          g.children = datasOverrides.filter(h => h.parentId === g.id)
          g.children.filter(i => {
            i.children = datasOverrides.filter(j => j.parentId === i.id)
            i.children.filter(k => {
              k.children = datasOverrides.filter(l => l.parentId === k.id)
            })
          })
        })
      })
    })
  })

  return nodes;
};



export const regenerateListFromId = (categories, values) => {
  if (values && values.length !== 0 && categories && categories.length !== 0) {
    return values.map(nom => {
      return categories.find(e => e.nom === nom);
    });
  }

  return [];
};

export const refreshMe = (createMe, token, createSocket = null) => {
  const decodedToken = Jwt.decode(token)
  const userId = decodedToken?.id
  if (token && token !== "" && isValidToken(token)) {
    fetch(ME_ROUTE.concat('/').concat(userId), {
      method: "get",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        createMe(result)
        connectToSocket(result, createSocket)
      })
      .catch((err) => NotificationManager.error(err.message))
  }
}


export const backTop = () => window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}


export const connectToSocket = (me, createSocket) => {
  if (me) {
    var websocket = new WebSocket(SOCKET_ROUTE.concat('?userId=').concat(me.id))

    if (createSocket) {
      createSocket(websocket)
    }


    websocket.onopen = ev => { }

    websocket.onerror = err => { }

    websocket.onclose = () => { }

    websocket.onmessage = message => {
      const messageData = message.data
      if (messageData) {
        if (!("Notification" in window)) {
          alert(
            "Ce navigateur ne prend pas en charge la notification de bureau"
          )
        } else if (Notification.permission === "granted") {
          const notification = new Notification(
            JSON.stringify(messageData)
          );
        } else if (Notification.permission !== "denied") {
          Notification.requestPermission().then((permission) => {
            // Si l'utilisateur accepte, créons une notification
            if (permission === "granted") {
              const notification = new Notification(
                JSON.stringify(messageData)
              )
            }
          })
        }

      }
    }
  }
}


export const compareTwoDate = (date, nbreJour = 2) => {
  if (date) {
    const currentMs = new Date().getTime() + (nbreJour * 24 * 60 * 60 * 1000)
    const dateMs = new Date(date).getTime()
    if (dateMs <= currentMs) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}