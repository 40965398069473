import React, { useEffect, useState } from 'react';
import jwt from 'jsonwebtoken';
import tokenStore from '../stores/tokenStore';
import Title from 'antd/lib/typography/Title';
import { NotificationManager } from 'react-notifications';
import Text from 'antd/lib/typography/Text';
import { Avatar, Divider, List } from 'antd';
import { MESSAGES_ROUTE } from '../utils/api.route';
import { ORANGE } from '../theme/couleurs';

const AdminMessageList = () => {

    const token = tokenStore(state => state.token);
    const decodedToken = jwt.decode(token);
    const userId = decodedToken?.id
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(
            MESSAGES_ROUTE.concat('/recus/user/').concat(userId).concat('?page=0&size=10'),
            {
                method: 'get',
                headers: {
                    'content-type': 'application:json',
                    Authorization: 'Bearer ' + token,
                }
            }).then(response => response.json())
            .then(result => {
                if (result.error) return NotificationManager.success(result.message ? result.message : "Erreur de chargement des messages !");
                setMessages(result?.content?.filter(m => m.read === false));
                setLoading(false);
            }).catch(err => {
                NotificationManager.error(err.message)
                setLoading(false);
            })
    }, [])


    return (
        <div style={{ minHeight: "100vh" }}>
            <div style={{ fontSize: "16px", textDecoration: "underline", color: ORANGE, fontWeight: "bold", margin: "20px 0px" }}> Nouveaux méssages !</div>
            {messages?.length === 0 ? <p > Pas de messages !</p> : <List
                loading={loading}
                itemLayout="vertical"
                size="small"
                dataSource={messages}
                renderItem={item => <List.Item key={item.id}>
                    <List.Item.Meta
                        avatar={<Avatar src={item?.expediteur?.image ? item?.expediteur?.image?.url : "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"} />}
                        title={<Title level={4}> {item?.expediteur?.username} </Title>}
                        description={<p>{item.message}</p>}
                    />
                    <Divider style={{ margin: 0, padding: 0 }} />
                </List.Item>
                }
            />
            }
        </div>
    )
}
export default AdminMessageList;