import React from "react";

import { useHistory } from "react-router-dom";
import { Rate } from "antd";

import "../pages/Home.css";
import { BASE_PATH } from "../utils/api.route";

import { RED, WHITE } from "../theme/couleurs";

export const HomeCustumProfileCard = ({ profil }) => {
  const history = useHistory();
  return (
    profil && (
      <div
        key={profil?.id}
        className="card my-shadow card-item"
        style={{ minHeight: "348px"}}
      >
        <div>
          <img
            onClick={() => history.push("/detail/profil/".concat(profil.id))}
            src={
              profil.userImage && profil.userImage.nom !== ""
                ? profil.userImage.url
                : BASE_PATH.concat("/img/noProfile.jpg")
            }
            alt="Card profile"
            style={{
              height: "220px",
              cursor: "pointer",
              width: "100%",
              objectFit: "cover",
            }}
          />
        </div>

        <div className="card-body py-1">
          <div
            onClick={() => history.push('/detail/profil/'.concat(profil.id))}
            style={{ cursor: "pointer", fontWeight: "bold", fontSize: "14px" }}
          >
            {profil.nom + " " + profil.prenom}
          </div>
          <div
            className="text-truncate-two"
          >
            {profil.precisionCompetence}
          </div>

          <div className="d-flex align-items-center justify-content-between mt-1">
            <Rate value={profil.note} disabled />
            <span className="text-muted" style={{ fontSize: "12px" }}>
              ({profil.notes?.length}) Evaluations
            </span>
          </div>

          <div className="row mt-2">
            <div className="col-sm text-truncate-one">
              {profil.competences?.map((cp, index) => (
                <span
                  key={index}
                  className="mr-1 p-2"
                  style={{
                    fontSize: "12px",
                    border: "1px solid ".concat(RED),
                    background: RED,
                    color: WHITE,
                    fontWeight: "bold",
                    borderRadius: "5px"
                  }}
                >
                  {cp.nom}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  );
};
