import React from "react";
import TopBar from "../../components/TopBar";

const MainLayout = ({ children }) => {
  return (
    <div style={{ width: "100%", minHeight: 500 }}>
      <TopBar />
      {children}
    </div>
  );
};

export default MainLayout;
