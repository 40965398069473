import React, { useEffect, useState } from "react"
import { NotificationManager } from "react-notifications"
import moment from "moment"
import {
  DatePicker,
  Spin,
  Image,
  Upload,
  Input,
  Form,
  Select,
  Radio,
  Checkbox,
} from "antd"
import tokenStore from "../stores/tokenStore"
import jwt from "jsonwebtoken"
import meStore from "../stores/meStore"
import { Button } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import { refreshMe, regenerateListFromId } from "../utils/utilsFunction"
import PhoneInput from 'react-phone-input-2'
import { useHistory } from "react-router"
import { BASE_PATH, DEMANDE_ROUTE, IMAGES_ROUTE, LISTE_INVITE_CATEGORIES, LISTE_USERS } from "../utils/api.route"
import { GREEN, ORANGE, ORANGE_LIGNT, RED, WHITE } from "../theme/couleurs"
import { ACCEPTER, ASK_CERTIFICATION_MESSAGE, ATTENTE, BUSNESS, DEMANDE_DE_CERTIFICATION, OWNER, USER } from "../utils/constants"
import { MdOutlineGppGood } from 'react-icons/md'
import { BiTimeFive } from 'react-icons/bi'
import { MdDeleteForever } from 'react-icons/md'
import 'react-phone-input-2/lib/style.css'
import dayjs from "dayjs"


const Profile = ({ image, user }) => {
  const [imageState, setImageState] = React.useState(image)
  const token = tokenStore(state => state.token)
  const decodedToken = jwt.decode(token)
  const userId = decodedToken?.id
  const createMe = meStore(state => state.createMe)

  const props = {
    name: "file",
    action: LISTE_USERS.concat('/').concat(userId).concat("/image"),
    method: "put",
    showUploadList: false,
    headers: {
      authorization: "Bearer " + token,
    },
    onChange(info) {
      if (info.file.status === "done") {
        let newUrl = URL.createObjectURL(info.file.originFileObj)
        setImageState(newUrl)
        refreshMe(createMe, token)
        NotificationManager.success("Mise à jour du fichier réussi....")
      } else if (info.file.status === "error") {
        NotificationManager.error("Eurreur de la mise à jour !", null, 4000)
      }
    },
  }


  return (
    <div className="text-center">
      <Image style={{ objectFit: "cover", height: 150 }} src={imageState ? imageState : BASE_PATH.concat('/img/noProfile.jpg')} />
      <div className="my-2 fw-bold">{user && user.username} </div>

      <Upload {...props}>
        <Button size="small" icon={<UploadOutlined />}>
          Modifier
        </Button>
      </Upload>

    </div>
  )
}

const ProfileDetails = ({
  categories,
  userId,
  token,
  setLoading,
  loadUser,
  user,
  initialValues,
  loadingDemandeCertificat,
  setLoadingDemandeCertificat,
  loadingDemandeCertificatCreate,
  setLoadingDemandeCertificatCreate,
  demandeCertification,
  loadDemandeCertificat
}) => {
  const createMe = meStore(state => state.createMe)
  const me = meStore(state => state.me)
  const [accountType, setAccountType] = useState(user?.isBusiness ? BUSNESS : OWNER)
  const [showContacts, setShowContacts] = useState(user?.showContacts)
  const [showEntrepriseInformations, setShowEntrepriseInformations] = useState(user?.showEntrepriseInformations)

  const [pieces, setPieces] = useState([])
  const [showCertificationForm, setShowCertificationForm] = useState(false)

  const handleUpdate = (value) => {
    fetch(LISTE_USERS.concat('/?id=').concat(userId), {
      method: "put",
      body: JSON.stringify({
        ...value,
        isBusiness: accountType === BUSNESS ? true : false,
        showContacts,
        showEntrepriseInformations,
        competences: regenerateListFromId(categories, value.competences),
      }),
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.error)
          throw new Error(result.message ? result.message : "Erreur de mise à jour du compte !")
        NotificationManager.success("compte mise à jour...", null, 4000)
        setLoading(true)
        loadUser()
        refreshMe(createMe, token)
      })
      .catch((err) => NotificationManager.error(err.message, null, 4000))
  }

  const handleSendDemandeCertificat = async () => {
    try {
      setLoadingDemandeCertificatCreate(true)
      const request = await fetch(DEMANDE_ROUTE, {
        method: "post",
        body: JSON.stringify({
          userId: me?.id,
          message: ASK_CERTIFICATION_MESSAGE,
          type: DEMANDE_DE_CERTIFICATION,
          pieces: pieces.map(p => p.id)
        }),
        headers: {
          "content-type": "application/json",
          "Authorization": "Bearer " + token
        }
      })

      const response = await request.json()

      if (response.error)
        throw response

      NotificationManager.success('Demande envoyée avec succès', null, 8000)
      loadDemandeCertificat()
      setLoadingDemandeCertificatCreate(false)
    } catch (err) {
      
      setLoadingDemandeCertificatCreate(false)
      NotificationManager.error(err.message, null, 8000)
    }
  }

  const handleSellerTypeAccountChange = event => setAccountType(event.target.value)

  return (<div>
    <Form
      layout="vertical"
      onFinish={handleUpdate}
      initialValues={initialValues}
    >
      <div className="row">
        <div className="col-sm-12 col-md-5  my-shadow border p-4 rounded" style={{ backgroundColor: ORANGE_LIGNT }}>
          <div className="fw-bold mb-2">Informations personnelles</div>
          <div>
            <Form.Item
              label={<span>Nom</span>}
              name="nom"
              rules={[{ required: true, message: "Veuillez entrer le nom !" }]}
            >
              <Input size="large" placeholder="Entrez votre nom" />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label={<span>Prénoms</span>}
              name="prenom"
              rules={[
                { required: true, message: "Veuillez entrer le Prénoms !" },
              ]}
            >
              <Input size="large" placeholder="Votre Prénoms" />
            </Form.Item>
          </div>
          <div>
            <Form.Item label={<span>Date de naissance</span>} name="dateNaissance">
              <DatePicker
                disabledDate={date => moment() > date ? false : true}
                className="w-100"
                size="large"
              />
            </Form.Item>
          </div>
          <div className="mt-4">
            <button type="submit" style={{ background: ORANGE, padding: "5px 10px", color: WHITE, fontWeight: "bold", border: "none", cursor: "pointer" }}>
              Mise à jour
            </button>
          </div>
        </div>
      </div>
    </Form>

    <Form
      layout="vertical"
      onFinish={handleUpdate}
      initialValues={initialValues}
    >
      <div className="row">
        <div className="col-sm-12 col-md-5 my-shadow p-4 border rounded mt-3" style={{ backgroundColor: ORANGE_LIGNT }}>
          <div className="fw-bold mb-2">Informations sur le Contact</div>
          <div>
            <Form.Item
              label={<span>Email</span>}
              name="email"
              rules={[
                {
                  required: true,
                  message: "Veuiller entrer l'adresse email !",
                },
              ]}
            >
              <Input size="large" placeholder="Entrez votre Email" />
            </Form.Item>
          </div>

          <div>
            <Form.List name="tels">
              {(fields, { add, remove }) => (
                <div className="row">

                  {fields.map((field, index) => (
                    <div className="col-sm-12 col-lg-6" key={index}>
                      <div className="d-flex align-items-center">
                        <Form.Item
                          {...field}
                          label={<span>Téléphone</span>}
                          rules={[{ required: true, message: 'Please input your phone number!' }]}
                        >
                          <PhoneInput
                            country={'tg'}
                            inputStyle={{
                              width: "100%",
                            }}
                          />
                        </Form.Item>
                        <div>
                          <MdDeleteForever onClick={() => remove(index)} style={{ fontSize: '20px', cursor: 'pointer', color: RED, marginLeft: '5px' }} />
                        </div>
                      </div>
                    </div>
                  ))}

                  <button style={{ backgroundColor: "#EEE", margin: "10px 0px", width: "200px", marginLeft: "10px", padding: "4px 10px", fontWeight: "bold", border: "1px solid #ccc" }} onClick={() => add()} >+ Ajouter un numéro</button>

                </div>
              )}
            </Form.List>
          </div>

          <div className="my-2">
            <Checkbox
              checked={showContacts}
              onChange={event => setShowContacts(event.target.checked)}>
              <span className="fw-bold">Afficher les contacts aux utilisateurs ? </span>
            </Checkbox>
          </div>

          <div className="mt-4">
            <button type="submit" style={{ background: ORANGE, padding: "5px 10px", color: WHITE, fontWeight: "bold", border: "none", cursor: "pointer" }}>
              Mise à jour
            </button>
          </div>

        </div>
      </div>
    </Form>

    <Form
      layout="vertical"
      onFinish={handleUpdate}
      initialValues={initialValues}
    >
      <div className="row">
        <div className="col-sm-12 col-md-5 my-shadow p-4 border rounded mt-3" style={{ backgroundColor: ORANGE_LIGNT }} >
          <div className="fw-bold mb-2">Informations sur la Sécurité</div>
          <div>
            <Form.Item
              label={<span>Nom d'utilisateur</span>}
              name="username"
              rules={[
                { required: true, message: "Veuillez entrer le Nom d'utilisateur !" },
              ]}
            >
              <Input disabled placeholder="Votre Nom d'utilisateur" size="large" />
            </Form.Item>
          </div>
          <div>
            <Form.Item label={<span>Votre ancien mot de passe</span>} name="oldPassword">
              <Input.Password size="large" placeholder="Ancien mot de passe" />
            </Form.Item>
          </div>
          <div>
            <Form.Item label={<span>Nouveau mot de passe</span>} name="password">
              <Input.Password size="large" placeholder="nouveau mot de passe " />
            </Form.Item>
          </div>
          <div className="mt-4">
            <button type="submit" style={{ background: ORANGE, padding: "5px 10px", color: WHITE, fontWeight: "bold", border: "none", cursor: "pointer" }}>
              Mise à jour
            </button>
          </div>
        </div>

      </div>
    </Form>

    {
      me?.profil?.name === USER &&
      (
        <>
          <Form
            layout="vertical"
            onFinish={handleUpdate}
            initialValues={initialValues}
          >
            <div className="row">
              <div className="col-sm-12 col-md-5 my-shadow p-4 border rounded mt-3" style={{ backgroundColor: ORANGE_LIGNT }}>
                <div className="fw-bold mb-2">Information sur les Catégories ( compétences ) </div>
                <p className="my-1">La sélection de ses catégories vous permettras de faire des prestations de services dans ses différentes catégories chosies.</p>
                <div>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Vous devez choisir une Catégorie !",
                      },
                    ]}
                    label={<span>Catégories</span>}
                    help={
                      <p>
                        Selectionné vos Catégories !
                      </p>
                    }
                    name="competences"
                  >
                    <Select
                      showSearch
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      allowClear
                      size="large"
                      placeholder="Choisir vos catégories"
                      mode="multiple"
                    >
                      {
                        categories.filter(item => item.parent !== null && item.level === 2)
                          .map(cat => <Select.Option key={cat.id} value={cat.nom}> {cat.nom}</Select.Option>)
                      }
                    </Select>
                  </Form.Item>
                </div>
                <div className="mt-4">
                  <div>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Ce champ est obligatoire",
                        },
                      ]}
                      label={<span>Précision sur ce que vous faite dans ses compétences</span>}
                      help={
                        <p>Donnez un petit résumer et précis de ce que vous faite exactement dans ses catégories.</p>
                      }
                      name="precisionCompetence"
                    >
                      <Input.TextArea size="large" placeholder="détail" />

                    </Form.Item>
                  </div>
                </div>
                <div className="mt-4">
                  <button type="submit" style={{ background: ORANGE, padding: "5px 10px", color: WHITE, fontWeight: "bold", border: "none", cursor: "pointer" }}>
                    Mise à jour
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </>
      )
    }

    {
      me?.profil?.nom === USER &&
      (
        <>
          <Form
            layout="vertical"
            onFinish={handleUpdate}
            initialValues={initialValues}
          >
            <div className="row">
              <div className="col-sm-12 col-md-5 my-shadow p-4 rounded mt-3 border" style={{ backgroundColor: ORANGE_LIGNT }} >
                <div className="fw-bold mb-2">Information sur l'entreprise</div>

                {user?.isBusiness !== null && user?.isBusiness !== undefined && (
                  <div>

                    {
                      !user?.isBusiness && (
                        <div
                          style={{
                            margin: "20px 0px"
                          }}>
                          <Radio.Group onChange={handleSellerTypeAccountChange} value={accountType} >
                            <Radio value={OWNER} ><span style={{ fontWeight: "bold" }}>Je suis un particulier</span></Radio>
                            <Radio value={BUSNESS}><span style={{ fontWeight: "bold" }}>Je suis une entreprise</span></Radio>
                          </Radio.Group>
                        </div>
                      )
                    }

                    {
                      accountType === BUSNESS && (
                        <>
                          <div >
                            <Form.Item
                              name="nomEntreprise"
                              label={<span>Nom de la société ( obligatoire ) </span>}
                              rules={[{ required: true, message: "Ce champ est obligatoire" }]}
                            >
                              <Input placeholder="Nom de la société" size="large" />
                            </Form.Item>
                          </div>
                          <div >
                            <Form.Item
                              name="emailEntreprise"
                              label={<span>Email de l'entreprise </span>}
                            >
                              <Input placeholder="Email de l'entreprise" type="email" size="large" />
                            </Form.Item>
                          </div>
                          <div >
                            <Form.Item
                              name="telEntreprise"
                              label={<span>Numéro de téléphone de l'entreprise</span>}
                            >
                              <PhoneInput
                                country={'tg'}
                                inputStyle={{ width: "100%" }}
                                placeholder="Numéro de téléphone de l'entreprise"
                              />
                            </Form.Item>
                          </div>
                          <div >
                            <Form.Item
                              name="adresseEntreprise"
                              label={<span>Adresse </span>}
                            >
                              <Input placeholder="Adresse de l'entreprise" size="large" />
                            </Form.Item>
                          </div>
                          <div >
                            <Form.Item
                              name="immatriculationEntreprise"
                              label={<span>Numéro d'immatriculation</span>}
                            >
                              <Input placeholder="Immatriculation de l'entreprise" size="large" />

                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="tvaEntreprise"
                              label={<span>Numéro TVA</span>}
                            >
                              <Input placeholder="TVA" size="large" />
                            </Form.Item>
                          </div>

                          <div className="my-2">
                            <div>
                              <Checkbox checked={showEntrepriseInformations} onChange={event => setShowEntrepriseInformations(event.target.checked)}><span className="fw-bold">Afficher les informations de l'entreprise aux utilisateurs ? </span></Checkbox>
                            </div>
                          </div>
                        </>
                      )
                    }
                  </div>
                )}

                <div className="mt-4">
                  <button type="submit" style={{ background: ORANGE, padding: "5px 10px", color: WHITE, fontWeight: "bold", border: "none", cursor: "pointer" }}>
                    Mise à jour
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </>
      )
    }


    {
      me?.profil?.nom === USER &&
      (
        <>
          <Form
            layout="vertical"
          // onFinish={handleUpdate}
          // initialValues={initialValues}
          >
            <div className="row">
              <div className="col-sm-12 col-md-5 my-shadow p-4 rounded mt-3 border" style={{ backgroundColor: ORANGE_LIGNT }} >
                <div className="fw-bold mb-2">Certification</div>
                {
                  demandeCertification ? (
                    <>
                      {demandeCertification.status === ATTENTE && (
                        <div style={{ padding: '10px', background: ORANGE.concat(20), border: '1px solid '.concat(ORANGE), fontWeight: 'bold' }}>
                          <span style={{ marginRight: '10px' }}><BiTimeFive style={{ color: ORANGE, fontSize: '25px' }} /> </span>
                          <span style={{ fontWeight: 'bold' }}> Votre demande est en cours de traitement !</span>
                        </div>
                      )}
                      {demandeCertification.status === ACCEPTER && (
                        <div style={{ padding: '10px', background: GREEN.concat(20), border: '1px solid '.concat(GREEN) }}>
                          <span style={{ marginRight: '10px' }}><MdOutlineGppGood style={{ color: GREEN, fontSize: '25px' }} /> </span>
                          <span style={{ fontWeight: 'bold' }}> Votre compte est certifié</span>
                        </div>
                      )}
                    </>
                  ) :
                    (
                      <>

                        <div style={{ padding: "10px", background: "#FFF", border: "1px solid #ccc", color: "#000" }}>
                          la certification de votre compte, atteste que vos informations ont été vérifiées, que vous êtes un vrai prestataire ou une entreprise sérieuse.
                          Elle garantit que vous êtes réelles et rassure les clients pour leurs commandes.
                        </div>
                        {
                          !showCertificationForm && (
                            <div className="my-2">
                              <button style={{ background: ORANGE, padding: "5px 10px", color: WHITE, fontWeight: "bold", border: "none", cursor: "pointer" }} onClick={() => setShowCertificationForm(true)}>
                                <span> Demander une certification</span>
                              </button>
                            </div>
                          )
                        }

                        {
                          showCertificationForm && (
                            <div className='my-4' style={{ background: "#fff", padding: "10px", border: '1px solid #ccc' }}>
                              <div className="my-2" >
                                <span style={{ fontWeight: 'bold' }}>Photos de la pièce d'identité</span>
                                <span style={{ marginLeft: '5px' }}>( Carte d'identité ( devant et derrière ) , Passport , Carte d'élècteur )</span>
                              </div>
                              <div>
                                <Upload
                                  name="files"
                                  action={IMAGES_ROUTE.concat('/multiples?nom=prestation')}
                                  method="post"
                                  headers={{ authorization: "Bearer " + token }}
                                  listType="picture-card"
                                  onChange={(info) => {
                                    if (info.file.status === "uploading") {
                                      return;
                                    }
                                    if (info.file.status === "done") {
                                      setPieces(pieces.concat(info.file.response[0]))
                                    }
                                  }}
                                >
                                  <div className="my-2" />
                                  <div>Images</div>
                                </Upload>
                                <div className="mt-2">
                                  <span style={{ fontWeight: 'bold' }}>NB: </span>
                                  <span>Ses informations sont vraiment importantes. Une fois que votre demande serait envoyée, elle serait traitée dans les 48 heures.</span>
                                </div>
                              </div>
                              {
                                pieces.length > 0 && (
                                  <div className="my-2">
                                    <button onClick={handleSendDemandeCertificat} style={{ fontWeight: 'bold', backgroundColor: GREEN, color: '#fff', padding: '5px 10px' }}>
                                      {loadingDemandeCertificatCreate && (<span className="my-spinner spinner-white"></span>)}
                                      <span style={{ marginLeft: '10px' }}>
                                        Envoyer maintenant la demande
                                      </span>
                                    </button>
                                  </div>
                                )
                              }
                            </div>
                          )
                        }
                      </>
                    )
                }




              </div>
            </div>
          </Form>
        </>
      )
    }

  </div>)
}

const AdminAccount = () => {
  const token = tokenStore(state => state.token)
  const decodedToken = jwt.decode(token)
  const me = meStore(state => state.me)
  const image = me?.userImage?.url
  const [user, setUser] = useState(null)
  const userId = decodedToken?.id
  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState(null)
  const [categories, setCategories] = useState([])
  const history = useHistory()

  const [demandeCertification, setDemandeCertification] = useState(null)
  const [loadingDemandeCertificat, setLoadingDemandeCertificat] = useState(false)
  const [loadingDemandeCertificatCreate, setLoadingDemandeCertificatCreate] = useState(false)


  const loadDemandeCertificat = async () => {
    try {
      setLoadingDemandeCertificat(true)

      const request = await fetch(DEMANDE_ROUTE
        .concat("/")
        .concat(me.id)
        .concat('?type='.concat(DEMANDE_DE_CERTIFICATION)), {
        method: "get",
        headers: {
          "Authorization": "Bearer " + token
        }
      })

      const response = await request.json()
      if (response.error)
        throw response

      setDemandeCertification(response.demande)
      setLoadingDemandeCertificat(false)
    } catch (err) {
      
      setLoadingDemandeCertificat(false)
    }
  }


  const loadUser = () => {
    fetch(LISTE_USERS.concat('/').concat(userId), {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error)
          return history.push("/dashboard/notFound")
        setUser(data)


        setInitialValues({
          nom: data.nom,
          prenom: data.prenom,
          username: data.username,
          tel: data.tel,
          password: "",
          email: data.email,
          description: data?.description,
          notes: data.note,
          tels: data.tels,
          nomEntreprise: data.nomEntreprise,
          telEntreprise: data.telEntreprise,
          immatriculationEntreprise: data.immatriculationEntreprise,
          emailEntreprise: data.emailEntreprise,
          tvaEntreprise: data.tvaEntreprise,
          showEntrepriseInformations: data.showEntrepriseInformations,
          showContacts: data.showContacts,
          adresseEntreprise: data.adresseEntreprise,
          isBusiness: data.isBusiness,
          competences: data.competences?.map(c => c.nom) || [],
          dateNaissance: data.dateNaissance ? dayjs(data.dateNaissance) : null,
        })

        setLoading(false)
      })
      .catch((err) => {
        NotificationManager.error(err.message)
        setLoading(false)
      })
  }

  const loadInviteCategories = () => {
    fetch(LISTE_INVITE_CATEGORIES, {
      method: "get",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.error)
          throw new Error("Erreur de chargement des catégorie !")
        setCategories(result)
      })
      .catch((err) => NotificationManager.error(err.message, null, 4000))
  }




  useEffect(() => {
    if (userId) {
      setLoading(true)
      loadInviteCategories()
      loadUser()
      loadDemandeCertificat()
    }
  }, [])




  return (
    <div>
      {loading ? (
        <Spin tip="loading...." />
      ) : (
        <div>
          <div style={{ textDecoration: "underline", fontWeight: "bold", margin: "10px", color: ORANGE, fontSize: "16px" }}>
            Mon Compte
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-3">
              <div className="my-shadow p-4 bg-white rounded border" style={{ position: 'sticky', top: 70 }}>
                <div className="fw-bold">Profil</div>
                <Profile image={image} user={user} token={token} loadUser={loadUser} />
              </div>
            </div>
            <div className="col-sm-12 col-md-9">
              <ProfileDetails
                initialValues={initialValues}
                categories={categories}
                user={user}
                loadUser={loadUser}
                token={token}
                userId={userId}
                setLoading={setLoading}
                loadDemandeCertificat={loadDemandeCertificat}
                demandeCertification={demandeCertification}
                loadingDemandeCertificat={loadingDemandeCertificat}
                setLoadingDemandeCertificat={setLoadingDemandeCertificat}

                loadingDemandeCertificatCreate={loadingDemandeCertificatCreate}
                setLoadingDemandeCertificatCreate={setLoadingDemandeCertificatCreate}
              />
            </div>
            <div>
            </div>
          </div>
        </div>
      )
      }
    </div>
  )
}

export default AdminAccount
