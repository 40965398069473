import React from 'react'

const AdminDashobard = () => {
  return (
    <>
      page de Dashobard
    </>
  )
}



export default AdminDashobard
