import React, { useState, useEffect } from "react"
import { NotificationManager } from "react-notifications"
import DeleteConfirmationPopup from "../components/DeleteConfirmationPopup"
import {
  DeleteOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons"
import { Spin, Table } from "antd"
import tokenStore from "../stores/tokenStore"
import Title from "antd/lib/typography/Title"
import Popovers from "../components/Popover"
import { PROFILS_ROUTE } from '../utils/api.route'
import MyPagination from '../components/MyPagination'
import { ORANGE } from "../theme/couleurs"

const Profile = () => {
  const [profils, setProfils] = useState([])
  const token = tokenStore(state => state.token)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [totalElements, setTotalElements] = useState(0)
  const pageSize = 2

  const Results = () => {
    const columns = [
      {
        key: "id",
        title: (
          <Title level={4} style={{ fontSize: 13 }}>
            Id
          </Title>
        ),
        width: 50,
        dataIndex: "id",
        render: (value) => <p style={{ fontSize: 12 }}>{value}</p>,
      },
      {
        key: "nom",
        title: (
          <Title level={4} style={{ fontSize: 13 }}>
            Nom du profil
          </Title>
        ),
        dataIndex: "nom"
      },
      {
        key: "actions",
        title: (
          <Title level={4} style={{ fontSize: 13 }}>
            Actions
          </Title>
        ),
        dataIndex: "action",
        fixed: "right",
        render: (value) => (
          <div>
            <EyeInvisibleOutlined
              style={{ cursor: "pointer" }}

            />
            <Popovers
              content={
                <DeleteConfirmationPopup
                  id={value?.id}
                  nom={value?.nom}
                  handleDelete={() => handleDeleteProfile(value?.id)}
                />
              }
            >
              <DeleteOutlined style={{ cursor: "pointer" }} />
            </Popovers>
          </div>
        ),
      },
    ]

    const datas = profils.map((p) => ({
      key: p.id,
      id: p.id,
      nom: p.nom,
      actions: p,
    }))

    return loading ? (
      <Spin tip="loading...." />
    ) : (
      <div>
        <Table
          bordered
          size="small"
          dataSource={datas}
          columns={columns}
          pagination={false}
        />
        <div className="mt-2">
          {totalPages > 1 && profils.length > 0 && (
            <MyPagination
              currentPage={page}
              setCurrentPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>
    )
  }

  const handleDeleteProfile = (id) => {
    fetch(PROFILS_ROUTE.concat('/').concat(id), {
      method: "delete",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) throw new Error(data.message)
        NotificationManager.success("Suppression réussie...", null, 3000)
        return setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        NotificationManager.error(err.message, null, 3000)
      })

  }

  const loadProfils = () => {
    setLoading(true)
    fetch(PROFILS_ROUTE.concat('?page=').concat(page).concat('&size=').concat(pageSize), {
      method: "get",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((datas) => {
        if (datas.error)
          throw new Error(datas.message ? datas.message : datas.error)

        setTotalElements(datas.totalElements)
        setTotalPages(datas.totalPages)
        setProfils(datas.content)
        return setLoading(false)
      })
      .catch((err) => {
        NotificationManager.error(err.message, null, 3000)
        setLoading(false)
      })
  }

  useEffect(() => {
    loadProfils()
  }, [page])

  return (
    <div style={{ marginTop: 10, background: "#fff", padding: "10px" }}>
      <div style={{ fontSize: "16px", fontWeight: "bold", textDecoration: "underline", margin: "10px 0px"  , color: ORANGE}}>List des profils</div>
      <div style={{ display: "flex", flexWrap: "wrap", width: 400 }}>
        <div>
          Nombres de profils:
          <span style={{ color: "#00000090", fontWeight: "bold", marginLeft: "10px" }}>
            {totalElements} élément(s)
          </span>
        </div>
      </div>

      <div>
        {Results()}
      </div>
    </div>
  )
}

export default Profile
