import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useHistory, useParams } from 'react-router-dom'
import tokenStore from '../stores/tokenStore'
import { BASE_PATH, COMMANDES_ROUTE, FEEDBACKS_ROUTE } from '../utils/api.route'
import jwt from 'jsonwebtoken'
import { Button, Image, Popover, Rate, Spin, Tabs, Tag, Form, DatePicker, TimePicker, Radio } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import Modal from 'antd/lib/modal/Modal'
import TextArea from 'antd/lib/input/TextArea'
import { ORANGE, RED, GREEN, BLUE, GREEN_DARK, ORANGE_LIGNT, WHITE, BLACK } from '../theme/couleurs'
import dayjs from 'dayjs'
import moment from 'moment'
import PageNotFound from './404'
import { ACCEPTER, ATTENTE, CLIENT, COMMENCER, DEMANDEUR, NEGATIVE, NEUTRE, PAUSE, POSITIVE, PRESTATAIRE, REFUSER, TERMINER, TYPE_FEEDBACK_VENDEUR } from '../utils/constants'
import { EuiButton, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiTextArea, EuiRadioGroup, Euira$, EuiRadio, EuiDatePicker } from '@elastic/eui'
import 'dayjs/plugin/relativeTime'
import { compareTwoDate } from '../utils/utilsFunction'


const AdminDetailCommande = () => {
    const { id, typeUser } = useParams()
    const history = useHistory()
    const [commande, setCommande] = useState(null)
    const token = tokenStore(state => state.token)
    const decodedToken = jwt.decode(token)
    const userId = decodedToken?.id

    const [loadingCommande, setLoadingCommande] = useState(false);
    const [loadingAcceptationSansDevis, setLoadingAcceptationSansDevis] = useState(false)
    const [loadingTerminerService, setLoadingTerminerService] = useState(false)
    const [loadingRefus, setLoadingRefus] = useState(false)
    const [loadingDemarrerService, setLoadingDemarrerService] = useState(false)
    const [loadingAnnuler, setLoadingAnnuler] = useState(false)
    const [loadingApprouverDelais, setLoadingApprouverDelais] = useState(false)
    const [loadingNoteAndFeedback, setLoadingNoteAndFeedback] = useState(false)
    const [loadingSuggestion, setLoadingSuggestion] = useState(false)

    const [raisonPopup, setRaisonPopup] = useState({ visible: false, datas: null, typeUser: null })
    const [raison, setRaison] = useState(null)

    const [devisPopup, setDevisPopup] = useState({ visible: false, datas: null })

    const [visibleNoteAndFeedbackPopup, setVisibleNoteAndFeedbackPopup] = useState(false)
    const [typeNoteCommande, setTypeNoteCommande] = useState(POSITIVE)
    const [noteCommande, setNoteCommande] = useState(null)
    const [commentaireCommande, setCommentaireCommande] = useState("")
    const [typeNoteVendeur, setTypeNoteVendeur] = useState(POSITIVE)
    const [noteVendeur, setNoteVendeur] = useState(null)
    const [noteCommunicationVendeur, setNoteCommunicationVendeur] = useState(null)
    const [noteDelaisVendeur, setNoteDelaisVendeur] = useState(null)
    const [commentaireVendeur, setCommentaireVendeur] = useState("")
    const [errorFeedbackMessage, setErrorFeedbackMessage] = useState(null)

    const [delaisLivraison, setDelaisLivraison] = useState(moment())


    const loadCommande = () => {
        setLoadingCommande(true)
        fetch(
            COMMANDES_ROUTE.concat('/').concat(id), {
            method: "get",
            headers: {
                'Content-type': 'application/json',
                Authorization: "Bearer " + token
            },
        })
            .then(response => response.json())
            .then((result) => {
                if (result.error)
                    throw new Error(result.message ? result.message : result.error)


                setCommande(result.commande)
                setLoadingCommande(false)
            })
            .catch((err) => {
                setLoadingCommande(false)
                NotificationManager.error(err.message, null, 9000)
            })
    }

    const handleAnnulerCommande = ({ id, setLoading }) => {
        setLoading(true)
        fetch(COMMANDES_ROUTE.concat('?id=').concat(id), {
            method: 'delete',
            headers: {
                'content-type': 'application/json',
                Authorization: 'Bearer ' + token,
            }
        })
            .then(response => response.json())
            .then(result => {
                if (result.error)
                    throw new Error(result.message ? result.message : result.error)
                setLoading(false)
                NotificationManager.success("Commande annuler...", null, 6000)
                history.push('/dashboard/commandes/'.concat(typeUser))
            })
            .catch(err => {
                setLoading(false)
                NotificationManager.error(err.message, null, 9000)
            })
    }

    const handleUpdateCommande = ({ data, setLoading }) => {
        setLoading && setLoading(true)
        fetch(COMMANDES_ROUTE.concat('?id=').concat(commande.id), {
            method: 'put',
            body: JSON.stringify(data),
            headers: {
                'content-type': 'application/json',
                Authorization: 'Bearer ' + token,
            }
        })
            .then(response => response.json())
            .then(result => {
                if (result.error)
                    throw new Error(result.message ? result.message : result.error)
                setLoading && setLoading(false)
                NotificationManager.success("Mise à jour ok...", null, 6000)
                loadCommande()
            })
            .catch(err => {
                setLoading && setLoading(false)
                NotificationManager.error(err.message, null, 9000)
            })
    }

    const sendFeedback = async _ => {
        try {
            setLoadingNoteAndFeedback(true)
            setErrorFeedbackMessage(null)
            let payload = {
                typeNoteCommande,
                typeNoteVendeur,
                typeFeedback: TYPE_FEEDBACK_VENDEUR,
                commandeId: commande.id
            }
            if (noteCommande && noteCommande > 0) {
                payload.noteCommande = noteCommande
            }
            if (noteVendeur && noteVendeur > 0) {
                payload.noteVendeur = noteVendeur
            }
            if (commentaireCommande && commentaireCommande.trim()?.length > 0) {
                payload.commentaireCommande = commentaireCommande
            }
            if (commentaireVendeur && commentaireVendeur.trim()?.length > 0) {
                payload.commentaireVendeur = commentaireVendeur
            }
            if (noteCommunicationVendeur && noteCommunicationVendeur > 0) {
                payload.noteCommunicationVendeur = noteCommunicationVendeur
            }
            if (noteDelaisVendeur && noteDelaisVendeur > 0) {
                payload.noteDelaisVendeur = noteDelaisVendeur
            }

            const request = await fetch(FEEDBACKS_ROUTE, {
                method: 'post',
                body: JSON.stringify(payload),
                headers: {
                    'content-type': 'application/json',
                    Authorization: 'Bearer ' + token,
                }
            })
            const response = await request.json()
            if (response.error)
                throw new Error(response.message ? response.message : response.error)

            NotificationManager.success("Commande notée...", null, 6000)

            loadCommande()
            setLoadingNoteAndFeedback(false)
            setVisibleNoteAndFeedbackPopup(false)
            resetNoteState()
        } catch (err) {
            setLoadingNoteAndFeedback(false)
            
            setErrorFeedbackMessage(err.message)
        }
    }

    const resetNoteState = () => {
        setNoteCommande(null)
        setCommentaireCommande(null)
        setNoteCommunicationVendeur(null)
        setNoteDelaisVendeur(null)
    }

    const handleSuggestionDelaisLivraison = () => {
        const button = document.createElement('button')
        document.body.append(button)
        if (button) {
            button.setAttribute('data-target', '#delaisModal')
            button.setAttribute('data-toggle', 'modal')
            button.click()
            button.remove()
        }

    }


    const handleSendDelaisSuggestion = _ => {

        setLoadingSuggestion(true)

        let newObject = null
        if (typeUser === PRESTATAIRE) {
            newObject = { suggestionDelaisLivraisonPrestataire: moment(delaisLivraison).format('YYYY-MM-DD HH:mm:ss') }
        }
        if (typeUser === DEMANDEUR) {
            newObject = { suggestionDelaisLivraisonClient: moment(delaisLivraison).format('YYYY-MM-DD HH:mm:ss') }
        }
        if (newObject) {
            fetch(
                COMMANDES_ROUTE.concat('?id=').concat(id), {
                method: "put",
                body: JSON.stringify(newObject),
                headers: {
                    'Content-type': 'application/json',
                    Authorization: "Bearer " + token
                },
            })
                .then(response => response.json())
                .then(response => {
                    if (response.error) throw new Error(response.message ? response.message : "Erreur de suggestion de la date !")
                    NotificationManager.success("Délais suggeré", null, 6000)
                    const btn = document.getElementById('suggestion-popup-close-btn')
                    btn && btn.click()
                    setLoadingSuggestion(false)
                    loadCommande()

                })
                .catch(err => {
                    setLoadingSuggestion(false)
                    NotificationManager.error(err.message, 'Error', 9000)
                })
        }

    }
    const handleAcceptationDelaisLivraison = () => {
        let newObject = null
        if (typeUser === PRESTATAIRE) {
            newObject = { delaisLivraison: dayjs(commande.suggestionDelaisLivraisonClient).format('YYYY-MM-DD HH:mm:ss') }
        }
        if (typeUser === DEMANDEUR) {
            newObject = { delaisLivraison: dayjs(commande.suggestionDelaisLivraisonPrestataire).format('YYYY-MM-DD HH:mm:ss') }
        }
        if (newObject) {
            setLoadingApprouverDelais(true)
            fetch(
                COMMANDES_ROUTE.concat('?id=').concat(id), {
                method: "put",
                body: JSON.stringify(newObject),
                headers: {
                    'Content-type': 'application/json',
                    Authorization: "Bearer " + token
                },
            })
                .then(response => response.json())
                .then(response => {
                    if (response.error) throw new Error(response.message ? response.message : "Echec d'acceptation !")
                    NotificationManager.success("Délais Approuver", null, 6000)
                    setLoadingApprouverDelais(false)
                    loadCommande()
                })
                .catch(err => {
                    NotificationManager.error(err.message, null, 9000)

                    setLoadingApprouverDelais(false)
                })
        }
    }

    const handleSendNoteAndFeedback = () => {
        sendFeedback()
    }

    const DisplayNoteAndFeedbacksModal = () => {
        return visibleNoteAndFeedbackPopup && (
            <>
                <EuiModal onClose={() => setVisibleNoteAndFeedbackPopup(false)} style={{ width: "500px" }}>
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>
                            <div style={{ fontSize: "18px" }}>Evaluations</div>
                        </EuiModalHeaderTitle>
                    </EuiModalHeader>

                    <EuiModalBody style={{ border: "1px solid #ccc" }}>

                        {errorFeedbackMessage && errorFeedbackMessage.trim()?.length > 0 && (
                            <p className='alert alert-danger'> {errorFeedbackMessage}</p>
                        )}

                        <div>
                            <div style={{ fontWeight: "bold" }}>Evaluations sur la commande</div>
                            <div>
                                <div>
                                    <Radio.Group className='mt-2' onChange={event => setTypeNoteCommande(event.target.value)} value={typeNoteCommande}>
                                        <Radio value={POSITIVE} >Note positives</Radio>
                                        <Radio value={NEGATIVE} >Note négatives</Radio>
                                        <Radio value={NEUTRE} >Note neutres</Radio>
                                    </Radio.Group>
                                </div>
                                <div>
                                    <span> Noter: </span>
                                    <span style={{ marginLeft: "10px" }}><Rate value={noteCommande} onChange={value => setNoteCommande(value)} /></span>
                                </div>
                                <div className='mt-3'>
                                    <div>Commenter</div>
                                    <EuiTextArea
                                        fullWidth
                                        placeholder="Commentaires"
                                        value={commentaireCommande}
                                        onChange={event => setCommentaireCommande("".concat(event.target.value))}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr className='my-2' />
                        <div className='mt-2'>
                            <div></div>
                            <div style={{ fontWeight: "bold" }}>Evaluations sur le Vendeur ( prestataire ) </div>
                            <div>
                                {/* <div className='mt-2'>
                                    <div>
                                        <Radio.Group className='mt-2' onChange={event => setTypeNoteVendeur(event.target.value)} value={typeNoteVendeur}>
                                            <Radio value={POSITIVE} >Note positives</Radio>
                                            <Radio value={NEGATIVE} >Note négatives</Radio>
                                            <Radio value={NEUTRE} >Note neutres</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div>
                                    <span> Noter: </span>
                                    <span style={{ marginLeft: "10px" }}>
                                        <Rate value={noteVendeur} onChange={value => setNoteVendeur(value)} />
                                    </span>
                                </div> */}
                                <div className='mt-3'>
                                    <div className='fw-bold'> Note sur la communication </div>
                                    <div style={{ color: "#00000099" }}> Avez vous ue une bonne communication avec le vendeur ? </div>
                                    <div><span>Noter: </span>
                                        <span style={{ marginLeft: "10px" }}>
                                            <Rate value={noteCommunicationVendeur} onChange={value => setNoteCommunicationVendeur(value)} />
                                        </span>
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <div className='fw-bold'> Note sur le délais ou livraison</div>
                                    <div style={{ color: "#00000099" }}> Le vendeur a t'il respecté le délais de finition ? Le produit est il livré a temp </div>
                                    <span> Noter: </span>
                                    <span style={{ marginLeft: "10px" }}><Rate value={noteDelaisVendeur} onChange={value => setNoteDelaisVendeur(value)} /></span>
                                </div>

                                <div className='mt-3'>
                                    <div>Votre commentaires</div>
                                    <EuiTextArea
                                        fullWidth
                                        placeholder="Commentaires"
                                        value={commentaireVendeur}
                                        onChange={event => setCommentaireVendeur(event.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </EuiModalBody>

                    <EuiModalFooter>
                        <EuiButton
                            size='s'
                            onClick={() => setVisibleNoteAndFeedbackPopup(false)}
                            color="danger"
                        >
                            Cancel
                        </EuiButton>
                        <EuiButton
                            onClick={handleSendNoteAndFeedback}
                            fill
                            size='s'
                        >
                            Envoyer
                        </EuiButton>
                    </EuiModalFooter>
                </EuiModal>
            </>
        )
    }

    const DisplayRaisonModal = () => {
        return (
            <Modal
                title="Raison de refus !"
                open={raisonPopup.visible}
                onCancel={() => setRaisonPopup({ ...raisonPopup, visible: false, data: null })}
                onOk={() => {
                    raisonPopup.typeUser === PRESTATAIRE && handleUpdateCommande({ data: { acceptationPrestataire: 'REFUSER', raisonPrestataire: raison }, setLoading: setLoadingRefus })
                    raisonPopup?.typeUser === DEMANDEUR && handleUpdateCommande({ data: { acceptationDemandeur: 'REFUSER', raisonDemandeur: raison }, setLoading: setLoadingRefus })

                    setRaisonPopup({ ...raisonPopup, visible: false, data: null })
                }}
            >
                <TextArea placeholder="Raison" required onChange={(value) => setRaison(value.target.value)} />
            </Modal>
        )
    }

    const DevisModalContent = () => {
        return (
            <Modal
                title="Faire un devis"
                open={devisPopup.visible}
                onCancel={() => setDevisPopup({ visible: false, data: null })}
                onOk={() => {
                    console.log("devis click")
                }}
            >
                <Button size="small" type="primary"> Importer mon dévis </Button>
            </Modal>
        )
    }

    const ListActionsButton = () => (
        <div style={{ display: "flex", flexDirection: "column" }} className="mt-2">

            <div style={{ fontWeight: "bold", color: "#000", fontSize: "13px", marginBottom: "10px", textDecoration: 'underline' }}>Actions à Effectuer :</div>

            {typeUser === PRESTATAIRE && commande.acceptationPrestataire === ATTENTE && (
                <Button size="small" style={{ color: "#ffffff", background: "#007f5f", }} loading={loadingAcceptationSansDevis} onClick={() => handleUpdateCommande({ data: { id, acceptationPrestataire: 'ACCEPTER', delaisLivraison: commande && dayjs(commande.suggestionDelaisLivraisonClient).format('YYYY-MM-DD HH:mm:ss') }, setLoading: setLoadingAcceptationSansDevis })}> Accepter </Button>
            )}

            {typeUser === PRESTATAIRE && commande.acceptationPrestataire === ATTENTE && commande.statusTravail !== TERMINER && (
                <Button size="small" style={{ color: "#ffffff", background: ORANGE, width: "100%" }} loading={loadingRefus} onClick={() => setRaisonPopup({ visible: true, typeUser: PRESTATAIRE, datas: { data: { id, acceptationPrestataire: 'REFUSER' }, setLoading: setLoadingRefus } })}> Refuser la commande</Button>
            )}

            {typeUser === PRESTATAIRE && commande.acceptationPrestataire === ACCEPTER && (
                commande.acceptationPrestataire === ACCEPTER && commande.statusTravail !== COMMENCER && commande.statusTravail !== TERMINER && <Button size="small" style={{ color: "#ffffff", background: "blue", width: "100%" }} loading={loadingDemarrerService} onClick={() => handleUpdateCommande({ data: { id, statusTravail: COMMENCER }, setLoading: setLoadingDemarrerService })}> Démarrer service</Button>
            )}

            {typeUser === PRESTATAIRE && commande.acceptationPrestataire === ACCEPTER && commande.statusTravail !== TERMINER && <Button size="small" style={{ color: "#ffffff", background: "#007f5f", border: "none", width: "100%" }} loading={loadingTerminerService} onClick={() => handleUpdateCommande({ data: { id, statusTravail: TERMINER }, setLoading: setLoadingTerminerService })} > Terminer service </Button>}

            {
                typeUser === DEMANDEUR &&
                commande.acceptationPrestataire === ATTENTE && (
                    <Button size="small" style={{ background: RED, color: "#ffffff" }} loading={loadingAnnuler} >
                        <Popover title="Confirmation" content={<div>
                            <div className="my-2">Cette commande va ètre annuler.</div>
                            <div className='fw-bold my-1'>Voulez-vous vraiment continué ?</div>
                            <Button loading={loadingAnnuler} onClick={() => handleAnnulerCommande({ id, setLoading: setLoadingAnnuler, token })} size="small"
                                style={{
                                    background: RED, color: "#fff",
                                    padding: "0px 20px",
                                    border: "none",
                                    width: "100%"
                                }}>Oui</Button>
                        </div>}> Annuler commande</Popover>
                    </Button>
                )
            }

            {
                typeUser === DEMANDEUR &&
                commande.acceptationPrestataire === ACCEPTER &&
                commande.statusTravail === TERMINER &&
                !commande.isRated && (
                    <button
                        title='Seuls tous ceux qui ont effectué les transactions par la plateforme, peuvent noter'
                        disabled
                        style={{ width: "100%", color: "#fff", backgroundColor: BLUE, padding: "5px 10px" }}
                        onClick={() => setVisibleNoteAndFeedbackPopup(true)}
                    > Notes et feedbacks</button>
                )
            }
        </div>
    )

    const DisplayInfoMenuContent = () => (
        <div style={{ background: ORANGE_LIGNT, border: '1px solid '.concat(ORANGE).concat('20'), padding: '15px' }}>
            <div style={{ fontWeight: "bold", fontSize: "16px" }}> {commande?.prestation?.titre}</div>
            <div style={{ color: "#000", margin: '10px 0px' }}> {commande?.prestation?.description} </div>

            <div className="mt-2">
                <span className="mr-2" style={{ fontWeight: "bold" }}>Prix de vente:  </span>
                <Tag color={ORANGE} style={{ fontSize: 10, fontWeight: "bold" }} size="small">
                    {(commande?.prestation?.cout * commande?.quantiteDemander) + " " + commande?.prestation?.unite?.toLowerCase()}
                </Tag>
                <span> pour ( <span style={{ fontWeight: "bold", color: ORANGE }}>{commande?.prestation?.quantite}</span> ) quantitées</span>
            </div>
            <div className="mt-2">
                <span className="mr-2" style={{ fontWeight: "bold" }}>Quantité commandée: </span>
                <span color="#007f5f" style={{ fontSize: 12, fontWeight: "bold", color: ORANGE }}>{commande?.quantiteDemander} </span>
            </div>
            <div className='mt-2'>
                <span className="mr-2" style={{ fontWeight: "bold" }}> {typeUser === PRESTATAIRE ? "Mon Acceptation : " : "Acceptation du prestataire: "}</span>
                <span>
                    {commande?.acceptationPrestataire === ACCEPTER && <Tag size="small" style={{ fontSize: 10, fontWeight: "bold" }} color={GREEN_DARK} key={commande?.acceptationPrestataire} >Acceptée</Tag>}
                    {commande?.acceptationPrestataire === REFUSER && <Tag size="small" color="#b21e35" style={{ fontSize: 10, fontWeight: "bold" }} key={commande?.acceptationPrestataire} >Refusée</Tag>}
                    {commande?.acceptationPrestataire === ATTENTE && <Tag size="small" color="#b88b4a" style={{ fontSize: 10, fontWeight: "bold" }} key={commande?.acceptationPrestataire} > En Attente</Tag>}
                </span>
            </div>
            {
                commande?.detail && (
                    <div className='mt-2 p-2 border' style={{ background: "#FFF" }}>
                        <div>
                            {typeUser === PRESTATAIRE && (
                                <span style={{ color: ORANGE, textDecoration: 'underline', fontWeight: 'bold' }}>Précision du client</span>
                            )}

                            {typeUser === DEMANDEUR && (
                                <span style={{ color: ORANGE, textDecoration: 'underline', fontWeight: 'bold' }}>Ma précision</span>
                            )}
                        </div>
                        <div className='mt-2'>
                            {
                                commande.detail
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )

    const DisplayContentDetail = () => (
        <div className="mt-4 border rounded my-shadow py-2 px-4 bg-light" style={{ maxWidth: "90%" }}>
            <div style={{ padding: 2, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between" }} >
                <div>
                    <div>
                        <span> Numéro de la commande: </span>
                        <span style={{ fontWeight: "bold", maginLeft: "20px" }}> {commande?.id} </span>
                    </div>
                    <div className='mt-2'>
                        <span> Référence : </span>
                        <span style={{ fontWeight: "bold", marginLeft: "20px", color: "#850014", textDecoration: "underline" }}> {commande?.libelle} </span>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center" }}>
                    <span> Date commande </span>
                    <span style={{ color: "#07beb8", fontWeight: "bold" }}> {moment(commande?.createdAt).format('DD MMM YYYY')} </span>
                    {commande && typeUser === PRESTATAIRE && <span className='mt-2'> Commandée par:</span>}
                    {commande && typeUser === DEMANDEUR && <span className='mt-2'> Prestataire :</span>}
                    {commande && typeUser === PRESTATAIRE && <div className="d-flex justify-content-center align-items-center"> <Avatar style={{ objectFit: "cover" }} src={commande?.demandeur?.userImage ? commande?.demandeur?.userImage?.url : BASE_PATH.concat("/img/noProfile.jpg")} /> <span style={{ marginLeft: 5 }}> {commande?.demandeur?.nom + " " + commande?.demandeur?.prenom} </span> </div>}
                    {commande && typeUser === DEMANDEUR && <div className="d-flex justify-content-center align-items-center"> <Avatar style={{ objectFit: "cover" }} src={commande?.prestataire?.userImage ? commande?.prestataire?.userImage?.url : BASE_PATH.concat("/img/noProfile.jpg")} /> <span style={{ marginLeft: 5 }}> {commande?.prestataire?.nom + " " + commande?.prestataire?.prenom} </span> </div>}
                </div>
            </div>
            <div className="row mt-4 ">
                <div className="col-md-10">
                    <div className="row mb-1">
                        <div className="col-md-4">
                            <span style={{ color: "#000", fontWeight: "bold" }}>Status de la commande: </span>
                            <span className='ms-2'>
                                {commande?.statusTravail === ATTENTE && <div style={{ display: 'inline-block', fontSize: 10, background: ORANGE, color: 'white', padding: '3px 10px ', border: '1px solid '.concat(ORANGE), fontWeight: 'bold' }} > En Attente </div>}
                                {commande?.statusTravail === COMMENCER && <div style={{ display: 'inline-block', fontSize: 10, background: BLUE, color: 'white', padding: '3px 10px ', border: '1px solid '.concat(BLUE), fontWeight: 'bold' }} > Commencée </div>}
                                {commande?.statusTravail === PAUSE && <div style={{ display: 'inline-block', fontSize: 10, background: RED, color: 'white', padding: '3px 10px ', border: '1px solid '.concat(RED), fontWeight: 'bold' }}> En Pause </div>}
                                {commande?.statusTravail === TERMINER && <div style={{ display: 'inline-block', fontSize: 10, background: GREEN_DARK, color: 'white', padding: '3px 10px ', border: '1px solid '.concat(GREEN_DARK), fontWeight: 'bold' }}> Livrée </div>}
                                {
                                    commande?.statusTravail === REFUSER && (
                                        <div style={{ display: 'inline-block', fontSize: 10, background: '#b21e35', color: 'white', padding: '3px 10px ', border: '1px solid '.concat('#b21e35'), fontWeight: 'bold' }} >Refusée</div>
                                    )
                                }
                            </span>
                        </div>
                        {commande && <div className="col-md my-2">
                            <div className="row align-items-center">
                                <div className="col-sm-7 border p-3 bg-white">

                                    <div>
                                        {
                                            typeUser === PRESTATAIRE && (
                                                <div>
                                                    {
                                                        commande.suggestionDelaisLivraisonClient && (
                                                            <div>
                                                                <span>Délais suggéré par le client : </span>
                                                                <span style={{ color: ORANGE, fontSize: "16px", marginLeft: '10px', fontWeight: 'bold' }}>{dayjs(commande.suggestionDelaisLivraisonClient).locale('fr').format('DD MMMM YYYY HH:mm:ss')}</span>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        commande.suggestionDelaisLivraisonPrestataire ? (
                                                            <div>
                                                                <span>Date que vous avez suggéré : </span>
                                                                <span style={{ color: BLACK, fontSize: "14px", marginLeft: '10px', fontWeight: 'bold' }}>
                                                                    {dayjs(commande.suggestionDelaisLivraisonPrestataire).locale('fr').format('DD MMMM YYYY HH:mm:ss')}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <span>Date minimum de livraison: </span>
                                                                <span style={{ fontWeight: 'bold' }}>
                                                                    {
                                                                        commande?.prestation?.delaisJoursLivraison && commande?.prestation?.delaisJoursLivraison > 0 ?
                                                                            dayjs(
                                                                                commande?.createdAt + (parseInt(commande?.prestation?.delaisJoursLivraison) * 24 * 60 * 60 * 1000)
                                                                            )
                                                                                .locale('fr')
                                                                                .format('DD MMMM YYYY HH:mm:ss')
                                                                            :
                                                                            dayjs(commande?.createdAt + (24 * 60 * 60 * 1000))
                                                                                .locale('fr')
                                                                                .format('DD MMMM YYYY HH:mm:ss')
                                                                    }
                                                                </span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }


                                        {
                                            typeUser === DEMANDEUR && (
                                                <div>
                                                    {
                                                        commande.suggestionDelaisLivraisonPrestataire ? (
                                                            <div>
                                                                <span>Délais suggéré par le prestataire : </span>
                                                                <span style={{ color: ORANGE, fontSize: "16px", marginLeft: '10px', fontWeight: 'bold' }}>{dayjs(commande.suggestionDelaisLivraisonPrestataire).locale('fr').format('DD MMMM YYYY HH:mm:ss')}</span>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <span>Date minimum de livraison: </span>
                                                                <span style={{ color: ORANGE, fontSize: "16px", marginLeft: '10px', fontWeight: 'bold' }}>
                                                                    {
                                                                        commande?.prestation?.delaisJoursLivraison && commande?.prestation?.delaisJoursLivraison > 0 ?
                                                                            dayjs(
                                                                                commande?.createdAt + (parseInt(commande?.prestation?.delaisJoursLivraison) * 24 * 60 * 60 * 1000)
                                                                            )
                                                                                .locale('fr')
                                                                                .format('DD MMMM YYYY HH:mm:ss')
                                                                            :
                                                                            dayjs(
                                                                                commande?.createdAt + (24 * 60 * 60 * 1000)
                                                                            )
                                                                                .locale('fr')
                                                                                .format('DD MMMM YYYY HH:mm:ss')
                                                                    }
                                                                </span>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        commande.suggestionDelaisLivraisonClient && (
                                                            <div>
                                                                <span>Date que vous avez suggéré : </span>
                                                                <span style={{ color: BLACK, fontSize: "14px", marginLeft: '10px', fontWeight: 'bold' }}>
                                                                    {
                                                                        dayjs(commande.suggestionDelaisLivraisonClient)
                                                                            .locale('fr')
                                                                            .format('DD MMMM YYYY HH:mm:ss')
                                                                    }
                                                                </span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>

                                {
                                    commande && commande.acc
                                }

                            </div>
                        </div>}
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <Image alt="commande" style={{ width: "100%", objectFit: "cover" }} src={commande?.prestation?.image?.url} />
                        </div>
                        <div className="col-lg-7 ">
                            {
                                DisplayInfoMenuContent()
                            }
                            {
                                commande && commande.acceptationPrestataire === REFUSER && commande.raisonPrestataire?.length > 0 && (
                                    <>
                                        <div style={{ marginTop: '30px', marginBottom: '10px', fontWeight: 'bold', color: RED, textDecoration: 'underline', fontSize: '16px' }}>Raison de refus</div>
                                        <div className='mb-2 p-4' style={{ border: '1px solid '.concat(RED), fontWeight: 'bold', background: RED.concat(10) }}>
                                            {commande.raisonPrestataire}
                                        </div>
                                    </>
                                )
                            }
                        </div>
                        <div className='col-lg-2'>
                            <div
                                className='rounded'
                                style={{
                                    background: ORANGE.concat(10),
                                    padding: "10px",
                                    borderBottom: "1px solid #ccc",
                                    borderLeft: "1px solid #ccc",
                                    borderRight: "1px solid #ccc",
                                    borderTop: "2px solid ".concat(ORANGE),
                                    // height: "100%",
                                    textAlign: "center"
                                }}>
                                <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                                    Prix Total
                                </div>
                                <div className="text-center mt-3" style={{ fontWeight: "bold", fontSize: "16px", color: ORANGE }}>
                                    {"".concat(commande?.quantiteDemander * commande?.prestation?.cout).concat(' ').concat(commande?.prestation?.unite?.toLowerCase())}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                    {ListActionsButton()}
                </div>
            </div>
        </div>
    )

    const DisplayDelaisModal = () => (
        <div className="modal fade" id="delaisModal" aria-hidden="true" data-backdrop="static" >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header p-2">
                        <div className="modal-title fw-bold" >Délais de suggestion</div>
                        <button type="button" className="close" id="suggestion-popup-close-btn" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="font-weight-bold" style={{ fontSize: "18px" }}>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div>Délais de livraison</div>
                        <EuiDatePicker
                            fullWidth
                            showTimeSelect
                            selected={delaisLivraison}
                            onChange={date => setDelaisLivraison(date)}
                            placeholder="Délais de livraison"
                            dateFormat="YYYY-MM-DD HH:mm:ss"
                            filterDate={date => compareTwoDate(date, commande?.prestation?.delaisJoursLivraison ? commande?.prestation?.delaisJoursLivraison : 1)}
                        />
                    </div>
                    <div className='modal-footer'>
                        <div className='text-right'>
                            <button style={{ background: ORANGE, border: 'none', padding: '5px 10px', fontWeight: 'bold', color: WHITE }} onClick={handleSendDelaisSuggestion}>
                                {
                                    loadingSuggestion && (
                                        <span className='my-spinner spinner-white'></span>
                                    )
                                }
                                <span>Suggérer</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    const TitleCommande = () => (
        <div>
            <div className='page-title'>Détails commandes</div>
            <div className="mt-2"> Tous les informations concernant une commande, ainsi que les différentes actions à effectuer dessus </div>
        </div>
    )

    useEffect(() => {
        id && loadCommande()
    }, [id, typeUser])

    return loadingCommande ? <Spin tip="Chargement..." /> : commande && userId && typeUser && ((typeUser === PRESTATAIRE && userId === commande.prestataire.id) || (typeUser === 'DEMANDEUR' && userId === commande.demandeur.id)) ? <div className="detail-commande p-3">
        {TitleCommande()}
        {DisplayContentDetail()}
        {DisplayRaisonModal()}
        {DisplayNoteAndFeedbacksModal()}
        {DevisModalContent()}
        {DisplayDelaisModal()}
    </div> :
        <PageNotFound />

}

export default AdminDetailCommande