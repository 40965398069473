import { Image, Popconfirm } from 'antd'
import React, { useEffect, useState } from 'react'
import tokenStore from '../stores/tokenStore'
import { NotificationManager } from 'react-notifications'
import jwt from 'jsonwebtoken'
import { BASE_PATH, COMMANDES_ROUTE } from '../utils/api.route'
import { useHistory } from 'react-router-dom'
import { GiCancel } from 'react-icons/gi'
import { FaRegEdit } from 'react-icons/fa'
import { EuiComboBox, EuiInMemoryTable } from '@elastic/eui'
import { ATTENTE, ACCEPTER, DEMANDEUR, NOUVELLE, PRESTATAIRE, REFUSER, TERMINER, PAUSE, DEMARRER } from '../utils/constants'
import moment from 'moment'
import { BLUE } from '../theme/couleurs'


const AdminCommande = ({ typeUser, title = 'Commandes recus' }) => {
    const token = tokenStore(state => state.token)
    const decodedToken = jwt.decode(token)
    const userId = decodedToken?.id
    const [commandes, setCommandes] = useState({ loading: false, datas: [] })
    const [items, setItems] = useState({ loading: false, datas: [] })
    const [typeCommande, setTypeCommande] = useState([{ value: "*", label: "Toutes" }])
    const history = useHistory()

    let debounceTimeoutId
    let requestTimeoutId

    const loadCommandes = () => {
        setCommandes({ ...commandes, loading: true })
        setItems({ ...commandes, loading: true })
        fetch(
            COMMANDES_ROUTE
                .concat('?typeUser=')
                .concat(typeUser)
                .concat('&id=')
                .concat(userId)
                .concat("&typeCommande=")
                .concat(typeCommande?.[0]?.value)
                .concat('&sort=id,DESC'),
            {
                method: "get",
                headers: {
                    'Content-type': 'application/json',
                    Authorization: "Bearer " + token
                },
            })
            .then(response => response.json())
            .then((result) => {
                if (result.error)
                    throw new Error(result.message ? result.message : result.error)
                setCommandes({ ...commandes, loading: false, datas: result.content })
                setItems({ ...commandes, loading: false, datas: result.content })
            })
            .catch((err) => {
                setCommandes({ ...commandes, loading: false })
                setItems({ ...commandes, loading: false })
                NotificationManager.error(err.message)
            })
    }

    const handleAnnulerCommande = ({ id }) => {
        fetch(COMMANDES_ROUTE.concat('?id=').concat(id), {
            method: 'delete',
            headers: {
                'content-type': 'application/json',
                Authorization: 'Bearer ' + token,
            }
        })
            .then(response => response.json())
            .then(result => {
                if (result.error)
                    throw new Error(result.message ? result.message : result.error)
                NotificationManager.success("Commande annuler...")
                loadCommandes()
            })
            .catch(err => {
                NotificationManager.error(err.message)
            })
    }
    let getColumns = () => {

        let columns = [
            {
                field: "prestation",
                name: "Libellé",
                width: '300px',
                render: prestation => (
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                        {
                            prestation.images?.length > 0 && (
                                <div style={{ marginRight: '10px' }}>
                                    <Image src={prestation?.images[0]?.url} style={{ minWidth: "100px", maxWidth: "100px", height: "50px", objectFit: 'cover' }} />
                                </div>
                            )
                        }
                        <div>
                            {prestation?.titre}
                        </div>
                    </div>
                )
            }
        ]
        if (typeUser === PRESTATAIRE) {
            columns.push(
                {
                    name: 'Client',
                    field: 'client',
                    render: (value) => <div className="d-flex align-items-center">
                        <img src={value.userImage ? value.userImage.url : BASE_PATH.concat("/img/noProfile.jpg")} alt="profil prestation " className="rounded-circle me-2" style={{ objectFit: "cover", width: "30px", height: "30px" }} />
                        <span style={{ color: "#00000090", }}>{value.nom ? value.nom : value.username} </span>
                        <span className="mx-1" style={{ color: "#00000090" }}>{value.prenom && value.prenom} </span>
                    </div>
                }
            )
        }

        if (typeUser === DEMANDEUR) {
            columns.push(
                {
                    name: 'Prestataire',
                    field: 'prestataire',
                    render: (value) => <div className="d-flex align-items-center">
                        <img src={value.userImage ? value.userImage.url : BASE_PATH.concat("/img/noProfile.jpg")} alt="profil prestation " className="rounded-circle me-2" style={{ objectFit: "cover", width: "30px", height: "30px" }} />
                        <span style={{ color: "#00000090" }}>{value.nom ? value.nom : value.username} </span>
                        <span className="mx-1" style={{ color: "#00000090" }}>{value.prenom && value.prenom} </span>
                    </div>
                }
            )
        }


        columns.push(
            {
                name: 'Prix Total',
                field: 'prix',
                render: (value) => <span>
                    <div style={{ fontSize: 10, background: '#007f5f', color: 'white', padding: '3px 10px ', border: '1px solid '.concat('#007f5f'), fontWeight: 'bold' }}>
                        {value.cout}  {value.unite}
                    </div>
                </span>
            },
            {
                name: 'Quantité commandée',
                field: 'quantite',
            }
        )

        columns.push(
            {
                name: typeUser === "DEMANDEUR" ? 'Acceptation du Prestataire' : "Mon acceptation",
                field: 'acception',
                render: (value) => <div>
                    {value === ACCEPTER && <div style={{ fontSize: 10, background: '#007f5f', color: 'white', padding: '3px 10px ', border: '1px solid '.concat('#007f5f'), fontWeight: 'bold' }} >Acceptée</div>}
                    {value === REFUSER && <div style={{ fontSize: 10, background: '#b21e35', color: 'white', padding: '3px 10px ', border: '1px solid '.concat('#b21e35'), fontWeight: 'bold' }} >Refusée</div>}
                    {value === ATTENTE && <div style={{ fontSize: 10, background: '#b88b4a', color: 'white', padding: '3px 10px ', border: '1px solid '.concat('#b88b4a'), fontWeight: 'bold' }} > En Attente</div>}
                </div>
            },

            {
                name: 'Statut de la commande',
                field: 'statusTravail',
                render: (value) => <div>
                    {value === TERMINER && <div style={{ fontSize: 10, background: '#007f5f', color: 'white', padding: '3px 10px ', border: '1px solid '.concat('##007f5f'), fontWeight: 'bold' }}  >Livrée</div>}
                    {value === DEMARRER && <div style={{ fontSize: 10, background: '#0077b6', color: 'white', padding: '3px 10px ', border: '1px solid '.concat('#0077b6'), fontWeight: 'bold' }} >Démarrée</div>}
                    {value === ATTENTE && <div style={{ fontSize: 10, background: '#b88b4a', color: 'white', padding: '3px 10px ', border: '1px solid '.concat('#b88b4a'), fontWeight: 'bold' }} > En Attente</div>}
                    {value === PAUSE && <div style={{ fontSize: 10, background: '#bc6c25', color: 'white', padding: '3px 10px ', border: '1px solid '.concat('#bc6c25'), fontWeight: 'bold' }} > En Pause</div>}
                    {value === REFUSER && <div style={{ fontSize: 10, background: '#b21e35', color: 'white', padding: '3px 10px ', border: '1px solid '.concat('#b21e35'), fontWeight: 'bold' }} > Refusée</div>}
                </div>
            },
            {
                name: 'Date',
                field: 'date',
                render: (value) => <p style={{ color: "#00000080", fontWeight: "bold" }}>{moment(value).format('DD MMM YYYY')} </p>
            },
            {
                name: 'Actions',
                field: 'action',
                render: (value) => <div className="d-flex">
                    <FaRegEdit onClick={() => history.push('/dashboard/commandes/details/'.concat(value.id).concat('/').concat(typeUser))} style={{ fontSize: 20, color: BLUE, cursor: "pointer" }} />
                    {!value.acceptationPrestataire && typeUser === DEMANDEUR && <Popconfirm title="Etes-vous sûr de vouloir annulé cette commande ? ">
                        <GiCancel style={{ marginLeft: 5, fontSize: 20, color: "#e63946", cursor: "pointer" }} onClick={() => handleAnnulerCommande({ id: value.id })} />
                    </Popconfirm>}
                </div>
            }
        )

        return columns
    }

    const getTypeCommandeOptions = () => {
        let optionsList = [
            { label: "Toutes", value: "*" },
            { label: "Nouvelles commandes", value: NOUVELLE },
            { label: "Commandes Acceptées ", value: ACCEPTER },
            { label: "Commandes Refusées ", value: REFUSER },
            { label: "Commandes Terminées", value: TERMINER }
        ]
        return optionsList
    }


    const SectionFilter = () => (
        <div className="d-flex justify-content-between align-items-center mr-5">
            <div>
                <EuiComboBox
                    style={{ width: "300px" }}
                    placeholder="Commande"
                    options={getTypeCommandeOptions()}
                    selectedOptions={typeCommande}
                    onChange={value => setTypeCommande(value.length > 0 ? value : [{ label: 'Toutes', value: '*' }])}
                    singleSelection={{ asPlainText: true }}
                />
            </div>
            <div style={{ fontSize: "14px", marginLeft: "10px", textDecoration: 'underline' }}>Total: <span style={{ color: "#000000", fontWeight: "bold" }}> {commandes.datas.length} </span>commandes</div>
        </div>
    )


    const onQueryChange = ({ query }) => {
        clearTimeout(debounceTimeoutId)
        clearTimeout(requestTimeoutId)

        debounceTimeoutId = setTimeout(() => {
            setItems({ ...items, loading: true })

            requestTimeoutId = setTimeout(() => {
                let itemsResult = []

                itemsResult = commandes.datas
                    .map((item, index) => ({
                        key: index,
                        id: item.id,
                        libelle: item.prestation.titre,
                        client: item.demandeur,
                        prestataire: item.prestataire,
                        quantite: item.quantiteDemander,
                        acception: item.acceptationPrestataire,
                        confirmationClient: item.acceptationDemandeur,
                        prix: { ...item.prestation, cout: (item.prestation?.cout * item.quantiteDemander) },
                        date: item.createdAt,
                        action: item,
                        statusTravail: item.statusTravail,
                    }))
                    .filter(cmd => {
                        const storeDict = `${cmd.statusTravail} ${cmd.id} ${cmd.libelle} ${cmd.prestataire?.nom} ${cmd.client?.nom} ${cmd.prestataire?.username} ${cmd.client?.username} ${cmd.acception} ${cmd.confirmationClient} ${cmd.date} ${cmd.quantite}`.toLowerCase()
                        const normalizedQuery = query.text.toLowerCase()
                        return storeDict.indexOf(normalizedQuery) !== -1
                    })

                setItems({ ...items, loading: false, datas: itemsResult })
            }, 1000)
        }, 300)
    }




    const search = {
        onchange: onQueryChange,
        toolsRight: SectionFilter(),
        box: {
            incremental: true,
        },
    };

    const getDatas = () => {
        return items.datas.map((item, index) => ({
            key: index,
            id: item.id,
            prestation: item.prestation,
            libelle: item.prestation.titre,
            client: item.demandeur,
            prestataire: item.prestataire,
            quantite: item.quantiteDemander,
            acception: item.acceptationPrestataire,
            confirmationClient: item.acceptationDemandeur,
            prix: { ...item.prestation, cout: (item.prestation?.cout * item.quantiteDemander) },
            date: item.createdAt,
            action: item,
            statusTravail: item.statusTravail
        })) || []
    }

    const TableCommandes = () => (
        <div className='rounded '>
            <EuiInMemoryTable
                tableCaption="Liste de commande"
                items={getDatas()}
                loading={commandes?.loading}
                columns={getColumns()}
                search={search}
                pagination
                sorting={true}
            // style={{ overflowX: 'auto', width: '500px' }}
            />
        </div>
    )


    useEffect(() => {
        loadCommandes()
    }, [typeCommande])


    return <div >
        <div className="my-1 p-2 page-title">{title} </div>
        <div className='mt-4'>
            {TableCommandes()}
        </div>
    </div>
}


export default AdminCommande