// export const RED = "#fe696a"
export const RED = "#b21e35"
export const ORANGE = "#f3722c"
export const GREEN = "#3cb9c3"
export const GREEN_DARK = "#007f5f"
export const GREEN_LIGHT = "#f3fbff"
export const ORANGE_LIGNT = "#ffe7d950"
export const WHITE = "#fff"
export const BLACK = "#000"
export const BLUE = "#3a86ff"
export const JAUNE = "#ffd000"
export const LIGHT = "#f6f9fc"
export const DARK_GRAY = "#1a535c"
export const GRAY = "#54545E"

export const PRIMARY = "#0e6ba8"
export const DEFAULT = "#1a535c"