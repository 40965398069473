import create from "zustand";
const store = (set) => ({
  filtres: [],
  addFiltres: filtres =>
    set({ filtres }),
});

const filtresStore = create(store);

export default filtresStore;
