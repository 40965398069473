import ConstantConfig, { GetServerURL } from "./constants.config"

export const BASE_PATH = ConstantConfig()?.BASE_PATH
export const LISTE_SERVICES = BASE_PATH.concat('/api/prestations')
export const LISTE_USERS = BASE_PATH.concat('/api/users')
export const IMAGES_ROUTE = BASE_PATH.concat('/api/images')
export const COMMANDES_ROUTE = BASE_PATH.concat('/api/commandes')
export const MESSAGES_ROUTE = BASE_PATH.concat('/api/messages')
export const PROFILS_ROUTE = BASE_PATH.concat('/api/profils')
export const QUESTIONS_ROUTE = BASE_PATH.concat('/api/questions')
export const DOMAINES_ROUTE = BASE_PATH.concat('/api/categories')
export const REALISATIONS_ROUTE = BASE_PATH.concat('/api/realisations')
export const LISTE_INVITE_CATEGORIES = BASE_PATH.concat('/api/invite/categories')
export const LISTE_INVITE_USERS = BASE_PATH.concat('/api/invite/users')
export const LISTE_INVITE_UNITES = BASE_PATH.concat('/api/invite/unites')
export const RESEND_CODE_ROUTE = BASE_PATH.concat('/api/invite/resendcode')
export const INVITE_SEND_CONTACT_MESSAGE = BASE_PATH.concat('/api/invite/contactUs')
export const LISTE_INVITE_SERVICES = BASE_PATH.concat('/api/invite/prestations')
export const LISTE_INVITE_REALISATIONS = BASE_PATH.concat('/api/invite/realisations')
export const SIGNIN_ROUTE = BASE_PATH.concat('/api/signin')
export const SIGNUP_ROUTE = BASE_PATH.concat('/api/signup')
export const VALIDATE_EMAIL_USER = BASE_PATH.concat('/api/validateEmail/users')
export const ME_ROUTE = BASE_PATH.concat('/api/users/me')
export const DEMANDE_ROUTE = BASE_PATH.concat('/api/demandes')
export const INVITE_DEMANDE_ROUTE = BASE_PATH.concat('/api/invite/demandes')
export const FAVORIS_ROUTE = BASE_PATH.concat('/api/favoris')
export const PUBS_INVITE_ROUTE = BASE_PATH.concat('/api/invite/pubs')
export const PUBS_ROUTE = BASE_PATH.concat('/api/pubs')
export const NOTIFICATION_ROUTE = BASE_PATH.concat("/api/notifications")
export const INVITE_TAGS_ROUTE = BASE_PATH.concat("/api/invite/tags")
export const FEEDBACKS_ROUTE = BASE_PATH.concat('/api/feedbacks')
export const INIVITE_FEEDBACKS_ROUTE = BASE_PATH.concat('/api/invite/feedbacks')
export const ABONNER_ROUTER = BASE_PATH.concat('/api/abonner')
export const DESABONNER_ROUTER = BASE_PATH.concat('/api/desabonner')


export const SOCKET_ROUTE = GetServerURL()?.SOCKET_URL?.concat(BASE_PATH).concat('/api/socket')