import React, { useState, useEffect } from "react"
import { NotificationManager } from "react-notifications"
import {
    DeleteOutlined,
} from "@ant-design/icons"
import { Popconfirm, Spin, Table, Input, Select, Form } from "antd"
import tokenStore from "../stores/tokenStore"
import { DOMAINES_ROUTE } from '../utils/api.route'
import { FiEdit } from 'react-icons/fi'
import { BLUE, RED } from "../theme/couleurs"

const AdminDomaine = () => {
    const [domaines, setDomaines] = useState([])
    const token = tokenStore(state => state.token)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [currentCategorie, setCurrentCategorie] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [deleteErrorMessage, setDeleteErrorMessage] = useState(null)

    const loadDomaines = () => {
        setLoading(true)
        fetch(DOMAINES_ROUTE, {
            method: "get",
            headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then(response => response.json())
            .then((datas) => {
                if (datas.error)
                    throw new Error(datas.message ? datas.message : datas.error)

                // setTotalElements(datas.totalElements)
                setDomaines(datas)
                return setLoading(false)
            })
            .catch((err) => {
                NotificationManager.error(err.message, null, 3000)
                setLoading(false)
            })

    }

    const deleteDomaine = _ => {
        setDeleteErrorMessage(null)
        if (currentCategorie) {
            fetch(DOMAINES_ROUTE.concat("/").concat(currentCategorie.id), { method: "delete", headers: { Authorization: "Bearer " + token } })
                .then(response => response.json())
                .then(response => {
                    if (response.error) throw new Error(response.message ? response.message : "Erreur de suppression !")
                    NotificationManager.success("Suppression réuissit !", null , 8000)
                    loadDomaines()
                    document.getElementById("btn-close-delete-popup")?.click()
                })
                .catch(err => {
                    setDeleteErrorMessage(err.message)
                    NotificationManager.error(err.message, null, 8000)
                })
        }
    }

    const columns = [{
        key: "id",
        title: "Id",
        width: 50,
        dataIndex: "id",
    },
    {
        key: "nom",
        title: "nom",
        dataIndex: "nom"
    },

    {
        key: "actions",
        title: "Actions",
        width: "80px",
        render: value => <div className="d-flex align-items-center">
            <a
                href="#"
                onClick={() => {
                    setCurrentCategorie(value)
                }}
                data-bs-target="#newCategorie"
                data-bs-toggle="modal"
                className="me-2">
                <FiEdit style={{ cursor: "pointer", fontSize: "15px", color: BLUE }} />
            </a>

            <a
                data-bs-target="#deleteCategorie"
                data-bs-toggle="modal"
                href="#"
                onClick={() => {
                    setCurrentCategorie(value)
                }}>
                <DeleteOutlined style={{ cursor: "pointer", fontSize: "15px", color: RED }} />
            </a>

        </div>

    },
    ]

    const datas = domaines.length > 0 && domaines.map(domaine => ({
        ...domaine,
        key: domaine.id,
        actions: domaine
    }))

    const resetState = () => {
        form.resetFields()
        setCurrentCategorie(null)
        setErrorMessage(null)
        setErrorMessage(null)
    }

    const handleFinish = (data) => {
        if (data) {
            setErrorMessage(null)
            if (!currentCategorie) {
                fetch(DOMAINES_ROUTE, {
                    method: 'post',
                    body: JSON.stringify({ ...data, parent: domaines.find(d => d.nom === data.parent)?.id }),
                    headers: {
                        "content-type": "application/json",
                        "Authorization": "Bearer ".concat(token)
                    }
                }).then(response => response.json())
                    .then(response => {
                        if (response.error)
                            throw new Error(response.message ? response.message : response.error)
                        loadDomaines()
                        document.getElementById('btn-close-add-categorie')?.click()
                    }).catch(err => {
                        setErrorMessage(err.message)

                    })
            } else {
                fetch(DOMAINES_ROUTE.concat('/').concat(currentCategorie.id), {
                    method: 'put',
                    body: JSON.stringify({ ...data, parent: domaines.find(d => d.nom === data.parent)?.id }),
                    headers: {
                        "content-type": "application/json",
                        "Authorization": "Bearer ".concat(token)
                    }
                }).then(response => response.json())
                    .then(response => {
                        if (response.error)
                            throw new Error(response.message ? response.message : response.error)
                        loadDomaines()
                        document.getElementById('btn-close-add-categorie')?.click()
                        resetState()
                    }).catch(err => {
                        setErrorMessage(err.message)

                    })
            }
        }
    }

    const DisplayDeleteDomainePopup = <div className="modal fade"
        id="deleteCategorie"
        data-bs-backdrop="static" >
        <div className="modal-dialog" >
            <div className="modal-content" >
                <div className="modal-header py-1" >
                    <h4 className="text-danger fs-5" > Suppression </h4>
                    <button type="button"
                        onClick={resetState}
                        className="btn-close border rounded-circle bg-danger text-white"
                        data-bs-dismiss="modal"
                        aria-label="Close" > </button>
                </div >
                {
                    currentCategorie && <div className="modal-body" >
                        {deleteErrorMessage && <p className="alert alert-danger">{deleteErrorMessage} </p>}
                        <div >
                            <p >Cette Action va supprimer le Domaine : <span className="text-danger fw-bold">  {currentCategorie.nom} </span></p>
                            <p className="alert alert-danger">
                                Noter bien que, tous les prestations et leurs variations, les commandes , les notifications,les images, liées à cette catégories seront aussi supprimer !
                            </p>
                            <p className="mt-2"> Etes-vous vraiment sûr de vouloir le supprimer ? </p>
                        </div>
                    </div>
                }
                <div className="modal-footer">
                    <button type="button" className="btn btn-sm btn-success" data-bs-dismiss="modal" id="btn-close-delete-popup" onClick={resetState} > Fermer </button>
                    <button type="button" className="btn btn-sm btn-danger" onClick={deleteDomaine}  > Supprimer </button>
                </div >
            </div> </div>
    </div>

    const initDatas = () => {
        if (currentCategorie) {
            form.setFields([
                { name: "nom", value: currentCategorie.nom },
                { name: "parent", value: currentCategorie.parentId && domaines.find(d => d.id === currentCategorie.parentId)?.nom }
            ])
        }

    }

    useEffect(() => {
        loadDomaines()
    }, [])

    useEffect(() => {
        if (currentCategorie) {
            initDatas()
        }
    }, [currentCategorie])

    return <div style={{ marginTop: 10 }} >

        {DisplayDeleteDomainePopup}

        <Form layout="vertical" form={form} onFinish={handleFinish}>
            <div className="modal fade"
                id="newCategorie"
                data-bs-backdrop="static" >
                <div className="modal-dialog" >
                    <div className="modal-content" >
                        <div className="modal-header" >
                            <h4 > {currentCategorie ? "Mise à jour " : "Nouveau Catégorie"} </h4>
                            <button type="button"
                                onClick={resetState}
                                className="btn-close btn-sm"
                                data-bs-dismiss="modal"
                                aria-label="Close" > </button>
                        </div >
                        <div className="modal-body" >
                            {errorMessage && <p className="alert alert-danger">{errorMessage} </p>}
                            <div >
                                <Form.Item
                                    name="nom"
                                    label="Nom de la catégorie"
                                    rules={[{ message: "Ce champ est obligatoire" }]}
                                >
                                    <Input placeholder="Entrez le nom de la catégorie" />
                                </Form.Item> </div > <div >
                                <Form.Item
                                    name="parent"
                                    label="Choisir le parent ( Optionnel )" >
                                    <Select dropdownStyle={{ zIndex: 1090 }}
                                        placeholder="Choisir la catégorie parent" > {
                                            domaines
                                                .filter(d => d.parentId === null)
                                                .map(d => <Select.Option value={d.nom} key={d.id} > {d.nom} </Select.Option>)
                                        } </Select>
                                </Form.Item >
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-default" data-bs-dismiss="modal" id="btn-close-add-categorie" onClick={resetState} > Annuler </button>
                            <button className="btn btn-sm btn-primary" type="submit" > {currentCategorie ? "Mise à jour " : "Enrégistrer"} </button>
                        </div >
                    </div> </div>
            </div>
        </Form >
        <div className="border rounded  bg-white p-2" >
            <div className="d-flex align-items-center justify-content-between mb-1" >
                <h4 > Liste des Domaines </h4> <button className="btn btn-success btn-sm"
                    data-bs-target="#newCategorie"
                    onClick={resetState}
                    data-bs-toggle="modal" > Ajouter une nouvelle </button>
            </div > {
                loading ? < Spin tip="loading...." /> :
                    <div>
                        <Table
                            bordered
                            size="small"
                            dataSource={datas}
                            columns={columns}
                        /> </div >
            } </div> </div >
}

export default AdminDomaine