import { Button, Pagination, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import tokenStore from '../stores/tokenStore'
import jwt from 'jsonwebtoken'
import { LISTE_INVITE_USERS, LISTE_SERVICES } from '../utils/api.route'
import { Route, useHistory, Link } from 'react-router-dom'
import { GREEN, ORANGE } from '../theme/couleurs'
import HomeCustumCard from '../components/HomeCustumCard'
import { PRESTATION } from '../utils/constants'


const Services = () => {
    const token = tokenStore(state => state.token)
    const decodedToken = jwt.decode(token)
    const userId = decodedToken?.id
    const history = useHistory()

    const [prestations, createPrestations] = useState([])
    const [page, setPage] = useState(0)
    const [size, setSize] = useState(10)

    const [loadingPrestations, setLoadingPrestations] = useState(false)

    // const loadPrestation = () => {
    //     setLoadingPrestations(true)
    //     fetch(LISTE_SERVICES
    //         .concat('/users/')
    //         .concat(userId)
    //         .concat('?page=')
    //         .concat(page)
    //         .concat('&size=')
    //         .concat(size)
    //         .concat('&sort=id,DESC'),
    //         {
    //             method: 'get',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 Authorization: "Bearer " + token,
    //             },
    //         }
    //     )
    //         .then(result => result.json())
    //         .then((response) => {
    //             if (response.error)
    //                 NotificationManager.error(response.message ? response.message : response.error)
    //             createPrestations(response.content)
    //             setLoadingPrestations(false)
    //         })
    //         .catch((err) => {
    //             setLoadingPrestations(false)
    //             NotificationManager.error(
    //                 "Erreur de chargement des services !",
    //                 null,
    //                 4000
    //             )
    //         })
    // }


    const loadPrestation = async () => {
        try {

            if (userId) {
                setLoadingPrestations(true)
                const request = await fetch(
                    LISTE_INVITE_USERS
                        .concat("/")
                        .concat(userId)
                        .concat('?page=')
                        .concat(page)
                        .concat('&size=')
                        .concat(size)
                        .concat('&sort=id,DESC'),
                    {
                        method: "get",
                        headers: {
                            "content-type": "application/json",
                        },
                    }
                )

                const response = await request.json()
                if (response.error)
                    throw response

                createPrestations(response.prestations?.content)
                setLoadingPrestations(false)
            }

        } catch (err) {
            
            setLoadingPrestations(false)
            NotificationManager.error(
                "Erreur de chargement des services !",
                null,
                4000
            )
        }
    }


    useEffect(() => {
        loadPrestation()
    }, [])

    return loadingPrestations ?
        (
            <Spin tip="Chargement des prestations ! " />
        ) :
        prestations.length > 0 ?
            (
                <div className="my-4">
                    <div >
                        <div style={{ fontWeight: "bold", textDecoration: 'underline', color: ORANGE, fontSize: "16px" }} className="my-3"> Listes des services </div>
                        <div className='my-3'>Listes de tous vos services créés</div>
                    </div>
                    <div>
                        {prestations?.length === 0 ?
                            (
                                <div className="border rounded p-3 shadow bg-white">
                                    <h4>Services</h4>
                                    <p>Vous n'avez pas encore de service à offrir !</p>
                                    <Button onClick={() => history.push('/dashboard/add/service')} type="primary">Nouveau service</Button>
                                </div>
                            ) :
                            (
                                <div className="row">

                                    {prestations?.map((prestation, index) => (
                                        <div key={index} className="col-xs-1 col-sm-6 col-md-4 col-lg-3 col-xxl-2 my-1">
                                            <HomeCustumCard
                                                hasFavoris={false}
                                                hasUserProfil={false}
                                                type={PRESTATION}
                                                service={prestation}
                                                url={"/dashboard/detail/service/"}
                                            />
                                        </div>
                                    )
                                    )}
                                </div>
                            )
                        }

                        {prestations?.length !== 0 && 0 > 1 && <div
                            style={{
                                margintTop: 5,
                                display: "flex",
                                justifyContent: "end",
                                width: "100%",
                            }}
                        >
                            <Pagination color="primary" onChange={(value) => setPage(value)} count={3} size="small" />
                        </div>
                        }
                    </div>

                </div>
            )
            :
            (
                <div>Lites vide !</div>
            )
}


const AdminListes = () => {
    return <div>
        <Route path="/dashboard/list/services" component={Services} />
        {/* <Route path="/dashboard/list/realisations" component={Realisations} /> */}
    </div>
}
export default AdminListes