import { NotificationContainer } from "react-notifications"
import Routing from "./routes"
import ScrollToTop from "./components/ScrollToTop"

function App() {
  return (
    <>
      <ScrollToTop>
        <Routing />
        <NotificationContainer />
      </ScrollToTop>
    </>
  )
}

export default App