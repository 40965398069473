import React, { useState, useEffect } from "react"
import { Link, useHistory, Redirect } from "react-router-dom"

import { connectToSocket, isValidToken, refreshMe } from "../utils/utilsFunction"
import { Button, Form, Input, Space } from "antd"
import tokenStore from "../stores/tokenStore"
import { SIGNIN_ROUTE } from "../utils/api.route"
import meStore from "../stores/meStore"
import { BLUE, ORANGE, RED } from '../theme/couleurs'
import moment from 'moment'
import { EuiFieldPassword, EuiFieldText } from "@elastic/eui"
import socketStore from "../stores/socketStore"
import { APP_NAME } from "../utils/constants"


const Login = ({ style }) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const createMe = meStore(state => state.createMe)
  const [errorMessage, setErrorMessage] = useState(null)
  const token = tokenStore(state => state.token)
  const createToken = tokenStore(state => state.createToken)
  const createSocket = socketStore(state => state.createSocket)




  const onHandleLogin = (data) => {
    setLoading(true)
    setErrorMessage(null)
    createToken(null)
    fetch(SIGNIN_ROUTE, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error)
          throw new Error(response.message ? response.message : response.error)

        if (response.access_token) {
          createToken(response.access_token)
          refreshMe(createMe, response.access_token, createSocket)
          setErrorMessage(null)
          setLoading(false)
          history.goBack()
        }
      })
      .catch((err) => {
        setLoading(false)
        createToken(null)
        setErrorMessage(err.message)
      })

  }

  useEffect(() => {
    token && isValidToken(token) && history.goBack()
  }, [])

  return <div style={{ marginTop: 80 }}>
    <div
      style={{
        textAlign: "center",
      }}
      onClick={() => history.push("/")}
    >
      <Link to="/" >
        <span style={{ fontSize: 20, fontWeight: "bold", color: "#000000" }}>
          {APP_NAME}
        </span>
      </Link>
    </div>
    <div
      style={{
        maxWidth: 500,
        marginTop: 10,
        marginRight: "auto",
        background: "#fff",
        marginLeft: "auto",
        padding: "20px",
        ...style,
      }}

      className="border my-shadow rounded"
    >
      <div className="text-center font-weight-bold" style={{ color: ORANGE, fontSize: "18px" }} >
        Connexion
      </div>

      <p
        className="text-center mt-2"
      >
        Renseigner vos informations de connexion
      </p>

      {errorMessage && <p className="alert alert-danger"> {errorMessage} </p>}

      <Form
        style={{ marginTop: 20 }}
        layout="vertical"
        onFinish={onHandleLogin}
      >
        <Form.Item
          name="username"
          label={<span>Nom d'utilisateur </span>}
          rules={[{ required: true, message: "Le nom d'utilisateur est obligatoire" }]}
        >
          {EuiFieldText({ placeholder: "Entrez le nom d'utilisateur", fullWidth: true })}
        </Form.Item>
        <Form.Item
          name="password"
          label={<span>Votre mot de passe</span>}
          rules={[{ required: true, message: "Le mot de passe est obligatoire !" }]}
        >
          {EuiFieldPassword({ fullWidth: true, placeholder: "Entrez votre mot de passe", type: 'dual' })}
        </Form.Item>

        <div className="mb-3 mt-4">
          <Button
            style={{ width: "100%", color: "#fff", border: "none", background: ORANGE, fontWeight: "bold" }}
            htmlType="submit"
            loading={loading}
          >
            Connecté
          </Button>
        </div>
      </Form>
      <div className="my-2">
        <p> Vous n'avez pas encore de compte ? </p>
        <Link to="/register" className="fw-bold">Inscrivez-vous</Link>
      </div>
      <hr />
      <p style={{ textAlign: "center", color: "#00000080" }}>
        © {moment().format('YYYY')} Copyright HISPWCA
      </p>
    </div>



  </div>
}

export default Login
