import { ShoppingCartOutlined } from "@ant-design/icons"
import { Avatar, Badge, Button, Form, Image, Input, Modal, Rate, Space, Spin, Tabs } from "antd"
import Paragraph from "antd/lib/typography/Paragraph"
import React, { useEffect, useState } from "react"
import { NotificationManager } from "react-notifications"
import { useHistory, useParams } from "react-router"
import { Link, Route } from "react-router-dom"
import panierStore from "../stores/panierStore"
import tokenStore from "../stores/tokenStore"
import { backTop, compareTwoDate, isValidToken } from "../utils/utilsFunction"
import meStore from '../stores/meStore'
import { useForm } from "antd/lib/form/Form"
import { ABONNER_ROUTER, BASE_PATH, DESABONNER_ROUTER, FAVORIS_ROUTE, INIVITE_FEEDBACKS_ROUTE, LISTE_INVITE_SERVICES, LISTE_INVITE_USERS, MESSAGES_ROUTE, ME_ROUTE } from "../utils/api.route"
import ElementNotFound from "../components/ElementNotFound"
import pathStore from "../stores/pathStore"
import HomeCustumCard from "../components/HomeCustumCard"
import favorisStore from "../stores/favorisStore"
import { FaComments } from 'react-icons/fa'
import { AiOutlineFieldTime } from 'react-icons/ai'
import { BiMessageDots } from 'react-icons/bi'
import { BLUE, GRAY, GREEN, JAUNE, ORANGE, ORANGE_LIGNT, RED } from "../theme/couleurs"
import moment from "moment"
import { A_PROPOS, EVALUATION, EXPLORER, PRESTATION, TYPE_FEEDBACK_VENDEUR } from "../utils/constants"
import { AiFillPlusCircle } from 'react-icons/ai'
import { AiFillMinusCircle } from 'react-icons/ai'
import { BsStopCircle } from 'react-icons/bs'
import { MdStarRate } from 'react-icons/md'
import { EuiDatePicker, EuiFieldText, EuiLoadingSpinner, EuiTextArea } from "@elastic/eui"



const ContactPopupContent = ({ contactPopup, setContactPopup }) => (
  <Modal title="Contact" open={contactPopup} cancelText={false} onCancel={() => setContactPopup(false)}>
    Tel: 97-58-48-36
  </Modal>
)

const handleMeContacte = ({ setContactPopup, token, history, createPathStore }) => {
  createPathStore(history.location.pathname)
  if (token && isValidToken(token)) {
    return setContactPopup(true)
  }
  return history.push('/login')
}

const VariationCard = ({ variant, setCurrentDisplayImage, setCurrentVariant, currentVariant }) => (
  <div
    onClick={() => {
      setCurrentVariant(variant)
      setCurrentDisplayImage(variant && variant.images.length !== 0 ? variant.images[0].url : BASE_PATH.concat("/img/nothing.jpg"))
    }}

    style={{
      marginRight: 5, cursor: "pointer", width: "200px",
      marginTop: 10,
      border: currentVariant === variant ? `1px solid ${ORANGE}` : "1px solid #ccc",
    }}
    className="bg-white"
  >
    <Space size={5}>
      <img
        width={50}
        height={50}
        onClick={() => {
          setCurrentVariant(variant)
          setCurrentDisplayImage(variant && variant.images.length !== 0 ? variant.images[0].url : BASE_PATH.concat("/img/nothing.jpg"))
        }}
        src={variant && variant.images.length !== 0 ? variant.images[0].url : BASE_PATH.concat("/img/nothing.jpg")}
        alt="variationImage"
        style={{ objectFit: "cover" }}
      />
      <Paragraph ellipsis={{ rows: 2 }}>
        {variant?.titre}
      </Paragraph>
    </Space>
  </div>
)

const HomeService = () => {
  const [currentDisplayImage, setCurrentDisplayImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [service, setService] = useState(null);
  const token = tokenStore((state) => state.token);
  const addServices = panierStore(state => state.addServices)
  const panierServices = panierStore(state => state.services)
  const [contactPopup, setContactPopup] = useState(false)
  const { id } = useParams()
  const [serviceSimilaire, setServiceSimilaire] = useState([])
  const createPathStore = pathStore(state => state.addCurrentPath)
  const [variations, setVariations] = useState([])
  const me = meStore(state => state.me)
  const addFavoris = favorisStore(state => state.addFavoris)

  const [details, setDetails] = useState('')
  const [qty, setQty] = useState(1)
  const [delaisLivraison, setDelaisLivraison] = useState(null)


  const loadService = () => {
    setLoading(true);
    fetch(LISTE_INVITE_SERVICES.concat('/').concat(id), {
      method: "get",
      headers: {
        "content-type": "application/json",
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error)
          throw new Error(data.message ? data.message : data.error);
        setLoading(false)
        setService(data)
        setVariations(data.variations)
        setCurrentDisplayImage(data.images.length !== 0 ? data.images[0].url : BASE_PATH.concat("/img/nothing.jpg"))
        loadServiceSimilaire(data)
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(err.message);
      });
  }

  const loadServiceSimilaire = (service) => {
    const cptIds = service?.user?.competences?.map(cpt => cpt.id)

    fetch(
      LISTE_INVITE_SERVICES
        .concat('?competences=')
        .concat(
          cptIds.length > 0 ? cptIds.join() : "")
        .concat('&page=0&size=10&sort=id,DESC'),
      {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then(datas => {
        if (datas.error)
          throw new Error(datas.message ? datas.message : datas.error)
        const newArray = datas.content.length > 0 ? datas.content.map((s, index) => index < 5 && s) : []
        setServiceSimilaire(newArray)
      })
      .catch((err) => {
        
      })
  }

  const loadFavoris = () => {
    fetch(FAVORIS_ROUTE.concat("?userId=").concat(me.id), {
      method: "get",
      headers: {
        Authorization: "Bearer ".concat(token),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error)
          throw new Error(
            response.message
              ? response.message
              : "Erreur de récupération de la liste des favoris !"
          )
        addFavoris(response.favoris)
      })
      .catch((err) => {
        NotificationManager.error(err.message)
      })
  }

  const onCommande = () => {
    addServices([{ ...service, qty: qty, prestataire: service.prestataire }]);
    history.push("/panier")
  }

  const onAddToList = () => {
    const a = document.createElement('a')
    a.setAttribute('data-bs-target', '#panierOptionModal')
    a.setAttribute('data-bs-toggle', 'modal')
    a.style.display = 'none'
    document.body.appendChild(a)
    a.click()
    return a.remove()
  }

  const cleanState = () => {
    setDelaisLivraison(null)
    setDetails('')
    setQty(1)
  }

  const handleAdd = () => {
    if (!panierServices?.map(s => s.id).includes(service.id)) {
      let payload = {
        ...service,
        prestataire: service.prestataire
      }

      if (details && details.length > 0) {
        payload = { ...payload, detail: details }
      }

      if (qty && qty > 0) {
        payload = { ...payload, qty }
      }

      if (delaisLivraison) {
        payload = { ...payload, delaisLivraison }
      }

      addServices([...panierServices, payload])
      cleanState()
    }

    document.getElementById('btn-close')?.click()
  }

  const RenderPanierOptionModal = () => (
    <div>
      <div className="modal fade" id="panierOptionModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title fw-bold" style={{ fontSize: '16px' }} id="exampleModalLabel">Options</div>
              <button type="button" className="btn-close" id="btn-close" data-bs-dismiss="modal" aria-label="Fermer"></button>
            </div>
            <div className="modal-body">
              <div>
                <div>
                  Quantiter
                </div>
                <EuiFieldText
                  type='number'
                  placeholder="Ajouter une quantitée"
                  aria-label="Ajouter une quantitée"
                  fullWidth
                  value={qty}
                  onChange={e => setQty(e.target.value)}
                />
              </div>

              <div className="mt-2">
                <div>
                  Description
                </div>
                <EuiTextArea
                  placeholder="Ajouter une précision"
                  aria-label="Ajouter une précision"
                  fullWidth
                  value={details}
                  rows={2}
                  onChange={e => setDetails(e.target.value)}
                />
              </div>

              <div className="mt-2">
                <div>
                  Delais de livraison
                </div>
                <EuiDatePicker
                  fullWidth
                  showTimeSelect
                  selected={delaisLivraison}
                  onChange={date => setDelaisLivraison(date)}
                  placeholder="Délais de livraison"
                  filterDate={date => compareTwoDate(date, service.delaisJoursLivraison ? service.delaisJoursLivraison : 1)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
              <button type="button" onClick={handleAdd} className="btn" style={{ color: '#FFF', background: ORANGE }}>+ Ajouter</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const SectionPanier = () => (
    <div className="card mt-2"
      style={{
        borderLeft: "1px solid #ccc",
        borderRight: "1px solid #ccc",
        borderBottom: "1px solid #ccc",
        borderTop: "2px solid ".concat(ORANGE),
      }}
    >
      <div className="card-header bg-white d-flex justify-content-between">
        <div style={{ fontWeight: "bold" }}>Passer des commandes</div>
        <span><Badge count={panierServices.length} children={<ShoppingCartOutlined style={{ fontSize: "18px" }} />} /> </span>
      </div>
      <div className="card-body">
        <p>Vous pouvez passer directement la commande ou les ajoutés à votre panier !</p>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10px", flexWrap: 'wrap' }}>

          {service && service.variations.length > 0 && (
            <div style={{ background: ORANGE_LIGNT, fontWeight: 'bold', border: '1px solid '.concat(JAUNE), padding: '10px' }}>
              Veuillez sélectionner un service avant de l'ajouter au panier
            </div>
          )}

          {service && service.variations.length === 0 && (
            <div>
              <button style={{ background: ORANGE, color: "#ffffff", border: "none", padding: "5px 30px", fontWeight: "bold", marginTop: "5px", marginRight: "10px" }} onClick={onAddToList}>+ Ajouter au panier </button></div>
          )}
          {service && service.variations.length === 0 && panierServices.length < 2 && (
            <div><button className="mt-1" style={{ background: ORANGE, border: "none", color: "#ffffff", padding: "5px 30px", fontWeight: "bold", marginTop: "5px" }} onClick={onCommande}>Commander</button></div>
          )}

        </div>
        <div className="mt-4"><Link style={{ textDecoration: "underline", fontWeight: "bold" }} to="/panier">Aller dans mon panier</Link></div>
      </div>
    </div>
  )

  const DisplayUserProfil = () => (
    <>
      <div className="card bg-white mt-2"
        style={{
          borderLeft: "1px solid #ccc",
          borderRight: "1px solid #ccc",
          borderBottom: "1px solid #ccc",
          borderTop: "2px solid ".concat(ORANGE),
        }}
      >
        <div className="card-header bg-white">
          <div style={{ fontWeight: "bold" }}>Profil du prestataire</div>
        </div>
        <div className="card-body">
          <div
            onClick={() => history.push("/users/" + service && service.userId)}
            className="d-flex align-items-center"
          >
            <Avatar
              size="small"
              style={{ objectFit: "cover" }}
              src={
                service && service.user && service.user?.userImage ? service.user?.userImage?.url : BASE_PATH.concat("/img/noProfile.jpg")}
            />
            <div className="ml-2">
              <div className="d-flex">
                <div style={{ fontWeight: "bold" }}>
                  {service && service.user?.nom}
                </div>
                <div style={{ fontWeight: "bold" }} className="ml-2">{service && service.user?.prenom} </div>
              </div>
              <div className="text-muted">
                {service && service.user?.username}
              </div>
            </div>
          </div>
          <div className="mt-2"> 
            <Rate
              disabled
              value={service && service.user?.note}
            />
            <span className="ms-2" style={{ fontSize: "12px" }}> ({service && service.user?.notes?.length}) évaluations </span>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }} className="border rounded bg-light p-2 mt-2">
            <div className="mt-1">
              <span><AiFillPlusCircle style={{ fontSize: "20px", color: GREEN, marginRight: "2px" }} /> </span>
              <span>Note positives: </span>
              <span style={{ fontWeight: "bold", marginLeft: "5px" }}>{service && service.user?.evaluation?.moyennePositive}</span>
            </div>

            <div className="mt-1">
              <span><AiFillMinusCircle style={{ fontSize: "20px", color: RED, marginRight: "2px" }} /> </span>
              <span>Note négatives: </span>
              <span style={{ fontWeight: "bold", marginLeft: "5px" }}>{service.user?.evaluation?.moyenneNegative}</span>
            </div>

            <div className="mt-1">
              <span><BsStopCircle style={{ fontSize: "20px", color: GRAY, marginRight: "2px" }} /> </span>
              <span>Note neutres: </span>
              <span style={{ fontWeight: "bold", marginLeft: "5px" }}>{service.user?.evaluation?.moyenneNeutre}</span>
            </div>
          </div>
          <div className="mt-2">
            <Link
              to={`/detail/profil/${service.user?.id}`}
              style={{ textDecoration: "underline", fontWeight: "bold" }}
            >Visiter la boutique du prestataire </Link>
          </div>
          <div className="text-center mt-3">
            <Button
              style={{ padding: "0px 20px", border: "none", fontWeight: "bold", background: "#22222C", color: "#fff" }}
              onClick={() => {
                createPathStore(history.location.pathname)
                handleMeContacte({ setContactPopup, token, history, createPathStore })
              }
              }
            >
              Me contacter
            </Button>
          </div>
        </div>
      </div>
    </>

  )

  useEffect(() => {
    if (id) {
      loadService()
    }
  }, [id]);

  return service && (
    <>
      <div className="my-container my-4" >
        <div>
          <ContactPopupContent contactPopup={contactPopup} setContactPopup={setContactPopup} />
          {loading ?
            <Spin tip="Loading...." />
            :
            (
              <div className="row">
                <div className="col-md-12 col-xl-9 mb-2">
                  <div className="row bg-white border rounded py-4 px-2">
                    <div className="col-md-5">
                      <Image
                        style={{ objectFit: "cover", width: "100%" }}
                        src={currentDisplayImage}
                      />
                    </div>
                    <div className="col-md-7">
                      <div className="text-truncate-four" style={{ fontSize: "18px", fontWeight: "bold" }}>{service.titre} </div>
                      <div className="mt-2">
                        <div><span>Notes reçus:</span></div>
                        <Rate
                          disabled
                          value={service.note}
                        />
                        <span className="ms-4">({service.notes.length}) Evaluations</span>
                      </div>

                      {
                        service.cout && service.cout !== 0 && <div className="d-flex mt-4">
                          <div>Coût : </div>
                          <div className="d-flex ml-2 px-2">
                            <span style={{ color: GREEN, fontWeight: "bold", fontSize: "16px" }}>
                              {service.cout}
                            </span>
                            <span style={{ color: GREEN, fontWeight: "bold", fontSize: "16px" }} className="ml-2">{service?.unite}</span>
                          </div>
                        </div>
                      }

                      {
                        service.delaisJoursLivraison && (
                          <div className="my-2 p-3 mt-3" style={{ background: GREEN.concat(10), border: '1px solid '.concat(GREEN), display: 'inline-block', }}>
                            <span style={{ fontSize: '16px' }}>Délai minimumde livraison</span>
                            <span style={{ fontWeight: 'bold', fontSize: '16px' }}> {service.delaisJoursLivraison}</span>
                            <span style={{ marginLeft: '5px', fontSize: '16px', fontWeight: 'bold', textDecoration: 'underline' }}>Jours</span>
                          </div>
                        )
                      }

                      <div className="mt-3">
                        <div style={{ background: JAUNE.concat(10), border: '1px solid '.concat(JAUNE) }} className="rounded p-3">
                          <div>
                            <span className="fw-bold me-2" style={{ color: "#000", fontSize: "14px" }}>{service.nbrRealisations}</span>
                            <span> Réalisations faites </span>
                          </div>

                          <div className="mt-2">
                            <span className="fw-bold me-2" style={{ color: "#000", fontSize: "14px" }}>{service.nbrCommandesEncours}</span>
                            <span > Commandes en cours </span>
                          </div>
                        </div>
                      </div>


                      {
                        variations.length !== 0 &&
                        <div className="mt-2">
                          <div style={{ textDecoration: "underline" , marginTop:'20px', fontWeight:'bold', fontSize:'16px' , color: ORANGE }}> Variations de services</div>
                          <div className="row">
                            {variations?.map((value, index) => <div className="col-xs-12 col-sm-12 col-lg-6 col-xl-6" key={index}>
                              <VariationCard
                                key={index}
                                variant={value}
                                currentVariant={service}
                                setCurrentDisplayImage={setCurrentDisplayImage}
                                setCurrentVariant={setService}
                              />
                            </div>
                            )}
                          </div>
                        </div>
                      }
                    </div>

                    <div className="mt-5">
                      <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">
                          <span style={{ fontWeight: "bold" }}>Description</span>
                        </button>
                        <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">
                          <span style={{ fontWeight: "bold" }}>
                            Commentaires et Avis
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="tab-content" id="nav-tabContent">
                      <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        {
                          service && service.description ?
                            <p className="p-3 bg-light">{service.description}</p>
                            : <p> Pas de description !</p>
                        }
                      </div>
                      <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">.
                        <div>
                          {
                            service.evaluation?.commentaireCommandes?.length > 0 ?
                              <div>
                                {service
                                  .evaluation?.commentaireCommandes?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                                  .map(item => <div key={item.id} className="mt-2 border-bottom py-2">
                                    {item.sender && <div className="d-flex align-items-center">
                                      <Avatar src={item.sender?.userImage ? item.sender.userImage.url : BASE_PATH.concat("/img/noProfile.jpg")} />
                                      <div className="ms-4">
                                        <div className="fw-bold">{item.sender.username}</div>
                                        <div className="text-muted" style={{ fontSize: "12px" }}>{moment(item.createdAt).format("DD MMM YYYY HH:mm:ss")}</div>
                                      </div>
                                    </div>}
                                    <div className="mt-1">{item.commentaire}</div>
                                  </div>)}
                              </div>
                              :
                              <p className="alert alert-primary">Pas de commentaires</p>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xl-3" >
                  {SectionPanier()}
                  {DisplayUserProfil()}
                </div>
              </div>
            )
          }
        </div>

        <div style={{ marginTop: 50 }}>
          {serviceSimilaire.length !== 0 && (
            <>
              <div style={{ color: ORANGE, textDecoration: "underline", fontSize: '16px', fontWeight: "bold", margin: "10px 0px" }}>Autre services similaires </div>
              <div style={{ marginBottom: "10px" }}>Explorer également les services des autres prestataires</div>
              {loading ? (
                <Spin tip="loading...." />
              ) : (
                <div className="row">
                  {serviceSimilaire.map((service, index) => <div className="col-xs-1 col-sm-6 col-md-4 col-lg-3 col-xxl-2 my-1 " key={index}>
                    <HomeCustumCard
                      service={service}
                      hasFavoris={true}
                      hasUserProfil={true}
                      type={PRESTATION}
                      loadFavoris={loadFavoris}
                    />
                  </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>

      </div>

      {RenderPanierOptionModal()}
    </>
  )
}



const HomeProfil = () => {
  const [user, setUser] = useState(null)
  const token = tokenStore(state => state.token)
  const me = meStore(state => state.me)
  const createMe = meStore(state => state.createMe)
  const addFavoris = favorisStore(state => state.addFavoris)
  const [loading, setLoading] = useState(false)
  const [messagePopup, setMessagePopup] = useState(false)
  const [feedbacks, setFeedbacks] = useState([])
  const { id } = useParams()
  const histoiry = useHistory()

  const [loadingFeedbacks, setLoadingFeedbacks] = useState(false)
  const [loadingAbonner, setLoadingAbonner] = useState(false)




  const loadUser = () => {
    setLoading(true)
    fetch(
      LISTE_INVITE_USERS
        .concat("/")
        .concat(id),
      {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error)
          throw data

        setUser(data)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const loadMe = async () => {
    try {
      const request = await fetch(ME_ROUTE.concat('/').concat(user?.id), {
        method: "get",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      })

      const response = await request.json()
      if (response.error)
        throw response

      createMe(response)
    } catch (err) {
      NotificationManager.error(err.message)
    }
  }

  const handleSabonner = async () => {
   
    if (me?.id && user?.id) {
      try {
        setLoadingAbonner(true)

        const payload = {
          clientId: me?.id,
          vendeurId: user?.id
        }

        const request = await fetch(ABONNER_ROUTER, {
          method: 'post',
          body: JSON.stringify(payload),
          headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer '.concat(token)
          }
        })

        const response = await request.json()
        if (response.error)
          throw response

        await loadMe()
        setLoadingAbonner(false)
      } catch (err) {
        NotificationManager.error(err.message);
        setLoadingAbonner(false)
      }
    } else {
      histoiry.push('/login')
    }
  }

  const handleDeSabonner = async () => {
    if (me?.id && user?.id) {
      try {
        setLoadingAbonner(true)

        const payload = {
          clientId: me?.id,
          vendeurId: user?.id
        }

        const request = await fetch(DESABONNER_ROUTER, {
          method: 'post',
          body: JSON.stringify(payload),
          headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer '.concat(token)
          }
        })

        const response = await request.json()
        if (response.error)
          throw response

        await loadMe()
        setLoadingAbonner(false)
      } catch (err) {
        NotificationManager.error(err.message);
        setLoadingAbonner(false)
      }
    } else {
      histoiry.push('/login')
    }
  }


  const loadFavoris = () => {
    fetch(FAVORIS_ROUTE.concat("?userId=").concat(me.id), {
      method: 'get',
      headers: {
        'content-type': 'application/json',
        'Authorization': 'Bearer '.concat(token)
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.error)
          throw response
        addFavoris(response.favoris)
      })
      .catch(err => {
      })
  }

  const MessagePopup = () => {
    const [form] = useForm()
    const [messageLoading, setMessageLoading] = useState(false)

    const onFinish = (value) => {
      setMessageLoading(true)
      fetch(
        MESSAGES_ROUTE.concat('/envoyer/user/').concat(me?.id), {
        method: "post",
        body: JSON.stringify({ ...value, destinataireEmail: user?.email }),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + token
        },
      }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error)
            throw new Error(data.message ? data.message : data.error)

          NotificationManager.success("Méssage envoyé !")
          setMessageLoading(false)
          setMessagePopup(false)
        })
        .catch((err) => {
          setMessageLoading(false)
          NotificationManager.error(err.message)
        })
    }

    return <Modal
      open={messagePopup}
      footer={null}
      onCancel={() => setMessagePopup(false)}
      title="Méssage"
      bodyStyle={{ height: 150 }}
    >
      <Form form={form} onFinish={onFinish} >
        <Form.Item name="message" label="Méssage">
          <Input placeholder="votre message" />
        </Form.Item>
        <Form.Item >
          <Button loading={messageLoading} type="primary" htmlType="submit"> Envoyer </Button>
        </Form.Item>
      </Form>

    </Modal>
  }

  const loadFeedbacks = async () => {
    try {
      setLoadingFeedbacks(true)
      const request = await fetch(INIVITE_FEEDBACKS_ROUTE.concat("?userId=").concat(user.id).concat('&typeFeedback=').concat(TYPE_FEEDBACK_VENDEUR))
      const response = await request.json()
      if (response.error)
        throw response

      setFeedbacks(response.content)
      setLoadingFeedbacks(false)
    } catch (err) {
      
      setLoadingFeedbacks(false)
    }
  }

  const DisplayFeedbacks = () => (
    <>
      <div>
        {loadingFeedbacks && (
          <div><Spin tip="Chargement des commentaires" /></div>
        )}

        {!loadingFeedbacks && feedbacks.length > 0 && (
          <div className="border bg-white p-4">
            <div style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0px" }}>Toutes les évaluations <span> ( {feedbacks.length} )</span></div>
            {feedbacks.map(feedback => (
              <div key={feedback.id} className="py-2 border-bottom">
                <div>
                  {feedback.commentaireVendeur?.commentaire}
                </div>

                {feedback.commentaireVendeur?.sender && (
                  <div className="mt-1">
                    <span style={{ color: "#00000090" }}>posté par </span>
                    {feedback.commentaireVendeur?.sender?.image?.url && (
                      <span style={{ marginLeft: "10px" }}><Avatar style={{ objectFit: "cover" }} size="small" src={feedback.commentaireVendeur.sender.image.url} /></span>
                    )}
                    <span style={{ marginLeft: "10px", fontWeight: "bold" }}>{feedback.commentaireVendeur.sender.username}</span>
                  </div>
                )}

              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )

  const DisplayUserPrestation = () => user && user.prestations.content.length > 0 && (
    <div className="my-container">
      <div className="my-1"> Découvrez mes autres meilleurs prestations de services </div>
      <div className="row">
        {user.prestations.content.map((serv, index) => <div key={index} className="col-xs-1 col-sm-6 col-md-4 col-lg-3 col-xxl-2 my-1" >
          <HomeCustumCard
            hasFavoris={true}
            hasUserProfil={true}
            type={PRESTATION}
            service={serv}
            loadFavoris={loadFavoris}
          />
        </div>)}
      </div>
    </div>
  )

  const DisplayUserAbout = () => (
    <>
      <div className="mt-2">
        <div style={{ fontWeight: "bold", fontSize: "18px", }}>A propos de nous</div>
        <div className="mt-3">
          <div>
            {user?.precisionCompetence}
          </div>
          <div className="mt-3">
            {user?.username && (
              <div>
                <span style={{ fontWeight: "bold" }}>Nom d'utilisateur:</span>
                <span style={{ marginLeft: "20px" }}>{user?.username} </span>
              </div>
            )}
            {user?.nom && (
              <div>
                <span style={{ fontWeight: "bold" }}>Nom:</span>
                <span style={{ marginLeft: "20px" }}>{user?.nom} </span>
              </div>
            )}
            {user?.prenom && (
              <div>
                <span style={{ fontWeight: "bold" }}>Prénoms:</span>
                <span style={{ marginLeft: "20px" }}>{user?.prenom} </span>
              </div>
            )}

            {
              user?.showContacts && (
                <div className="my-4">
                  <div>
                    <span style={{ fontWeight: "bold" }}>Téléphones:</span>
                    <span style={{ marginLeft: "20px" }}>{user?.tels?.join(' / ')} </span>
                  </div>

                  <div>
                    <span style={{ fontWeight: "bold" }}>Email:</span>
                    <span style={{ marginLeft: "20px" }}>{user?.email} </span>
                  </div>
                </div>
              )
            }

            {user?.createdAt > 0 && (
              <div >
                <span style={{ fontWeight: "bold" }}>Membre depuis:</span>
                <span style={{ marginLeft: "20px" }}> {moment(user?.createdAt).format("DD MMM YYYY")} </span>
              </div>
            )}
          </div>
        </div>
      </div>
      {user?.showEntrepriseInformations && user?.isBusiness && (
        <div className="my-4 border p-3" style={{ maxWidth: "500px", backgroundColor: ORANGE_LIGNT }}>
          <div style={{ fontWeight: "bold", fontSize: "18px", }}>Information sur la societé</div>
          <div className="mt-3">
            {user?.nomEntreprise && (
              <div>
                <span style={{ fontWeight: "bold" }}>Nom:</span>
                <span style={{ marginLeft: "20px" }}>{user?.nomEntreprise}</span>
              </div>
            )}
            {user?.emailEntreprise && (
              <div>
                <span style={{ fontWeight: "bold" }}>Email:</span>
                <span style={{ marginLeft: "20px" }}>{user?.emailEntreprise} </span>
              </div>
            )}
            {user?.adresseEntreprise && (
              <div>
                <span style={{ fontWeight: "bold" }}>Adresse:</span>
                <span style={{ marginLeft: "20px" }}> {user?.adresseEntreprise} </span>
              </div>
            )}
            {user?.telEntreprise && (
              <div>
                <span style={{ fontWeight: "bold" }}>Téléphone:</span>
                <span style={{ marginLeft: "20px" }}>{user?.telEntreprise}</span>
              </div>
            )}

            {user?.tvaEntreprise && (
              <div>
                <span style={{ fontWeight: "bold" }}>TVA:  </span>
                <span style={{ marginLeft: "20px", fontWeight: "bold", color: ORANGE }}>{user?.tvaEntreprise}</span>
              </div>
            )}

            {user?.immatriculationEntreprise && (
              <div>
                <span style={{ fontWeight: "bold" }}>Immatriculation:  </span>
                <span style={{ marginLeft: "20px", fontWeight: "bold", color: ORANGE }}>{user?.immatriculationEntreprise}</span>
              </div>
            )}


          </div>
        </div>

      )}
    </>
  )



  const DisplayUserBannerImage = () => (
    <>
      <div style={{ height: "200px", width: "100%" }}>
        <img src={`${user.bannerImage?.url}`} style={{ objectFit: "cover", width: "100%", height: "100%" }} />
      </div>
    </>
  )

  const DisplayUserInfo = () => (
    <>
      <div style={{ background: "#EEEEEE", padding: "20px", border: "1px solid #ccc" }}>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <img style={{ height: "100px", objectFit: "cover" }} alt="profile utilisateur" src={user.userImage ? user.userImage.url : BASE_PATH.concat('/img/noProfile.jpg')} />
          </div>
          <div style={{ marginLeft: "30px", width: "100%" }}>
            <div style={{ fontSize: "25px", fontWeight: "bold" }}>{user.username}</div>
            <div className="row mt-2 align-items-center">
              <div className="col-md-7">
                <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <span><MdStarRate style={{ color: ORANGE, fontSize: "24px" }} /></span>

                    </div>
                    <span style={{ marginLeft: "5px", fontSize: "16px", fontWeight: "bold" }}>{user?.evaluation?.moyennePositive}</span>
                    <span style={{ marginLeft: "10px", fontSize: "16px" }}>Note positives</span>
                    <span style={{ marginLeft: "10px", fontSize: "10px" }}>( <span style={{ fontWeight: "bold" }}> {user.evaluation?.positiveEvaluations?.length || 0} </span> Evaluations )</span>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", marginLeft: "30px" }}>
                    <span style={{ fontWeight: "bold", marginRight: "10px", fontSize: "16px", marginRight: "10px" }}>{user.nombresAbonnes}</span>
                    <span style={{ fontSize: "16px" }}> Abonnées</span>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", marginLeft: "30px" }}>
                    <span style={{ fontWeight: "bold", marginRight: "10px", fontSize: "16px", marginRight: "10px" }}>{user.nombresVentes}</span>
                    <span style={{ fontSize: "16px" }}> Vente éffectuées</span>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div style={{ display: "flex", alignItems: "end" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span><BiMessageDots style={{ color: BLUE, fontSize: "24px" }} /></span>
                    <span style={{ fontSize: "16px", marginLeft: "10px" }}>Contacter</span>
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    {
                      me && me?.abonnes?.map(ab => ab.vendeur?.id)?.includes(user?.id) ? (
                        <>
                          <button
                            style={{
                              fontWeight: "bold",
                              border: "none",
                              outline: "none",
                              background: RED,
                              padding: "5px 30px",
                              borderRadius: "40px",
                              color: "#fff",
                              display: 'flex',
                              alignItems: "center"
                            }}
                            onClick={handleDeSabonner}
                          >
                            {loadingAbonner && <span style={{ marginRight: "10px" }}>
                              <EuiLoadingSpinner size="m" />
                            </span>}
                            <span>Désabonner</span>
                          </button>
                        </>
                      ) :
                        (
                          <button
                            style={{
                              fontWeight: "bold",
                              border: "none",
                              outline: "none",
                              background: BLUE,
                              padding: "5px 30px",
                              borderRadius: "40px",
                              color: "#fff",
                              display: 'flex',
                              alignItems: "center"
                            }}
                            onClick={handleSabonner}
                          >
                            {loadingAbonner && <span style={{ marginRight: "10px" }}>
                              <EuiLoadingSpinner size="m" />
                            </span>}
                            <span>S'abonner</span>
                          </button>
                        )
                    }
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )


  const DisplayEvaluationsContent = () => (
    <>
      <div className="mt-3 p-4 border rounded bg-white">
        <div className="row my-3">
          <div className="col-md" style={{ borderRight: "1px solid #ccc" }}>
            <div style={{ fontWeight: "bold", fontSize: "16px", color: ORANGE, textDecoration: "underline" }}>Notes sur le Prestataire</div>
            <div className="mt-3" style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "40px" }}>
                <div >
                  <span><AiFillPlusCircle style={{ fontSize: "20px", color: GREEN, marginRight: "2px" }} /> </span>
                  <span style={{ textDecoration: "underline", fontWeight: "bold" }}>Positives</span>
                </div>
                <div className="mt-2" style={{ fontSize: "15px", }}>
                  <Rate
                    disabled
                    value={user?.evaluation?.moyennePositive}
                  />
                  <span className="ms-2" style={{ fontSize: "12px" }}>{"( ".concat(user?.evaluation?.positiveEvaluations?.length).concat(" évaluations ) ")} </span>
                </div>
              </div>
              <div style={{ marginRight: "40px" }}>
                <div style={{ fontWeight: "bold", textDecoration: "underline" }}>Neutres</div>
                <div className="mt-2" style={{ fontSize: "15px" }}>
                  <Rate
                    disabled
                    value={user?.evaluation?.moyenneNeutre}
                    style={{ color: RED }}
                  />
                  <span className="ms-2" style={{ fontSize: "12px" }}>{"( ".concat(user?.evaluation?.neutreEvaluations?.length).concat(" évaluations ) ")} </span>
                </div>
              </div>
              <div>
                <div style={{ fontWeight: "bold", textDecoration: "underline" }}>Négatives</div>
                <div className="mt-2" style={{ fontSize: "15px" }}>
                  <Rate
                    disabled
                    value={user?.evaluation?.moyenneNegative}
                    style={{ color: RED }}
                  />
                  <span className="ms-2" style={{ fontSize: "12px" }}>{"( ".concat(user?.evaluation?.negativeEvaluations?.length).concat(" évaluations ) ")} </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md ps-4" >
            <div style={{ fontWeight: "bold", fontSize: "16px", color: ORANGE, textDecoration: "underline" }}>Evaluations selon quelques critères</div>
            <div className="mt-3" style={{ fontSize: "15px", display: "flex", alignItems: "center" }}>
              <div>
                <div style={{ fontWeight: "bold" }}>
                  <span><FaComments style={{ fontSize: "20px", color: BLUE, marginRight: "2px" }} /> </span>
                  <span style={{ textDecoration: "underline" }}>Communications</span>
                </div>
                <div className="mt-2">
                  <Rate
                    disabled
                    value={user?.evaluation?.noteCommunicationVendeur}
                    style={{ color: RED }}
                  />
                  <span className="ms-2" style={{ fontSize: "12px" }}>{"( ".concat(user?.evaluation?.communicationVendeurEvaluations?.length).concat(" évaluations ) ")} </span>
                  <div style={{ fontSize: "12px", color: "#00000090", padding: "2px", border: "1px solid #ccc", background: "#EEEEEE" }}>Evaluations sur la communication entre le client et le prestataire </div>
                </div>
              </div>
              <div style={{ marginLeft: "30px" }}>
                <div style={{ fontWeight: "bold" }}>
                  <span><AiOutlineFieldTime style={{ fontSize: "20px", color: ORANGE, marginRight: "2px" }} /> </span>
                  <span style={{ textDecoration: "underline" }}>Delais de livraison</span>
                </div>
                <div className="mt-2">
                  <Rate
                    disabled
                    value={user?.evaluation?.noteDelaisVendeur}
                    style={{ color: RED }}
                  />
                  <span className="ms-2" style={{ fontSize: "12px" }}>{"( ".concat(user?.evaluation?.delaisVendeurEvaluations?.length).concat(" évaluations ) ")} </span>
                  <div style={{ fontSize: "12px", color: "#00000090", padding: "2px", border: "1px solid #ccc", background: "#EEEEEE" }}>Evaluations sur le délais de livraison du prestataire </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">{DisplayFeedbacks()}</div>

    </>
  )

  const DisplayMenuSection = () => (
    <div className="mt-4">
      <Tabs
        defaultActiveKey={EXPLORER}
        items={[
          {
            label: <span style={{ fontSize: "15px", fontWeight: "bold" }}>Nos Services</span>,
            key: EXPLORER,
            children: DisplayUserPrestation(),
          },
          {
            label: <span style={{ fontSize: "15px", fontWeight: "bold" }}>A Propos</span>,
            key: A_PROPOS,
            children: DisplayUserAbout(),
          },
          {
            label: <span style={{ fontSize: "15px", fontWeight: "bold" }}>Nos Evaluations</span>,
            key: EVALUATION,
            children: DisplayEvaluationsContent(),
          },
        ]}
      />
    </div>
  )

  const DisplayUserDetail = () => (
    <>
      {user?.bannerImage && DisplayUserBannerImage()}
      {DisplayUserInfo()}
      {DisplayMenuSection()}
    </>
  )

  useEffect(() => {
    id && loadUser()
  }, [])

  useEffect(() => {
    if (user) {
      loadFeedbacks()
    }
  }, [user])

  return (
    <div style={{ minHeight: "90vh" }}>
      <div className="my-container">
        {
          loading ? (
            <Spin tip="Loading..." />
          ) :
            user ? DisplayUserDetail()
              : ElementNotFound()
        }
      </div>
    </div>
  )
}

const HomeDetail = () => {
  backTop()
  return (
    <div className="mt-3">
      <Route exact path="/detail/service/:id" component={HomeService} />
      <Route exact path="/detail/profil/:id" component={HomeProfil} />
    </div>
  )
}

export default HomeDetail
