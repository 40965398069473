import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import tokenStore from "../stores/tokenStore"
import meStore from "../stores/meStore"
import { Avatar, Badge, Button, Dropdown, Spin } from "antd"
import { MailOutlined } from "@ant-design/icons"
import { IoNotificationsCircleSharp } from 'react-icons/io5'
import jwt from "jsonwebtoken"
import panierStore from "../stores/panierStore"
import "./TopBar.css"
import {
  BASE_PATH,
  NOTIFICATION_ROUTE,
  MESSAGES_ROUTE,
} from "../utils/api.route"
import { BLUE, ORANGE, JAUNE } from "../theme/couleurs"
import { CgProfile } from "react-icons/cg"
import { ADMIN, APP_NAME, CHANGEMENT_PROFIL, COMMANDE_ACCEPTER, COMMANDE_REFUSER, COMMANDE_TERMINER, DEMANDEUR, NOUVELLE_COMMANDE, PRESTATAIRE, SUGGESTION_CLIENT_DELAIS_DE_LIVRAISON_COMMANDE, SUGGESTION_PRESTATAIRE_DELAIS_DE_LIVRAISON_COMMANDE, SYSTEM } from "../utils/constants"
import { GrSystem } from 'react-icons/gr'
import moment from "moment"

const TopBar = () => {
  const token = tokenStore(state => state.token)
  const createToken = tokenStore(state => state.createToken)
  const createMe = meStore(state => state.createMe)
  const me = meStore(state => state.me)
  const decodedToken = jwt.decode(token)
  const userId = decodedToken?.id
  const [messages, setMessages] = useState(0)
  const addServices = panierStore(state => state.addServices)
  const [loadingMessages, setLoadingMessages] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [loadingNotifications, setLoadingNotifications] = useState(false)

  const history = useHistory()

  const loadMessages = () => {
    setLoadingMessages(true)
    fetch(
      MESSAGES_ROUTE.concat("/recus/user/")
        .concat(userId)
        .concat("?page=0&size=100"),
      {
        method: "get",
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.error)
          throw new Error("Erreur de récuperation de la listes des méssages !")

        const size = result?.content?.filter((m) => m.read === false)?.length
        setMessages(size)
        setLoadingMessages(false)
      })
      .catch((err) => {
        setLoadingMessages(false)
      })
  }



  const loadNotifications = async () => {
    setLoadingNotifications(true)
    const route = NOTIFICATION_ROUTE.concat("?userId=")
      .concat(userId)
      .concat("&")
      // .concat("read=false")
      .concat('size=15&sort=id,DESC')
    fetch(route, {
      method: "get",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.error)
          throw result

        setNotifications(result.content)
        setLoadingNotifications(false)
      })
      .catch((err) => {
        setLoadingNotifications(false)
      })
  }

  const updateNotifications = async (payload) => {
    const route = NOTIFICATION_ROUTE

    fetch(route, {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (!result || result.error)
          throw result

        loadNotifications()
      })
      .catch((err) => {
        setLoadingNotifications(false)
      })
  }

  const handleLoggout = () => {
    createToken(null)
    createMe(null)
    addServices([])
  }

  const DisplayMenu = () => (
    <div
      className="text-center bg-white p-4 shadow rounded border"
      style={{ minWidth: "200px", maxWidth: "200px" }}
    >
      <div className="my-2">
        <Avatar
          size="large"
          src={
            me?.userImage
              ? me?.userImage?.url
              : BASE_PATH.concat("/img/noProfile.jpg")
          }
        />
        <div>{me?.username}</div>
        <div >{me?.email}</div>
      </div>
      <hr />
      <div className="text-left d-flex align-items-center my-2">
        <CgProfile style={{ fontSize: "22px" }} className="item-menu" />
        <Link
          to="/dashboard/compte"
          className="item-menu ml-3 font-weight-normal"
        >
          Profil
        </Link>
      </div>

      <div className="pt-2">
        <Button
          size="small"
          onClick={handleLoggout}
          style={{
            background: ORANGE,
            color: "#fff",
            border: "none",
            width: "100%",
          }}
        >
          Déconnecter
        </Button>
      </div>
    </div>
  )

  const handleClickNotification = async (notification) => {

    await updateNotifications({ userId: me?.id, notificationId: notification.id, read: true })


    switch (notification.type) {

      case NOUVELLE_COMMANDE:
        if (notification.commande)
          history.push("/dashboard/commandes/details/".concat(notification.commande?.id).concat('/').concat(PRESTATAIRE))
        break

      case COMMANDE_REFUSER:
        if (notification.commande)
          history.push("/dashboard/commandes/details/".concat(notification.commande?.id).concat('/').concat(DEMANDEUR))
        break

      case COMMANDE_ACCEPTER:
        if (notification.commande)
          history.push("/dashboard/commandes/details/".concat(notification.commande?.id).concat('/').concat(DEMANDEUR))
        break

      case COMMANDE_TERMINER:
        if (notification.commande)
          history.push("/dashboard/commandes/details/".concat(notification.commande?.id).concat('/').concat(DEMANDEUR))
        break

      case SUGGESTION_CLIENT_DELAIS_DE_LIVRAISON_COMMANDE:
        if (notification.commande)
          history.push("/dashboard/commandes/details/".concat(notification.commande?.id).concat('/').concat(PRESTATAIRE))
        break


      case SUGGESTION_PRESTATAIRE_DELAIS_DE_LIVRAISON_COMMANDE:
        if (notification.commande)
          history.push("/dashboard/commandes/details/".concat(notification.commande?.id).concat('/').concat(DEMANDEUR))
        break

      case SYSTEM:
        break

      default:
        break
    }

  }

  const DisplayNotificationMenu = () => (
    <div className="bg-white p-2 border rounded shadow eui-scrollBar" style={{ maxWidth: "300px", overflowY: "auto", maxHeight: "500px" }}>
      {
        loadingNotifications ? <Spin tip="chargement des notifications" /> :
          notifications
            .filter(notif => me?.profil?.nom === ADMIN ? true : notif.type !== SYSTEM ? true : false)
            ?.length > 0 ?
            (
              <div className="d-flex flex-column align-items-center justify-content-center" style={{ position: 'relative' }}>
                <div
                  className="shadow-sm border-bottom"
                  style={{
                    margin: '0px',
                    position: 'sticky',
                    top: '-8px',
                    left: '0px',
                    background: '#fff',
                    width: '100%',
                  }}>
                  <div
                    style={{
                      borderBottom: '1px solid #ccc',
                      width: '100%',
                      padding: '10px',
                      textAlign: 'center'
                    }}
                  >
                    <span style={{
                      fontWeight: "bold",
                      textDecoration: 'underline',
                      color: ORANGE,
                      fontSize: '15px',
                    }}>
                      Notifications
                    </span>
                    {
                      notifications
                        .filter(notif => me?.profil?.nom === ADMIN ? true : notif.type !== SYSTEM ? true : false)
                        .filter(notif => !notif.read)
                        .length > 0 &&
                      (
                        <span style={{ marginLeft: '10px' }}>
                          {"( " + notifications
                            .filter(notif => me?.profil?.nom === ADMIN ? true : notif.type !== SYSTEM ? true : false)
                            .filter(notif => !notif.read)
                            .length
                          } non lues )
                        </span>

                      )
                    }
                  </div>
                </div>
                {
                  notifications
                    ?.filter(notif => me?.profil?.nom === ADMIN ? true : notif.type !== SYSTEM ? true : false)
                    ?.map(notification => (
                      <div className="border-bottom p-2 rounded mt-1 notification-in-app"
                        onClick={() => handleClickNotification(notification)}
                        key={notification.id}>
                        <div>
                          <span className="me-2">
                            <IoNotificationsCircleSharp
                              className="font-weight-bold"
                              style={{ color: BLUE, fontSize: "22px" }}
                            />
                          </span>
                          <span
                            className={notification.read ? 'read' : 'fw-bold'}
                          >{notification.message}</span>
                        </div>
                        {
                          notification.createdAt && (
                            <div className="fw-bold text-muted" >{moment(notification.createdAt).format('DD MMM YYYY HH:mm:ss')}</div>
                          )
                        }
                      </div>
                    ))
                }
              </div>
            )
            :
            <div className="d-flex flex-column align-items-center justify-content-center p-2">
              <p className="text-muted fw-bold" > Pas de Notification !</p>
            </div>
      }
    </div>
  )

  useEffect(() => {
    if (userId) {
      loadNotifications()
    }
  }, [])

  return (
    <div className="navbar navbar-expand-lg navbar-light bg-white fixed-top border-bottom px-4 mb-5">
      <div className="d-flex align-items-center ">
        <Link to="/" className="navbar-brand">
          <span style={{ fontSize: 20, fontWeight: "bold" }}>
          {APP_NAME}
          </span>
        </Link>
      </div>
      <button
        className="navbar-toggler btn-sm"
        type="button"
        data-toggle="collapse"
        data-target="#admin-header"
        aria-controls="admin-header"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse"
        id="admin-header"
      >
        <div className="navbar-nav ms-auto">
          {token && token !== "" && (
            <div className="d-flex">
              {
                me?.profil?.nom === ADMIN &&
                (
                  <div className="text-center">
                    <Badge count={messages} size="small">
                      <Link to={"/dashboard/messages/recus/"}>
                        <MailOutlined
                          className="font-weight-bold"
                          style={{ color: "#000", fontSize: "32px" }}
                        />
                      </Link>
                    </Badge>
                    <div
                      style={{ fontSize: "10px", cursor: "pointer" }}
                    >
                      Messages
                    </div>
                  </div>
                )
              }

              <div className="ml-4 text-center">
                <Dropdown dropdownRender={DisplayNotificationMenu}>
                  <div className="text-center">
                    <Badge count={
                      notifications
                        .filter(notif => me?.profil?.nom === ADMIN ? true : notif.type !== SYSTEM ? true : false)
                        .filter(notif => !notif.read)
                        .length} size="large">
                      <span style={{ cursor: 'pointer' }}>
                        <IoNotificationsCircleSharp
                          className="font-weight-bold"
                          style={{ color: BLUE, fontSize: "32px" }}
                        />
                      </span>
                    </Badge>
                    <div
                      style={{ cursor: "pointer" }}
                    >
                      Notifications
                    </div>
                  </div>
                </Dropdown>
              </div>

              <div className="ml-4">
                <Dropdown dropdownRender={DisplayMenu}>
                  <div className="text-center">
                    <Avatar
                      src={me?.userImage ? me?.userImage?.url : BASE_PATH.concat("/img/noProfile.jpg")}
                    />
                    <div style={{ cursor: "pointer" }}>{me?.username}</div>
                  </div>
                </Dropdown>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TopBar
