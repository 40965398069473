import create from "zustand";

const store = (set) => ({
  imageCatalogueListes:[],
  addImageCatalogueListes: imageCatalogueListes => set({ imageCatalogueListes }),
});

const imageCatalogueListesStore = create(
  store
);

export default imageCatalogueListesStore;
