import React, { useState, useEffect } from "react"
import { NotificationManager } from "react-notifications"
import {
    DeleteOutlined,
    EyeInvisibleOutlined,
} from "@ant-design/icons"
import { Popconfirm, Spin, Table, Tag } from "antd"
import tokenStore from "../stores/tokenStore"
import Title from "antd/lib/typography/Title"
import { QUESTIONS_ROUTE } from '../utils/api.route'

const AdminQuestion = () => {
    const [questions, setQuestions] = useState([])

    const token = tokenStore(state => state.token)
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [totalElements, setTotalElements] = useState(0)
    const pageSize = 10

    const loadQuestions = () => {
        setLoading(true)
        fetch(QUESTIONS_ROUTE.concat('?page=').concat(page).concat('&size=').concat(pageSize).concat('&type=SERVICE'), {
            method: "get",
            headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then(response => response.json())
            .then((datas) => {
                if (datas.error)
                    throw new Error(datas.message ? datas.message : datas.error)

                setTotalElements(datas.totalElements)
                setQuestions(datas.content)
                return setLoading(false)
            })
            .catch((err) => {
                NotificationManager.error(err.message, null, 3000)
                setLoading(false)
            })

    }

    const Results = () => {
        const columns = [
            {
                key: "id",
                title: "Id",
                width: 50,
                dataIndex: "id",
            },
            {
                key: "question",
                title: "Question",
                dataIndex: "question",
            },
            {
                key: "reponse",
                title: "Reponse",
                dataIndex: "reponse",
            },
            {
                key: "type",
                title: "Type",
                dataIndex: "type",
            },
            {
                key: "actions",
                title: "Actions",
                fixed: "right",
                render: (value) => (
                    <div>
                        <EyeInvisibleOutlined
                            style={{ cursor: "pointer" }}
                        />
                        <Popconfirm
                            onConfirm={() => handleDeleteQuestion(value.id)}
                            title="Etes-vous sûr de vouloir supprimé cette question ? "
                        >
                            <DeleteOutlined style={{ cursor: "pointer" }} />
                        </Popconfirm>
                    </div>
                ),
            },
        ]

        const datas = questions.map(question => ({
            key: question.id,
            id: question.id,
            question: question.question,
            reponse: question.reponse,
            type: question.type,
            actions: question,
        }))

        return loading ? (
            <Spin tip="loading...." />
        ) : (
            <div style={{ width: 400 }}>
                <Table
                    bordered
                    size="small"
                    dataSource={datas}
                    columns={columns}
                    pagination={{ pageSize: pageSize, total: totalElements }}
                />
            </div>
        )
    }

    const handleDeleteQuestion = (id) => {
        setLoading(true)
        fetch(QUESTIONS_ROUTE.concat('/').concat(id), {
            method: "DELETE",
            headers: {
                "content-type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) throw new Error(data.message)
                NotificationManager.success("Suppression réussie...", null, 3000)
                return setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                NotificationManager.error(err.message, null, 3000)
            })
    }

    useEffect(() => {
        loadQuestions()
    }, [])

    return (
        <div style={{ marginTop: 10 }}>
            <Title level={4}>List des  Questions</Title>
            <div style={{ display: "flex", flexWrap: "wrap", width: 400 }}>
                <p level={4} style={{ flex: 1 }}>
                    Nombres de questions:
                    <span style={{ color: "#00000090" }}>
                        {questions.length} élément(s)
                    </span>
                </p>

            </div>

            <div>
                <Results />
            </div>
        </div>
    )
}

export default AdminQuestion
