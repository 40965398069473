import React, { useEffect, useState } from "react"
import { useHistory } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react"
import meStore from "../stores/meStore"
import tokenStore from "../stores/tokenStore"
import {
  Carousel,
  Spin,
  Select,
} from "antd"
import dataTypeStore from "../stores/dataTypeStore"
import currentCategorieStore from "../stores/currentCategorieStore"
import { NotificationManager } from "react-notifications"
import {
  LISTE_INVITE_USERS,
  LISTE_INVITE_SERVICES,
  FAVORIS_ROUTE,
  PUBS_INVITE_ROUTE,
} from "../utils/api.route"
import SwiperCore, { Navigation } from "swiper"
import favorisStore from "../stores/favorisStore"
import HomeCustumCard from "../components/HomeCustumCard"
import { HomeCustumProfileCard } from "../components/HomeCustumProfileCard"
import filtresStore from "../stores/filtresStore"
import categoriesStore from "../stores/categoriesStore"
import {
  GRAY,
  GREEN_LIGHT,
  ORANGE,
} from "../theme/couleurs"

import HomeFooter from "../components/HomeFooter"
import HomeHeader from "../components/HomeHeader"
import { ALL, PRESTATAIRE, PRESTATAIRES_ELEMENTS_SIZE, PRESTATION, PRESTATIONS_ELEMENTS_SIZE } from "../utils/constants"
import { BsStarFill } from "react-icons/bs"
import "./Home.css"
import { EuiLoadingSpinner } from "@elastic/eui"
import { isValidToken } from "../utils/utilsFunction"
import MyPagination from "../components/MyPagination"


SwiperCore.use([Navigation])

const HomeSectionBoost = () => {
  const [boostedUsers, createBoostedUsers] = useState([])
  const [sectionBoostedLoading, setSectionBoostedLoading] = useState(false)

  const loadBoostedUsers = () => {
    setSectionBoostedLoading(false)
    fetch(
      LISTE_INVITE_USERS.concat("?page=0&size=30&sort=id,DESC").concat(
        "&boosted=true"
      ),
      {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((datas) => {
        if (datas.error)
          throw new Error(datas.message ? datas.message : datas.error)

        createBoostedUsers(datas.content)
        setSectionBoostedLoading(false)
      })
      .catch((err) => {
        setSectionBoostedLoading(false)
      })
  }

  useEffect(() => {
    loadBoostedUsers()
  }, [])

  return (
    <section
      style={{ background: GREEN_LIGHT }}
      className="py-4 border-bottom border-top"
    >
      {boostedUsers.length > 0 && (
        <div className="my-container">
          <div className="row">
            <div className="col-md-12">
              <h4
                className="text-center fw-bold"
                style={{ color: ORANGE, textDecoration: "underline", fontSize: "18px" }}
              >
                Top meilleurs prestataires
              </h4>

              <div className="text-center" style={{ color: GRAY, fontSize: "14px" }}>
                Des meilleurs prestataire recommandé pour vous
              </div>
              <div className="mt-2">
                {sectionBoostedLoading ? (
                  <Spin tip="loading...." />
                ) : (
                  <Swiper
                    slidesPerView={1}
                    slidesPerGroup={1}
                    navigation
                    loop
                    speed={1000}
                    autoplay={true}
                    autoplayspeed={5000}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },

                      768: {
                        slidesPerView: 2,
                      },
                      992: {
                        slidesPerView: 3,
                      },
                    }}
                  >
                    {boostedUsers.map((user, index) => (
                      <SwiperSlide key={index} className="px-1 py-2">
                        <HomeCustumProfileCard
                          key={index}
                          url={"/detail/profil/" + user.id}
                          profil={user}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}


export const HomePageContent = ({ params }) => {
  const me = meStore(state => state.me)
  const token = tokenStore(state => state.token)
  const filtres = filtresStore((state) => state.filtres)
  const dataType = dataTypeStore(state => state.dataType)
  const setDataType = dataTypeStore(state => state.createDataType)

  const selectedCategorie = currentCategorieStore(state => state.selectedCategorie)

  const [prestations, setPrestations] = useState([])
  const [prestationPage, setPrestationPage] = useState(0)
  const [prestationTotalElements, setPrestationTotalElements] = useState(0)
  const [prestationsTotalPages, setPrestationsTotalPages] = useState(0)
  const [loadingPrestations, setLoadingPrestations] = useState(false)

  const [prestataires, setPrestataires] = useState([])
  const [prestatairePage, setPrestatairePage] = useState(0)
  const [prestataireTotalElements, setPrestataireTotalElements] = useState(0)
  const [prestatairesTotalPages, setPrestatairesTotalPages] = useState(0)
  const [loadingPrestataires, setLoadingPrestataires] = useState([])

  const addFavoris = favorisStore(state => state.addFavoris)
  const categories = categoriesStore(state => state.categories)


  const [pubs, setPubs] = useState([])
  const [loadingPubs, setLoadingPubs] = useState(false)
  const setFiltres = filtresStore(state => state.addFiltres)
  const router = useHistory()

  const setting = {
    infinite: true,
    autoplay: true,
    autoplayspeed: 8000,
    speed: 1500,
    slidesPerRow: 1,
  }

  const loadPubBanner = () => {
    setLoadingPubs(true)
    fetch(PUBS_INVITE_ROUTE)
      .then((response) => response.json())
      .then((response) => {
        if (response.error)
          throw new Error(
            "Erreur de récuperation de la liste des images publicitaires "
          )
        setPubs(response.pubs)
        setLoadingPubs(false)
      })
      .catch((err) => {
        setLoadingPubs(false)
      })
  }

  // const handleSubmit = (event) => {
  //   event.preventDefault()
  //   if (searchInput && searchInput.trim() !== "") {
  //     let newFiltre = filtres.filter(item => item.titre !== "Recherche")
  //     newFiltre = newFiltre.concat({
  //       nom: searchInput,
  //       titre: "Recherche",
  //       value: searchInput,
  //     })
  //     !router.pathname?.includes('categories') && router.push("/categories/ALL")
  //     return setFiltres(newFiltre)
  //   } else {
  //     return setFiltres(filtres.filter(item => item.titre !== "Recherche"))
  //   }
  // }

  // const handleChangeSearchInput = (event) => setSearchInput("" + event.target.value)


  const loadPrestations = () => {

    setLoadingPrestations(true)
    let route = LISTE_INVITE_SERVICES.concat(
      prestationPage ? "?page=".concat(prestationPage).concat("&") : "?"
    )

    if (filtres.length > 0) {
      let newFilterObject = {}
      newFilterObject.competences = filtres
        .filter(f => f.titre === "Catégorie")
        .map(f => f.value)

      newFilterObject.notes = filtres
        .filter(f => f.titre === "Note")
        .map(f => f.value)

      newFilterObject.recherche = filtres.find(
        f => f.titre === "Recherche"
      )?.value

      if (newFilterObject.competences.length > 0) {
        route = route
          .concat("competences=".concat(newFilterObject.competences.join()))
          .concat("&")
      }

      if (newFilterObject.notes.length > 0) {
        route = route.concat(
          "notes=".concat(newFilterObject.notes.join())
            .concat("&")
        )
      }

      if (newFilterObject.recherche) {
        route = route.concat(
          "recherche=".concat(newFilterObject.recherche)
            .concat("&")
        )
      }
    }

    if (
      filtres.filter(item => item.titre === "Catégorie").length === 0 &&
      selectedCategorie[0]?.value !== ALL &&
      categories.filter(cat => selectedCategorie[0]?.value === cat.parentId).length > 0
    ) {

      route = route
        .concat(
          "competences=".concat(
            categories.filter(cat => selectedCategorie[0]?.value === cat.parentId)
              .map((cat) => cat.id)
              ?.join()
          )
        )
        .concat("&")
    }


    route = route
      .concat("size=")
      .concat(PRESTATIONS_ELEMENTS_SIZE)
      .concat("&")
      .concat("sort=id,DESC")

    fetch(route, {
      method: "get",
      headers: {
        "content-type": "application/json",
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.error)
          throw new Error(
            response.message
              ? response.message
              : "Erreur de chargement des services"
          )
        setPrestations(response.content)
        setPrestationTotalElements(response.totalElements)
        setPrestationsTotalPages(response.totalPages);
        setLoadingPrestations(false)
      })
      .catch((err) => {
        setLoadingPrestations(false)
      })
  }

  const loadPrestataires = () => {
    setLoadingPrestataires(true)

    let route = LISTE_INVITE_USERS.concat(
      prestatairePage ? "?page=".concat(prestatairePage).concat("&") : "?"
    )

    if (filtres.length > 0) {
      let newFilterObject = {}
      newFilterObject.competences = filtres
        .filter(f => f.titre === "Catégorie")
        .map(f => f.value)

      newFilterObject.notes = filtres
        .filter(f => f.titre === "Note")
        .map(f => f.value)

      newFilterObject.recherche = filtres.find(
        f => f.titre === "Recherche"
      )?.value

      if (newFilterObject.competences.length > 0) {
        route = route
          .concat("competences=".concat(newFilterObject.competences.join()))
          .concat("&")
      }

      if (newFilterObject.notes.length > 0) {
        route = route.concat(
          "notes=".concat(newFilterObject.notes.join())
            .concat("&")
        )
      }

      if (newFilterObject.recherche) {
        route = route.concat(
          "recherche=".concat(newFilterObject.recherche)
            .concat("&")
        )
      }
    }

    if (
      filtres.filter(item => item.titre === "Catégorie").length === 0 &&
      selectedCategorie[0]?.value !== ALL &&
      categories.filter(cat => selectedCategorie[0]?.value === cat.parentId).length > 0
    ) {

      route = route
        .concat(
          "competences=".concat(
            categories.filter(cat => selectedCategorie[0]?.value === cat.parentId)
              .map((cat) => cat.id)
              ?.join()
          )
        )
        .concat("&")
    }

    route = route
      .concat("size=")
      .concat(PRESTATAIRES_ELEMENTS_SIZE)
      .concat("&")
      .concat("sort=id,DESC")

    fetch(route, {
      method: "get",
      headers: {
        "content-type": "application/json",
      },
    })
      .then(response => response.json())
      .then((response) => {
        if (response.error)
          throw new Error(
            response.message
              ? response.message
              : "Erreur de chargement de la liste des profiles "
          )
        setPrestataires(response.content)
        setLoadingPrestataires(false)
        setPrestataireTotalElements(response.totalElements)
        setPrestatairesTotalPages(response.totalPages);
      })
      .catch((err) => {
        setLoadingPrestataires(false)
      })
  }

  const loadFavoris = () => {
    fetch(FAVORIS_ROUTE.concat("?userId=").concat(me.id), {
      method: "get",
      headers: {
        Authorization: "Bearer ".concat(token),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error)
          throw new Error(
            response.message
              ? response.message
              : "Erreur de récupération de la liste des favoris !"
          )
        addFavoris(response.favoris)
      })
      .catch((err) => {
        NotificationManager.error(err.message)
      })
  }

  const handleCategorieFilter = (value) => {
    if (value) {
      const newObjectList = value.map((catId) => {
        const categorieTrouver = categories.find((cat) => cat.id === catId)
        return (
          categorieTrouver && {
            nom: categorieTrouver.nom,
            titre: "Catégorie",
            value: categorieTrouver.id,
          }
        )
      })

      let newFiltres = filtres.filter(item => item.titre !== "Catégorie")
      return setFiltres([...newFiltres, ...newObjectList])
    }
  }

  const handleRateFilter = (value) => {
    if (value) {
      const newObjectList = value.map((note) => {
        let newObject = {}

        if (note === 5) {
          newObject.nom = "5 Etoiles"
          newObject.titre = "Note"
          newObject.value = note
        }
        if (note === 4) {
          newObject.nom = "4 Etoiles"
          newObject.titre = "Note"
          newObject.value = note
        }
        if (note === 3) {
          newObject.nom = "3 Etoiles"
          newObject.titre = "Note"
          newObject.value = note
        }
        if (note === 2) {
          newObject.nom = "2 Etoiles"
          newObject.titre = "Note"
          newObject.value = note
        }
        if (note === 1) {
          newObject.nom = "1 Etoiles"
          newObject.titre = "Note"
          newObject.value = note
        }

        return newObject
      })

      let newFiltres = filtres.filter(item => item.titre !== "Note")
      return setFiltres([...newFiltres, ...newObjectList])
    }
  }


  // const RenderFilterValues = () => filtres.length > 0 && (
  //   <div className="mt-4">
  //     <div className="my-container">
  //       <div style={{ textDecoration: "underline" }}>
  //         Critères de Filtre:
  //       </div>
  //       <div className="d-flex align-items-center flex-wrap mt-1">
  //         {filtres.map((filtre, index) => (
  //           <div
  //             key={index}
  //             className="border rounded bg-white shadow d-flex align-items-center me-2 mt-1"
  //           >
  //             <div className="px-2 py-1">
  //               {filtre.titre === "Note" && (
  //                 <span className="me-2">

  //                   <BsStarFill className="text-warning fs-6" />
  //                 </span>
  //               )}
  //               <span className="text-muted" style={{ fontSize: "12px" }}>{filtre.titre} : </span>
  //               <span>{filtre.nom} </span>
  //             </div>
  //             <div
  //               className="close-filtre-item-button text-center"
  //               onClick={() =>
  //                 setFiltres(filtres.filter(item => item !== filtre))
  //               }
  //             >
  //               <span className="px-2 py-1 border-start">X</span>
  //             </div>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   </div>
  // )

  const RenderFilter = () => (
    <div className="mt-4">
      <div className="my-container">
        <div className="rounded bg-white border">
          <div className="p-3 d-flex flex-wrap">
            <div className="me-2">
              <Select
                value={dataType}
                onSelect={(value) => setDataType(value)}
                style={{ minWidth: "150px" }}
              >
                <Select.Option key={1} value={PRESTATION}>
                  Prestation de services
                </Select.Option>
                <Select.Option key={2} value={PRESTATAIRE}>
                  Prestataires
                </Select.Option>

              </Select>
            </div>
            {
              selectedCategorie.length > 0 &&
              categories
                .filter(cat => selectedCategorie[0]?.value === ALL ?
                  true :
                  categories.find(c => c.id === selectedCategorie[0]?.value)?.parentId === null ?
                    cat.parentId === selectedCategorie[0]?.value
                    : false
                ).length > 0 && (
                <div className="me-2">
                  <Select
                    onChange={handleCategorieFilter}
                    value={filtres
                      .filter(f => f.titre === "Catégorie")
                      .map(cat => cat.value)}
                    style={{ width: "250px" }}
                    placeholder="Filtrer par catégories"
                    mode="multiple"
                    clearIcon
                  >
                    {
                      categories
                        .filter(cat => selectedCategorie[0]?.value === ALL ?
                          true :
                          categories.find(c => c.id === selectedCategorie[0]?.value)?.parentId === null ?
                            cat.parentId === selectedCategorie[0]?.value
                            : false
                        )
                        .map(cat => (
                          <Select.Option key={cat.id} value={cat.id}>
                            {cat.nom}
                          </Select.Option>
                        ))
                    }
                  </Select>
                </div>
              )}

            {(
              <div>
                <Select
                  onChange={handleRateFilter}
                  value={filtres
                    .filter(f => f.titre === "Note")
                    .map((note) => note.value)}
                  style={{ width: "250px" }}
                  placeholder="Filtrer par note"
                  mode="multiple"
                  showArrow
                >
                  <Select.Option key={2} value={5}>
                    <div className="d-flex align-item-center">
                      <span className="me-2">
                        <BsStarFill className="text-warning fs-6" />
                      </span>
                      <span> 5 Etoiles</span>
                    </div>
                  </Select.Option>
                  <Select.Option key={3} value={4}>
                    <div className="d-flex align-item-center">
                      <span className="me-2">
                        <BsStarFill className="text-warning fs-6" />
                      </span>
                      <span> 4 Etoiles</span>
                    </div>
                  </Select.Option>
                  <Select.Option key={4} value={3}>
                    <div className="d-flex align-item-center">

                      <span className="me-2">
                        <BsStarFill className="text-warning fs-6" />
                      </span>
                      <span> 3 Etoiles</span>
                    </div>
                  </Select.Option>
                  <Select.Option key={5} value={2}>
                    <div className="d-flex align-item-center">

                      <span className="me-2">
                        <BsStarFill className="text-warning fs-6" />
                      </span>
                      <span> 2 Etoiles</span>
                    </div>
                  </Select.Option>
                  <Select.Option key={6} value={1}>
                    <div className="d-flex align-item-center">

                      <span className="me-2">
                        <BsStarFill className="text-warning fs-6" />
                      </span>
                      <span> 1 Etoile</span>
                    </div>
                  </Select.Option>
                </Select>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )

  const RenderPrestations = () => (
    <>
      <div className="my-container my-2" >
        <div
          className="d-flex align-items-center my-2"
        >
          <div style={{ fontWeight: "bold", textDecoration: "underline", fontSize: "16px" }}> Prestation de services</div>
          <div style={{
            marginLeft: "30px",
            backgroundColor: "#850014",
            color: "#fff",
            padding: "5px 20px",
            fontWeight: "bold",
            borderRadius: "20px",
            border: "1px solid #ccc"
          }}> Dans {
              selectedCategorie?.[0]?.value === ALL ?
                selectedCategorie?.[0]?.label :
                categories.find(cat => cat.id === selectedCategorie?.[0]?.value)?.nom
            }
          </div>
        </div>
        <div>Retrouvez vos besoins dans cette liste des meilleurs services.</div>
        <div className="mt-1 text-right">Total des prestations de services: <span className="fw-bold ml-1">{prestationTotalElements}</span></div>

        <div className="row">
          {
            loadingPrestations && (
              <div style={{ margin: "50px 0px", display: "flex", alignItems: "center" }}>
                <EuiLoadingSpinner size="xl" /> <span style={{ marginLeft: "20px" }}>Chargement des services...</span>
              </div>
            )
          }

          {!loadingPrestations && prestations.map((serviceOffer, index) => (
            <div key={index} className="col-xs-1 col-sm-6 col-md-4 col-lg-3 col-xxl-2 my-1">
              <HomeCustumCard
                hasFavoris={true}
                hasUserProfil={true}
                type={PRESTATION}
                service={serviceOffer}
                loadFavoris={loadFavoris}
              />
            </div>
          ))}

          <div className="my-4">
            {prestationsTotalPages > 1 && prestations.length > 0 && (
              <MyPagination
                currentPage={prestationPage}
                setCurrentPage={setPrestationPage}
                totalPages={prestationsTotalPages}
              />
            )}
          </div>

        </div>

      </div>
    </>
  )

  const RenderBanner = () => (
    <>
      <div className="my-container mt-2 rounded" >
        {pubs.length > 0 && (
          <div style={{ position: "relative" }} >
            {
              pubs.filter(item => item.type === "BANNER") && (
                <Carousel {...setting} dots={false} draggable >
                  {pubs
                    .filter(item => item.type === "BANNER")
                    .map(
                      (item, index) =>
                        item.image &&
                        item.image.url && (
                          <div key={index} >
                            <img
                              src={item.image.url}
                              style={{ objectFit: "cover", height: "250px", width: "100%" }}
                              alt={item.nom}
                              className="rounded"
                            />
                          </div>
                        )
                    )}
                </Carousel>
              )
            }
            <div
              style={{
                position: "absolute",
                top: 100,
                width: "100%", padding: '20px'
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  color: "#fff",
                  fontWeight: "bold",
                  backgroundColor: "#00000060",
                  padding: "10px",
                  borderRadius: "20px",
                  textAlign: 'center'
                }}
              >
                Des milliers de services offerts par les meilleurs prestataires à votre disposition. Inscrivez-vous maintenant et passez vos commandes, ou devenez l'un des meilleurs prestataires et offrez des services avec votre talent.
              </div>
              {/* <div className="my-container">
                <div style={{ maxWidth: "80%", margin: "0px auto" }}>
                  
              </div> */}
            </div>
          </div>
        )}
      </div>
    </>
  )

  const RenderPrestataires = () => (
    <>
      <div className="my-container my-2" >
        <div
          className="d-flex align-items-center my-2"
        >
          <div style={{ fontWeight: "bold", textDecoration: "underline", fontSize: "16px" }}> Prestataires</div>
          <div style={{
            marginLeft: "30px",
            backgroundColor: "#850014",
            color: "#fff",
            padding: "5px 20px",
            fontWeight: "bold",
            borderRadius: "20px",
            border: "1px solid #ccc"
          }}> Dans {selectedCategorie?.[0]?.value === ALL ? selectedCategorie?.[0]?.label : categories.find(cat => cat.id === selectedCategorie?.[0]?.value)?.nom} </div>
        </div>
        <div className="mt-1">Des meilleurs prestataires en tout genre pour vous</div>
        <div className="mt-1 text-right">Total des prestataires: <span className="fw-bold ml-1">{prestataireTotalElements}</span></div>

        <div className="row">

          {
            loadingPrestataires && (
              <div style={{ margin: "50px 0px", display: 'flex', alignItems: "center" }}>
                <EuiLoadingSpinner size="xl" /> <span style={{ marginLeft: "20px" }}>Chargement des prestataires...</span>
              </div>
            )
          }

          {!loadingPrestataires && prestataires.map((prestataire, index) => (
            <div key={index} className="col-xs-1 col-sm-6 col-md-4 col-lg-3 col-xxl-2 my-1">
              <HomeCustumProfileCard
                profil={prestataire}
              />
            </div>
          ))}
        </div>


        <div className="my-4">
          {prestatairesTotalPages > 1 && prestataires.length > 0 && (
            <MyPagination
              currentPage={prestatairePage}
              setCurrentPage={setPrestatairePage}
              totalPages={prestatairesTotalPages}
            />
          )}
        </div>
      </div>
    </>
  )

  useEffect(() => {
    dataType === PRESTATION && loadPrestations()
    dataType === PRESTATAIRE && loadPrestataires()
  }, [dataType, filtres, selectedCategorie, prestatairePage, prestationPage])


  useEffect(() => {
    if (me && token && isValidToken(token)) {
      loadFavoris()
    }
  }, [token, me])

  useEffect(() => {
    loadPubBanner()
  }, [])



  return (
    <div style={{ marginTop: "10px" }} className="">
      {RenderBanner()}
      {RenderFilter()}
      {dataType === PRESTATION && RenderPrestations()}
      {dataType === PRESTATAIRE && RenderPrestataires()}
    </div>
  )
}

const Home = ({ children }) => (
  <>
    <HomeHeader />
    <div style={{ minHeight: "90vh" }}>
      {children}
    </div>
    <HomeFooter />
  </>
)

export default Home
