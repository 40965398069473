import create from "zustand";
import { persist } from "zustand/middleware";

const store = (set) => ({
  loginValue: null,
  setLoginValue: loginValue => set({ loginValue }),
});

const loginValueStore = create(persist(store, { name: "loginValue-store" }));

export default loginValueStore;
