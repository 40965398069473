import create from "zustand";
import { persist } from "zustand/middleware";

const store = (set) => ({
    socket: null,
    createSocket: socket => set({ socket }),
});

const socketStore = create(persist(store, { name: "socket-store" }));

export default socketStore;
