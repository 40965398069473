import { List, Avatar, Tag, Popconfirm } from 'antd'
import { BASE_PATH, DEMANDE_ROUTE, LISTE_USERS, } from '../utils/api.route'
import React, { useEffect, useState } from 'react'
import tokenStore from '../stores/tokenStore'
import { NotificationManager } from 'react-notifications'
import { FcOk } from 'react-icons/fc'
import { FiX } from 'react-icons/fi'
import { EuiInMemoryTable } from '@elastic/eui'
import moment from 'moment'


let debounceTimeoutId
let requestTimeoutId

const AdminDemande = () => {
    const [demandeLoading, setDemandeLoading] = useState(false)
    const [demandes, setDemandes] = useState([])
    const [items, setItems] = useState([])
    const token = tokenStore(state => state.token)



    const loadDemandes = () => {
        setDemandeLoading(true)
        fetch(DEMANDE_ROUTE, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + token
            }
        }).then(response => response.json())
            .then(result => {
                if (result.error)
                    throw new Error(result.message)
                setDemandeLoading(false)
                setDemandes(result.demandes)
                setItems(result.demandes)
            })
            .catch(err => {
                setDemandeLoading(false)
                NotificationManager.error(err.message)
            })
    }

    const handleChangeProfilConfirm = (item) => {
        fetch(
            DEMANDE_ROUTE
                .concat('/')
                .concat(item.id),
            {
                method: "put",
                body: JSON.stringify({ status: "ACCEPTER" }),
                headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token }
            }
        ).then(response => response.json())
            .then(result => {
                if (result.error)
                    throw new Error(result.message)
            })
            .catch(err => NotificationManager.error(err.message))


        fetch(
            LISTE_USERS
                .concat('/')
                .concat(item.user.userId).concat('/changeProfil'),
            {
                method: "put",
                body: JSON.stringify({ profil: "USER" }),
                headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token }
            }
        ).then(response => response.json())
            .then(result => {
                if (result.error)
                    throw new Error(result.message)
                NotificationManager.success("Demande bien traitée...")
                loadDemandes()

            })
            .catch(err => NotificationManager.error(err.message))
    }


    const getColumns = () => {

        const columns = []
        columns.push(
            {
                name: 'Utilisateur',
                field: 'user',
                render: value => <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div> 
                            <Avatar
                                size="large"
                                src={
                                    value?.userImage
                                        ? value?.userImage?.url
                                        : BASE_PATH.concat("/img/noProfile.jpg")
                                }
                            />
                        </div>
                        <div className='ml-2 fw-bold'>{value.username}</div>
                    </div>
                </div>
            },

            {
                name: 'Méssages',
                field: 'message'
            },
            {
                name: 'Status',
                field: 'status',
                render: value => (
                    <div>
                        {value}
                    </div>
                )
            },
            {
                name: 'Date',
                field: 'date',
                render: (value) => <div>{moment(value).format('DD MMM YYYY HH:mm:ss')} </div>
            },
            {
                name: 'Actions',
                field: 'action',
                render: value => <div className="d-flex">
                    <button>valider</button>
                </div>
            }
        )

        return columns

    }


    const onQueryChange = ({ query }) => {
        clearTimeout(debounceTimeoutId)
        clearTimeout(requestTimeoutId)

        debounceTimeoutId = setTimeout(() => {
            setDemandeLoading(true)

            requestTimeoutId = setTimeout(() => {
                let itemsResult = []

                itemsResult = demandes
                    .map((item, index) => ({
                        key: index,
                        id: item.id,
                    }))
                    .filter(dmd => {
                        const storeDict = `${dmd.id} ${dmd.date}`.toLowerCase()
                        const normalizedQuery = query.text.toLowerCase()
                        return storeDict.indexOf(normalizedQuery) !== -1
                    })

                setItems(itemsResult)
                setDemandeLoading(false)
            }, 1000)
        }, 300)
    }



    const search = {
        onchange: onQueryChange,
        // toolsRight: SectionFilter(),
        box: {
            incremental: true,
        },
    };

    const getDatas = () => {
        return items.map((item, index) => ({
            key: index,
            id: item.id,
            message: item.message,
            status: item.status,
            user: item.user,
            createdAt: item.createdAt,
        })) || []
    }

    const TableDemande = () => (
        <div className='rounded '>
            <EuiInMemoryTable
                tableCaption="Liste de commande"
                items={getDatas()}
                loading={demandeLoading}
                columns={getColumns()}
                search={search}
                pagination
                sorting={true}
            />
        </div>
    )


    useEffect(() => {
        loadDemandes()
    }, [])



    return (
        <div >
            <div className="my-1 p-2 page-title">List des demandes </div>
            <div className='mt-4'>
                {TableDemande()}
            </div>
        </div>
    )
}


export default AdminDemande