
import React from "react";

import { Route, Switch } from "react-router-dom";
import AdminLayout from './components/layouts/AdminLayout'
import NotFound from './pages/404'
import MainLayout from './components/layouts/MainLayout'
import Register from "./pages/Register";
import Login from "./pages/Login";
import HomeDetail from "./pages/HomeDetail";
import Home, { HomePageContent } from "./pages/Home";
import HomePanierCommande from "./pages/HomePanierCommande"
import HomeFavoris from "./pages/HomeFavoris";

const Routes = () => {
  return (
    <Switch>

      <Route path="/dashboard">
        <AdminLayout />
      </Route>

      <Route path="/detail">
        <Home>
          <HomeDetail />
        </Home>
      </Route>

      <Route path="/favoris">
        <Home>
          <HomeFavoris />
        </Home>
      </Route>

      <Route path="/register">
        <Register />
      </Route>

      <Route path="/login">
        <Login />
      </Route>

      <Route path="/panier">
        <Home>
          <HomePanierCommande />
        </Home>
      </Route>


      <Route exact path="/">
        <Home>
          <HomePageContent />
        </Home>
      </Route>

      <Route path="/notFound" >
        <MainLayout>
          <NotFound />
        </MainLayout>
      </Route>

    </Switch>
  );
};

export default Routes;
