import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import tokenStore from '../stores/tokenStore'
import { Route, useParams } from 'react-router'
import { LISTE_SERVICES } from '../utils/api.route'
import { AddService } from './AdminAdd'
import PageNotFound from './404'
import { NotificationManager } from 'react-notifications'
import AdminDetailUserAdministrateurPage from './AdminDetailUserAdministrateurPage'


const UpdateService = () => {
    const token = tokenStore(state => state.token)
    const [loading, setLoading] = useState(false)
    const [prestation, setPrestation] = useState(null)
    const { prestationId } = useParams()


    const loadDetailPrestation = () => {
        setLoading(true)
        fetch(LISTE_SERVICES.concat('/').concat(prestationId), { method: "get", headers: { 'content-type': 'application/json', 'Authorization': 'Bearer '.concat(token) } })
            .then(response => response.json())
            .then(result => {
                if (result.error)
                    throw new Error(result.message ? result.message : result.error)

                setPrestation(result.prestation)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                NotificationManager.error(err.message)
            })
    }


    useEffect(() => {
        prestationId && loadDetailPrestation()
    }, [])

    return loading ?
        <div>
            <Spin tip="loading..." />
        </div>
        : prestation ? <AddService parent={prestation} statut="UPDATE" /> : <PageNotFound />
}


const AdminDetails = () => {
    return <div>
        <Route path="/dashboard/detail/service/:prestationId" component={() => <UpdateService />} />
        <Route path="/dashboard/detail/profil/:userId" component={AdminDetailUserAdministrateurPage} />
    </div>
}
export default AdminDetails