import create from "zustand";
import { persist } from "zustand/middleware";

const store = (set) => ({
  categories: [],
  createCategories: categories => set({ categories }),
});

const categoriesStores = create(persist(store, { name: "categories-store" }));

export default categoriesStores;
