import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import meStore from "../stores/meStore";
import tokenStore from "../stores/tokenStore";
import { useHistory } from "react-router-dom";
import { Avatar, Spin, Rate, Popover } from "antd";

import { NotificationManager } from "react-notifications";
import { isValidToken } from "../utils/utilsFunction";
import "../pages/Home.css";
import { BASE_PATH, FAVORIS_ROUTE } from "../utils/api.route";

import { MdFavoriteBorder, MdFavorite } from "react-icons/md";
import { RED } from "../theme/couleurs";
import favorisStore from "../stores/favorisStore";
import { PRESTATION } from "../utils/constants";
import ConstantConfig from "../utils/constants.config";

const HomeCustumCard = ({
  service,
  loadFavoris,
  type = PRESTATION,
  hasFavoris = false,
  hasUserProfil = true,
  url = "/detail/service/",
}) => {
  const history = useHistory();
  const [loadingAddFavoris, setLoadingAddFavoris] = useState(false);
  const token = tokenStore((state) => state.token);
  const me = meStore((state) => state.me);
  const favoris = favorisStore((state) => state.favoris);
  const addFavoris = favorisStore((state) => state.addFavoris);

  const handleAddToFavoris = () => {
    if (token && service && isValidToken(token)) {
      setLoadingAddFavoris(true);
      return fetch(FAVORIS_ROUTE, {
        method: "post",
        body: JSON.stringify({ userId: me.id, prestationId: service.id }),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.error)
            throw new Error(
              result.message ? result.message : "Erreur d'ajout du Favoris"
            );
          setLoadingAddFavoris(false);
          addFavoris(favoris.concat(result.favoris));
        })
        .catch((err) => {
          setLoadingAddFavoris(false);
          NotificationManager.error(err.message);
        });
    }
    return history.push("/login")
  }

  const handleDeleteFavoris = () => {
    if (token && service && isValidToken(token)) {
      setLoadingAddFavoris(true);
      return fetch(FAVORIS_ROUTE, {
        method: "delete",
        body: JSON.stringify({ userId: me.id, prestationId: service.id }),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.error)
            throw new Error(
              result.message
                ? result.message
                : "Erreur de suppression de la Favoris"
            );
          setLoadingAddFavoris(false);
          loadFavoris && loadFavoris();
        })
        .catch((err) => {
          setLoadingAddFavoris(false);
          NotificationManager.error(err.message);
        });
    }
    return history.push("/login");
  };

  return (
    service && (
      <div
        key={service.id}
        className="card card-item my-shadow"
        style={{ minHeight: hasUserProfil ? "345px" : "330px" }}
      >
        <div className="position-relative">

          {type === PRESTATION && (

            <div
              className="prestation-card-image"
            >
              <img
                src={
                  service?.images?.length > 0 && service.images[0].url !== "" ? service.images[0].url : "".concat(ConstantConfig().BASE_PATH).concat("/img/noImage.png")
                }
                alt="card photos"
                style={{
                  height: '210px',
                  cursor: "pointer",
                  width: "100%",
                  objectFit: "cover",
                }}
                className="card-img"
                onClick={() => history.push(url.concat(service.id))}
              />
            </div>
          )}

          {hasFavoris && (
            <div
              className="position-absolute bg-white p-1 rounded-circle border shadow-sm text-center"
              style={{
                width: "35px",
                height: "35px",
                zIndex: 1,
                cursor: "pointer",
                bottom: -15,
                right: 5,
              }}
            >
              <Popover
                title="Favoris"
                content="Ajouter ou Supprimer Ce Favoris"
              >
                {loadingAddFavoris ? (
                  <Spin size="small" style={{ color: RED }} />
                ) : favoris.length !== 0 &&
                  favoris
                    .map((item) => item.prestation.id)
                    .includes(service.id) ? (
                  <MdFavorite
                    onClick={handleDeleteFavoris}
                    style={{ fontSize: "25px" }}
                    color={RED}
                  />
                ) : (
                  <MdFavoriteBorder
                    onClick={handleAddToFavoris}
                    style={{ fontSize: "25px" }}
                    color={RED}
                  />
                )}
              </Popover>
            </div>
          )}

          {service.hasIntervalCout && <div style={{ position: "absolute", top: 0, zIndex: 1 }}>
            <div
              style={{
                background: "#FFEA00",
                color: "#000",
                padding: "2px 5px",
                borderRadius: "25px",
                fontWeight: "bold",
                boxShadow: '0px 0px 2px 5px #FFEA0050',
                border: "1px solid #ccc"
              }}
            >
              {'A partir de ' + service.minCout + " "} {service.unite ? service.unite : ""}
            </div>
          </div>}
          {!service.hasIntervalCout && service.cout && <div style={{ position: "absolute", top: 0, zIndex: 1 }}>
            <div
              style={{
                background: "#80FFDB",
                color: "#000",
                padding: "2px 5px",
                borderRadius: "25px",
                fontWeight: "bold",
                boxShadow: '0px 0px 2px 5px #80FFDB50',
                border: "1px solid #ccc"
              }}
            >
              {service.cout + " "} {service.unite ? service.unite : ""}
            </div>
          </div>}

        </div>
        <div
          className="card-body px-3 py-1"
          style={{ cursor: "pointer" }}
          onClick={() => history.push(url.concat(service.id))}
        >
          <div className="d-flex align-items-center mt-1">
            <Rate value={service.note ? service.note : 0} disabled />
            <div className="card-text ml-3 text-muted">
              ({service.notes.length})
            </div>
            <div style={{ fontSize: "10px" }} className="ml-2 text-muted">évaluations</div>
          </div>

          <div
            onClick={() => history.push(url.concat(service.id))}
            style={{ cursor: "pointer", color: "#000000" }}
            className={"card-title mt-1".concat(hasUserProfil ? " text-truncate-two " : " text-truncate-tree ")}
          >
            {service.titre}
          </div>

          <div>
            {hasUserProfil && (
              <div className="d-flex align-items-center">
                <Avatar
                  src={
                    service.user &&
                      service.user.image &&
                      service.user.image.nom !== ""
                      ? service.user.image.url
                      : BASE_PATH.concat("/img/noProfile.jpg")
                  }
                />
                <div className="ml-3">
                  <div
                    className="text-truncate-one"
                  >
                    <span className="mr-2" style={{ fontSize: "10px" }}>
                      par
                    </span>
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {service.user?.nom + " " + service.user?.prenom}
                    </span>
                  </div>
                  <div>
                    <Rate
                      value={service.note ? service.note : 0}
                      disabled
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

        </div>
      </div>
    )
  );
};

export default HomeCustumCard;
