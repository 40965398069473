import create from 'zustand'
import { persist } from 'zustand/middleware'

const store = set => ({
   token: null,
   createToken: token => set({ token })
})

const tokenStore = create(persist(store, { name: 'token' }))

export default tokenStore
