import { Avatar, Button, InputNumber, Input, DatePicker, Tag, TimePicker, Image } from 'antd'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useHistory } from 'react-router'
import meStore from '../stores/meStore'
import panierStore from '../stores/panierStore'
import pathStore from '../stores/pathStore'
import tokenStore from '../stores/tokenStore'
import { COMMANDES_ROUTE } from '../utils/api.route'
import { compareTwoDate, isValidToken } from '../utils/utilsFunction'
import moment from 'moment'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime';
import { BLUE, GREEN, ORANGE, RED } from '../theme/couleurs'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { EuiDatePicker, EuiTextArea } from '@elastic/eui'
import 'dayjs/locale/fr'

dayjs.extend(relativeTime)

const HomePanierCommande = () => {
    const servicesPanier = panierStore(state => state.services)
    const addServices = panierStore(state => state.addServices)
    const history = useHistory()
    const me = meStore(state => state.me)
    const token = tokenStore(state => state.token)
    const [loading, setLoading] = useState(false)
    const createPathStore = pathStore(state => state.addCurrentPath)
    const [formDatas, setFormDatas] = useState([])
    const initialFormState = { id: null, detail: "", delaisLivraison: moment(), temp: null, date: null, qty: 1 }
    const [currentCommandeDescription, setCurrentCommandeDescription] = useState('')
    const [currentServiceItemSelected, setCurrentServiceItemSelected] = useState(null)
    const [currentServiceItemIndexSelected, setCurrentServiceItemIndexSelected] = useState(null)
    const [delaisLivraison, setDelaisLivraison] = useState(null)
    const [displaySuccessBox, setDisplaySuccessBox] = useState(false)


    const initialisedState = () => {
        setFormDatas(servicesPanier.map((item, index) => ({
            ...initialFormState,
            index,
            id: item.id,
            detail: item.detail?.trim()?.length > 0 ? item.detail : initialFormState.detail,
            delaisLivraison: item.delaisLivraison ? item.delaisLivraison : initialFormState.delaisLivraison,
            qty: item.qty > 0 ? item.qty : initialFormState.qty,
        })))
    }

    const handleOnClickCommande = () => {
        setDisplaySuccessBox(false)
        formDatas.forEach((item, indexForm) => {
            let newObject = { demandeurId: me?.id, quantiteDemander: item.qty }

            if (item.detail && item.detail.trim()?.length > 0) {
                newObject = { ...newObject, detail: item.detail }
            }

            if (item.delaisLivraison) {
                newObject = { ...newObject, suggestionDelaisLivraisonClient: moment(item.delaisLivraison).format('YYYY-MM-DD HH:mm:ss') }
            }

            createPathStore(history.location.pathname)
            if (!token || !isValidToken(token)) {
                return history.push("/login")
            }
            if (me?.nom === "" || me?.prenom === "" || me?.username === "" || me?.tels?.length === 0 || me?.email === "") {
                return history.push('/dashboard/compte');
            }

            servicesPanier.forEach((article, index) => {
                if (index === indexForm) {
                    setLoading(true)
                    return fetch(COMMANDES_ROUTE.concat('?variationId=').concat(article.id), {
                        method: 'post',
                        body: JSON.stringify({ ...newObject, prestataireId: article.user?.id }),
                        headers: {
                            'content-type': 'application/json',
                            Authorization: 'Bearer ' + token
                        }
                    })
                        .then(response => response.json())
                        .then(result => {
                            if (result.error)
                                throw new Error(result.message ? result.message : "Commande Numero: " + article.id + " non passé !");

                            if ((index + 1) === servicesPanier.length) {
                                addServices([])
                                setDisplaySuccessBox(true)
                            }
                        })
                        .catch(err => {
                            NotificationManager.error(err.message)
                            setLoading(false)
                            setDisplaySuccessBox(false)
                        })
                }
            })
        })
    }

    const handleAddDetails = () => {
        if (currentServiceItemSelected) {
            setFormDatas(formDatas.map((item, indexFormData) => {
                if (currentServiceItemIndexSelected === indexFormData && currentCommandeDescription?.trim()?.length > 0) {
                    return { ...item, detail: currentCommandeDescription }
                }
                return item
            }))

            document.getElementById("cancelDescriptionCommandeBTN")?.click()
        }
    }


    const handleQtyChange = (e, index) => {
        setFormDatas(formDatas.map((item, indexFormData) => {
            if (index === indexFormData) {
                return { ...item, qty: e }
            }
            return item
        }))
    }

    const handleAddTemp = () => {
        if (currentServiceItemSelected) {
            setFormDatas(formDatas.map((item, indexFormData) => {
                let element = item
                if (currentServiceItemIndexSelected === indexFormData && delaisLivraison) {
                    element = { ...element, delaisLivraison }
                }
                return element
            }))

            document.getElementById("cancelDelaisCommandeBTN")?.click()
        }
    }

    const handleGoToHome = () => {
        addServices([])

        setLoading(false)
        history.push('/')
    }

    const DisplaySuccessBox = () => (
        <div style={{ maxWidth: '500px', margin: '50px auto', textAlign: 'center', border: '1px solid '.concat(GREEN), padding: '30px', background: GREEN.concat(20) }}>
            <div>
                <BsFillCheckCircleFill style={{ fontSize: '50px', color: GREEN }} />
            </div>
            <div className='mt-3' style={{ fontSize: '18px', fontWeight: 'bold' }}>
                Commandes éffectuées avec succès
            </div>
            <div className='mt-4'>
                <button style={{ color: '#fff', background: GREEN, fontWeight: 'bold', padding: '10px 30px' }} onClick={handleGoToHome}>Retourner à la page d'accueil</button>
            </div>
        </div>
    )

    const handleDeleteServicePanier = (item) => {
        addServices(servicesPanier.filter(s => s.id !== item.id))
    }

    const DisplayCommandDescriptionModal = () => (
        <div id="descriptionModal" className="modal fade" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title" style={{ fontSize: "16px", fontWeight: "bold" }}>Description</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" style={{ fontSize: "18px" }}>&times; </span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <div className="my-1">Description</div>
                            <EuiTextArea placeholder='description' fullWidth rows={2} value={currentCommandeDescription} onChange={e => setCurrentCommandeDescription(e.target.value)} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            id="cancelDescriptionCommandeBTN"
                            data-bs-dismiss="modal" style={{ border: "none", background: RED, color: "#fff", padding: "3px 6px", fontWeight: "bold" }}
                        >Annuler</button>
                        <button
                            onClick={handleAddDetails}
                            style={{ border: "none", background: GREEN, color: "#fff", padding: "3px 6px", fontWeight: "bold" }}>Enrégistrer</button>
                    </div>
                </div>
            </div>
        </div>
    );

    const DisplayCommandDelaisModal = () => (
        <div id="delaisLivraisonModal" className="modal fade" tabIndex="-1" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title" style={{ fontSize: "16px", fontWeight: "bold" }}>Délais de livraison</div>
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" style={{ fontSize: "18px" }}>&times; </span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div>
                            Delais de livraison
                        </div>
                        <EuiDatePicker
                            fullWidth
                            showTimeSelect
                            selected={delaisLivraison}
                            onChange={date => setDelaisLivraison(date)}
                            placeholder="Délais de livraison"
                            dateFormat="YYYY-MM-DD HH:mm:ss"
                            filterDate={date => compareTwoDate(date, currentServiceItemSelected?.delaisJoursLivraison ? currentServiceItemSelected?.delaisJoursLivraison : 1)}
                        />
                    </div>
                    <div className="modal-footer">
                        <button id="cancelDelaisCommandeBTN" data-bs-dismiss="modal" style={{ border: "none", background: RED, color: "#fff", padding: "3px 6px", fontWeight: "bold" }}>Annuler</button>
                        <button
                            onClick={handleAddTemp}
                            style={{ border: "none", background: GREEN, color: "#fff", padding: "3px 8px", fontWeight: "bold" }}
                        >Enrégistrer</button>
                    </div>
                </div>
            </div>
        </div>
    )


    const handleCommandeDelaisLivraisonClick = (item, index) => {
        setCurrentServiceItemSelected(item)
        setCurrentServiceItemIndexSelected(index)
        setDelaisLivraison(moment(formDatas[index]?.delaisLivraison))
    }

    const handleCommandeDescriptionClick = (item, index) => {
        setCurrentServiceItemSelected(item)
        setCurrentServiceItemIndexSelected(index)
        setCurrentCommandeDescription(formDatas[index]?.detail)
    }

    const DisplayContent = () => (
        <div className='col-lg-9'>
            <div className='bg-white border mt-2 p-2'>
                {servicesPanier?.length === 0 && (
                    <div className="mt-4 p-2 d-flex flex-column justify-content-center align-items-center ">
                        <div><AiOutlineShoppingCart style={{ fontSize: "100px" }} /></div>
                        <div className="mt-3 " style={{ fontSize: "20px", fontWeight: "bold" }}>Le panier est vide ! </div>
                    </div>
                )}

                {servicesPanier.length > 0 && (
                    <div style={{ overflowX: "auto", }}>
                        <table style={{ minWidth: "1200px" }}>
                            <thead>
                                <tr>
                                    <th style={{ fontSize: "14px", fontWeight: "bold", padding: "5px" }}>Détails Service</th>
                                    <th style={{ fontSize: "14px", fontWeight: "bold", padding: "5px" }}>Prix unitaire</th>
                                    <th style={{ fontSize: "14px", fontWeight: "bold", padding: "5px" }}>Quantité commandée</th>
                                    <th style={{ fontSize: "14px", fontWeight: "bold", padding: "5px" }}>Prix total</th>
                                    <th style={{ fontSize: "14px", fontWeight: "bold", padding: "5px" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    servicesPanier.length > 0 && servicesPanier.map((item, index) => (<tr key={index} style={{ borderTop: "1px solid #ccc" }}>
                                        {
                                            item.images.length > 0 && (<td style={{ padding: "10px", verticalAlign: "top" }}>
                                                <div className='d-flex'>
                                                    <Image style={{ width: "100%", minWidth: "200px", maxWidth: "200px", maxHeight: "130px", height: "100%", objectFit: "cover" }} src={item.images[0].url} alt="item" />
                                                    <div style={{ marginLeft: "20px" }}>
                                                        <div className="font-weight-bold text-truncate-two">{item.titre} </div>
                                                        <div className="text-truncate-tree mt-1">{item.description} </div>
                                                        <div className='mt-2'>
                                                            <Avatar size="small" src={item.user?.userImage ? item.user?.userImage?.url : '/img/noProfile.jpg'} />
                                                            <span> {item.user?.username} </span>
                                                        </div>
                                                        <div className='mt-2 d-flex align-items-center flex-wrap'>
                                                            <button
                                                                style={{
                                                                    marginRight: "10px",
                                                                    marginTop: "5px",
                                                                    background: GREEN,
                                                                    color: "#fff",
                                                                    border: "none",
                                                                    padding: "5px 10px",
                                                                    cursor: "pointer",
                                                                    outline: "none",
                                                                    fontWeight: "bold",
                                                                }}
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#descriptionModal"
                                                                onClick={() => handleCommandeDescriptionClick(item, index)}
                                                            >
                                                                {
                                                                    formDatas[index]?.detail && formDatas[index]?.detail?.trim()?.length > 0 ?
                                                                        "Voir la description"
                                                                        : "Ajouter une description"
                                                                }
                                                            </button>
                                                            <button
                                                                style={{
                                                                    marginRight: "10px",
                                                                    marginTop: "5px",
                                                                    color: "#fff",
                                                                    background: BLUE,
                                                                    fontWeight: "bold",
                                                                    border: "none",
                                                                    padding: "5px 10px",
                                                                    cursor: "pointer",
                                                                    outline: "none"
                                                                }}
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#delaisLivraisonModal"
                                                                onClick={() => handleCommandeDelaisLivraisonClick(item, index)}
                                                            >
                                                                {
                                                                    formDatas[index]?.delaisLivraison ?
                                                                        "Voir délai de livraison"
                                                                        : "Ajouter délais de livraison"
                                                                }
                                                            </button>
                                                            <button
                                                                style={{
                                                                    marginTop: "5px",
                                                                    color: "#fff",
                                                                    background: RED,
                                                                    fontWeight: "bold",
                                                                    border: "none",
                                                                    padding: "5px 10px",
                                                                    cursor: "pointer",
                                                                    outline: "none"
                                                                }}

                                                                onClick={() => handleDeleteServicePanier(item)}
                                                            >
                                                                Supprimer
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            )
                                        }
                                        <td style={{ padding: "10px", verticalAlign: "top" }}>
                                            <div>
                                                {"".concat(item?.cout).concat(' ').concat(item?.unite?.toLowerCase())}
                                            </div>
                                        </td>
                                        <td style={{ padding: "10px", verticalAlign: "top" }}>
                                            <div>
                                                <InputNumber onChange={e => handleQtyChange(e, index)} value={formDatas[index]?.qty} />
                                            </div>
                                        </td>
                                        <td style={{ padding: "10px", verticalAlign: "top" }}>
                                            <Tag color="orange" style={{ fontWeight: "bold" }}>
                                                {item?.cout * formDatas[index]?.qty} {item?.unite?.toLowerCase()}
                                            </Tag>
                                        </td>
                                    </tr>))
                                }
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    )

    const getTotalPrice = () => {
        let total_price = servicesPanier.reduce((prev, curr, index) => {

            let currentPrice = 0
            if (curr.cout) {
                currentPrice = formDatas[index]?.qty > 0 ? curr.cout * formDatas[index].qty : curr.cout
            }

            return prev + currentPrice
        }, 0)
        return total_price
    }

    const DisplayTotalPrix = () => (
        <div className='col-lg-3 mt-2'>
            <div className='bg-white p-4 rounded' style={{ borderTop: `2px solid ${ORANGE}`, borderLeft: "1px solid #ccc", borderRight: "1px solid #ccc", borderBottom: "1px solid #ccc" }}>
                <div style={{ fontSize: "18px", fontWeight: "bold" }}>Totaux du panier</div>
                <div className='mt-3'>
                    <span style={{ color: "#00000090" }}>Services Totals:</span>
                    <span style={{ fontWeight: "bold", marginLeft: "10px" }}>{servicesPanier.length} ajouté(s) </span>
                </div>
                <div className='mt-3'>
                    <span style={{ color: "#00000090" }}>Prix Totals:</span>
                    <span style={{ fontWeight: "bold", marginLeft: "10px", fontSize: "18px", color: ORANGE }}>{getTotalPrice()} FCFA</span>
                </div>
                <hr className='my-4' />
                <div className='text-center'>
                    <Button style={{ background: ORANGE, outline: "none", fontWeight: "bold", border: "none", color: "#fff", padding: "0px 50px" }} loading={loading} onClick={handleOnClickCommande} disabled={servicesPanier?.length === 0}>Commander maintenant </Button>
                </div>
            </div>
        </div>
    )

    const DisplayTitle = () => (
        <div>
            <div style={{ fontSize: "18px", fontWeight: "bold", color: ORANGE, textDecoration: "underline" }}> Panier </div>
            <div className='mt-2'> Retrouver tous les services ajoutés à votre panier. Vous pouvez les éditer , les modifier, les retirer du panier avant de passer la commande</div>
        </div>
    )

    useEffect(() => {
        servicesPanier.length > 0 && initialisedState()
    }, [])

    return (
        <div className="my-container" style={{ marginTop: "20px", minHeight: "90vh" }}>
            <div className='row'>
                <div className="col-md-12 p-0">
                    {DisplayTitle()}
                    {displaySuccessBox && DisplaySuccessBox()}
                    {
                        !displaySuccessBox && (
                            <div className='row mt-4'>
                                {DisplayContent()}
                                {DisplayTotalPrix()}
                            </div>
                        )
                    }
                </div>
            </div>
            {DisplayCommandDescriptionModal()}
            {DisplayCommandDelaisModal()}
        </div>
    )
}

export default HomePanierCommande