import { Button, Form, Input, Radio, Select } from "antd"
import { useEffect, useState } from "react"
import { useHistory, Link, Route, Switch, Redirect } from "react-router-dom"
import tokenStore from '../stores/tokenStore'
import { NotificationManager } from 'react-notifications'
import { LISTE_INVITE_CATEGORIES, RESEND_CODE_ROUTE, SIGNIN_ROUTE, SIGNUP_ROUTE, VALIDATE_EMAIL_USER } from "../utils/api.route"
import meStore from "../stores/meStore"
import { refreshMe } from "../utils/utilsFunction"
import { BLUE, GREEN, ORANGE, RED } from "../theme/couleurs"
import moment from "moment"
import { APP_NAME, BUSNESS, CLIENT, OWNER, SELLER } from "../utils/constants"
import PhoneInput from "react-phone-input-2"
import categoriesStores from "../stores/categoriesStore"
import loginValueStore from "../stores/loginValue"
import { FcApproval } from 'react-icons/fc'


const Register = ({ style }) => {
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [userId, setUserId] = useState(null)
  const createToken = tokenStore(state => state.createToken)
  const setLoginValue = loginValueStore(state => state.setLoginValue)
  const loginValue = loginValueStore(state => state.loginValue)
  const createMe = meStore(state => state.createMe)
  const [errorMessage, setErrorMessage] = useState(null)
  const [accountType, setAccountType] = useState(CLIENT)
  const [sellerAccountType, setSellerAccountType] = useState(OWNER)
  const categories = categoriesStores(state => state.categories)
  const setCategories = categoriesStores(state => state.createCategories)

  const [resendErrorMessage, setResendErrorMessage] = useState(null)
  const [visibleSuccessSend, setVisibleSuccessSend] = useState(false)

  useEffect(() => {
    loadCategories()
  }, [])


  const loadCategories = async () => {
    try {
      const request = await fetch(LISTE_INVITE_CATEGORIES)
      const response = await request.json()
      if (response.status === "ERROR")
        throw response

      setCategories(response)
    } catch (err) {

    }
  }

  const onHandleSubmit = (data) => {
    setLoading(true)

    if (data.password && !isPasswordValid(data.password)) {
      setLoading(false)
      return form.setFields([{ name: 'password', errors: ["Mot de passe très faible"] }])
    }


    if (data.mdpConfirm && data.mdpConfirm !== data.password) {
      setLoading(false)
      return form.setFields([{ name: 'mdpConfirm', errors: ["Mot de passe incorrect"] }])
    }

    let payload = {
      ...data,
      accountType,
      isBusiness: sellerAccountType === BUSNESS ? true : false
    }

    if (data.tels) {
      payload = { ...payload, tels: [data.tels] }
    }

    setErrorMessage(null)
    fetch(SIGNUP_ROUTE.concat('?accountType=').concat(accountType), {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((datas) => {
        if (datas.error)
          throw new Error(datas.message ? datas.message : datas.error)

        setLoading(false)
        setUserId(datas)
        setLoginValue({ userId: datas, username: data.username, password: data.password })
        history.push("/register/verification")
        setErrorMessage(null)
      })
      .catch((err) => {
        setLoading(false)
        setUserId(null)
        setErrorMessage(err.message)
      })
  }

  const onVerificationFinish = (value) => {
    setLoading(true)
    setErrorMessage(null)
    fetch(VALIDATE_EMAIL_USER.concat('/').concat(userId), {
      method: "put",
      body: JSON.stringify({ code: parseInt(value.code) }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then(response => response.json())
      .then(datas => {
        if (datas.error)
          throw new Error(datas.message ? datas.message : datas.error)

        setLoading(false)
        NotificationManager.success("Inscription réuissi ! ")
        setErrorMessage(null)
        onHandleLogin(loginValue)
      })
      .catch((err) => {
        setLoading(false)
        setErrorMessage(err.message)
      })
  }

  const onHandleLogin = (data) => {
    setLoading(true)
    setErrorMessage(null)
    createToken(null)
    fetch(SIGNIN_ROUTE, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error)
          throw new Error(response.message ? response.message : response.error)

        if (response.access_token) {
          createToken(response.access_token)
          refreshMe(createMe, response.access_token)
          setErrorMessage(null)
          setLoading(false)
          setLoginValue(null)
          history.push("/")
        }

      })
      .catch((err) => {
        setLoading(false)
        createToken(null)
        setErrorMessage(err.message)
      })
  }

  const validateConfirmationPassword = event => {
    if (event.target.value) {
      if (form.getFieldValue('mdpConfirm') !== form.getFieldValue('password')) {
        form.setFields([{ name: 'mdpConfirm', errors: ["Mot de passe incorrect"], validating: false }])
      } else {
        form.setFields([{ name: 'mdpConfirm', errors: null, validating: true }])
      }
      // setConfirmMDP("".concat(event.target))
    }
  }

  const validatePassword = input => {

    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{5,}$/

    if (!regex.test(input)) {
      form.setFields([{ name: 'password', validating: true, errors: ["Mot de passe très faible "] }])
    } else {
      form.setFields([{ name: 'password', validating: true, errors: null }])
    }

  }

  const isPasswordValid = input => {
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{5,}$/
    return regex.test(input)
  }

  const RenderClientSection = () => (
    <>
      <Form
        form={form}
        style={{ marginTop: 20 }}
        layout="vertical"
        onFinish={onHandleSubmit}
      >
        <div className="row">
          <div className="col-md-6">
            <Form.Item
              name="nom"
              label={<span>Nom</span>}
              rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
            >
              <Input placeholder="Votre nom" size="large" />
            </Form.Item>

          </div>
          <div className="col-md-6">
            <Form.Item
              name="prenom"
              label={<span>Prénom(s)</span>}
            >

              <Input placeholder="Votre prénom(s)" size="large" />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              name="email"
              label={<span>Email</span>}
              rules={[{ required: true, message: "Ce champ est obligatoire", type: "email" }]}
            >
              <Input placeholder="Votre adresse email" size="large" />
            </Form.Item>

          </div>
          <div className="col-md-6">
            <Form.Item
              name="tels"
              label={<span>Téléphone</span>}
              rules={[{ required: true, message: "Ce champ est obligatoire" }]}
            >
              <PhoneInput
                country={'tg'}
                inputStyle={{ maxWidth: "100%" }}
              />
            </Form.Item>
          </div>

          <div className="col-md-12 mt-3">
            <Form.Item
              name="username"
              label={<span>Nom d'utilisateur </span>}
              rules={[{ required: true, message: "Ce champ est obligatoire" }]}
            >
              <Input placeholder="Votre nom d'utilisateur" size="large" />
            </Form.Item>
          </div>
          <div className="col-md-12">
            <div style={{ background: '#EEE', padding: "10px", borderRadius: '10px' }}>
              <div style={{ fontWeight: "bold" }}>Mot de passe</div>
              <div>
                1 - Doit contenir au moins une lettre Majuscule
              </div>
              <div>
                2 - Doit contenir au moins 1 caractère spéciale
              </div>
              <div>
                3 - Doit contenir au moins 1 chiffre
              </div>
              <div>
                3 - Doit dépasser au moins 5 caractères
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <Form.Item
              name="password"
              label={<span>Mot de passe</span>}
              rules={[{ required: true, message: "Ce champ est obligatoire" }]}
            >
              <Input.Password onChange={_ => validatePassword(form.getFieldValue('password'))} placeholder="Votre mot de passe" size="large" />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              name="mdpConfirm"
              label={<span>Confirmation du Mot de passe</span>}
              rules={[{ required: true, message: "Ce champ est obligatoire" }]}
            >
              <Input.Password onChange={validateConfirmationPassword} placeholder="Confirmation" size="large" />
            </Form.Item>
          </div>

          <div className="col-md-12 mt-2">
            <Button
              style={{ width: "100%", color: "#fff", border: "none", background: ORANGE, fontWeight: "bold" }}
              htmlType="submit"
              loading={loading}
            >
              s'inscrire
            </Button>
          </div>
        </div>
      </Form>
    </>
  )

  const RenderSellerSection = () => (
    <>
      <Form
        form={form}
        style={{ marginTop: 20 }}
        layout="vertical"
        onFinish={onHandleSubmit}
      >
        <div className="row">
          <div className="col-md-12 fw-bold">
            Informations personnel
          </div>
          <div className="col-md-6">
            <Form.Item
              name="nom"
              label={<span>Nom</span>}
            >
              <Input placeholder="Votre nom" size="large" />
            </Form.Item>

          </div>
          <div className="col-md-6">
            <Form.Item
              name="prenom"
              label={<span>Prénom(s)</span>}
            >

              <Input placeholder="Votre prénom(s)" size="large" />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              name="email"
              label={<span>Email</span>}
              rules={[{ required: true, message: "Ce champ est obligatoire", type: "email" }]}
            >
              <Input placeholder="Votre adresse email" size="large" />
            </Form.Item>

          </div>
          <div className="col-md-6">
            <Form.Item
              name="tels"
              label={<span>Téléphone</span>}
              rules={[{ required: true, message: "Ce champ est obligatoire" }]}
            >
              <PhoneInput
                inputStyle={{ width: "100%" }}
                country={'tg'}
              />
            </Form.Item>
          </div>

          <div className="col-md-12 fw-bold">
            Catégories
          </div>
          <div className="col-md-12">
            <div className="my-2">Sélectionner les catégories dans lesquelles vous proposez vos prestations de service.</div>
            <Form.Item
              name="competences"
              label={<span>Catégories</span>}
              rules={[{ required: true, message: "Donner quelque détails sur ce que vous faite" }]}
            >
              <Select mode="multiple" placeholder="Catégories" size="large">
                {categories.filter(cat => cat.parentId).map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>{cat.nom}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-md-12">
            <Form.Item
              name="precisionCompetence"
              label={<span>Résumé de ce que vous faites dans ses catégories</span>}
              rules={[{ required: true, message: "Résumé de ce que vous faites dans ses catégories" }]}
            >
              <Input.TextArea placeholder="Donner un petit résumé de ce que vous faite dans ses catégories" size="small" />
            </Form.Item>
          </div>


          {sellerAccountType === BUSNESS && (
            <div className="row my-1">

              <div className="col-md-12 my-2 fw-bold">
                Informations sur l'entreprise
              </div>

              <div className="col-md-6">
                <Form.Item
                  name="nomEntreprise"
                  label={<span>Nom de la société ( obligatoire ) </span>}
                  rules={[{ required: true, message: "Ce champ est obligatoire" }]}
                >
                  <Input placeholder="Nom de la société" size="large" />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="emailEntreprise"
                  label={<span>Email de l'entreprise </span>}
                >
                  <Input placeholder="Email de l'entreprise" type="email" size="large" />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name=""
                  label={<span>Numéro de téléphone </span>}
                >
                  <PhoneInput
                    country={'tg'}
                    inputStyle={{ maxWidth: "100%" }}
                    placeholder="Numéro de téléphone de l'entreprise"
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="adresseEntreprise"
                  label={<span>Adresse </span>}
                >
                  <Input placeholder="Adresse de l'entreprise" size="large" />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="immatriculeEntreprise"
                  label={<span>Numéro d'immatriculation</span>}
                >
                  <Input placeholder="Immatriculation de l'entreprise" size="large" />

                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="tvaEntreprise"
                  label={<span>Numéro TVA</span>}
                >
                  <Input placeholder="TVA" size="large" />
                </Form.Item>
              </div>
            </div>
          )}

          <div className="col-md-12 my-2 fw-bold">
            Informations de connexion
          </div>

          <div className="col-md-12">
            <Form.Item
              name="username"
              label={<span>Nom d'utilisateur</span>}
              rules={[{ required: true, message: "Ce champ est obligatoire" }]}
            >
              <Input placeholder="Votre nom d'utilisateur" size="large" />
            </Form.Item>
          </div>
          <div className="col-md-12">
            <div style={{ background: '#EEE', padding: "10px", borderRadius: '10px' }}>
              <div style={{ fontWeight: "bold" }}>Mot de passe</div>
              <div>
                1 - Doit commencer par une lettre Majuscule
              </div>
              <div>
                2 - Doit dépasser au moins 1 chiffre
              </div>
              <div>
                3 - Doit dépasser au moins 5 caractères
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <Form.Item
              name="password"
              label={<span>Mot de passe</span>}
              rules={[{ required: true, message: "Ce champ est obligatoire" }]}
            >

              <Input.Password onChange={_ => validatePassword(form.getFieldValue('password'))} placeholder="Mot de passe" size="large" />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              name="mdpConfirm"
              label={<span>Confirmation du Mot de passe</span>}
              rules={[{ required: true, message: "Ce champ est obligatoire" }]}
            >
              <Input.Password onChange={validateConfirmationPassword} placeholder="Confirmation" size="large" />
            </Form.Item>
          </div>
          <div className="col-md-12">
            <Button
              style={{ width: "100%", color: "#fff", border: "none", background: RED, fontWeight: "bold" }}
              htmlType="submit"
              loading={loading}
            >
              s'inscrire
            </Button>
          </div>
        </div>

      </Form>
    </>
  )

  const handleTypeAccountChange = event => setAccountType(event.target.value)
  const handleSellerTypeAccountChange = event => setSellerAccountType(event.target.value)

  const Inscription = () => (
    <div>
      <div className="text-center font-weight-bold" style={{ color: ORANGE, fontSize: "18px" }} >
        Inscription
      </div>
      <div className="my-4 d-flex fw-bold justify-content-center">
        <Radio.Group onChange={handleTypeAccountChange} value={accountType} >
          <Radio value={CLIENT} ><span style={{ fontSize: "15px", fontWeight: "bold" }}>Compte CLIENT</span></Radio>
          <Radio value={SELLER}><span style={{ fontSize: "15px", fontWeight: "bold" }}>Compte VENDEUR</span></Radio>
        </Radio.Group>
      </div>

      {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
      <div
        className="alert p-1 border rounded"
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "12px",
          background: BLUE.concat(20),
          color: "#000",
        }}
      >
        {accountType === CLIENT && <span> Le compte CLIENT vous permet de passé des commandes , de pouvoir noter et donner vos avis sur les prestataires.</span>}
        {accountType === SELLER && <span> Le compte vendeur vous permet de devenir un prestataire sur la plateforme , de proposer des prestations de services , de reçevoir des commandes, d'être noté selon la qualité ou le travail effectué pour vos clients.</span>}
      </div>

      {accountType === SELLER && (
        <div
          style={{
            margin: "20px 0px"
          }}>
          <Radio.Group onChange={handleSellerTypeAccountChange} value={sellerAccountType} >
            <Radio value={OWNER} ><span style={{ fontSize: "15px", fontWeight: "bold" }}>Je suis un particulier</span></Radio>
            <Radio value={BUSNESS}><span style={{ fontSize: "15px", fontWeight: "bold" }}>Je suis une entreprise</span></Radio>
          </Radio.Group>
        </div>
      )}

      {accountType === CLIENT && RenderClientSection()}
      {accountType === SELLER && RenderSellerSection()}
      <div className="mt-2">
        <p>
          Vous avez déjà un compte ? <Link style={{ fontWeight: "bold", marginLeft: "10px" }} to="/login">Me connecter</Link>
        </p>
      </div>
    </div>
  )

  const handleResend = async () => {
    try {
      setResendErrorMessage(null)
      setVisibleSuccessSend(false)

      const payload = { userId: loginValue?.userId }
      const request = await fetch(RESEND_CODE_ROUTE, {
        method: "post",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      })

      const response = await request.json()

      if (response.error)
        throw response

      setVisibleSuccessSend(true)
    } catch (err) {

      setVisibleSuccessSend(false)
      setResendErrorMessage(err.message)
    }
  }

  const Verification = () => !loginValue ? (
    <Redirect to="/" />
  ) : (
    <div>
      <div className="text-center font-weight-bold" style={{ color: ORANGE, fontSize: "18px" }} >
        Inscription
      </div>    {errorMessage && <p className="alert alert-danger"> {errorMessage} </p>}

      {resendErrorMessage ? (
        <div style={{ background: RED.concat(50), fontWeight: "bold", borderRadius: 10, padding: '10px' }} className="my-3">
          {resendErrorMessage}
        </div>
      ) : (
        <div style={{ background: "#08979c30", borderRadius: 10, padding: '10px' }} className="my-3">
          Nous vous avons envoyé un code de vérification dans votre boite mail.
          Si vous ne l'avez pas récus , vous pouvez toute fois re-cliquer sur
          envoyé pour ré-envoyer le code!
        </div>
      )}

      <div style={{ marginTop: 10 }}>
        <Form onFinish={onVerificationFinish} layout="vertical" form={form}>
          <Form.Item
            rules={[{ required: true, message: "Entrer le code de vérification" }]}
            label="Entrer le code de vérification"
            name="code"
          >
            <Input type="number" size="large" placeholder="Entrer le code de vérification" />
          </Form.Item>
          <div>Vous n'avez pas reçu le code ? . <a href="#" onClick={handleResend} style={{ color: ORANGE, fontWeight: "bold" }}>ré-envoyer ? </a>
            {
              visibleSuccessSend && (
                <span className="ms-3"> <FcApproval style={{ fontSize: "20px", color: GREEN }} /></span>
              )
            }
          </div>
          <div className="my-3">
            <Button
              style={{ width: "100%", color: "#fff", border: "none", background: ORANGE, fontWeight: "bold" }}
              htmlType="submit"
              loading={loading}
            >
              Envoyer
            </Button>
          </div>
        </Form>

      </div>
    </div>
  )



  return (
    <div>
      <div
        style={{
          textAlign: "center",
          marginTop: 80,
        }}
        onClick={() => history.push("/")}
      >
        <Link to="/" >
          <div style={{ fontSize: 20, fontWeight: "bold", color: "#000000" }}>
            {APP_NAME}
          </div>
        </Link>
      </div>

      <div
        style={{
          maxWidth: 500,
          marginTop: 10,
          marginRight: "auto",
          background: "#fff",
          marginLeft: "auto",
          padding: "20px",
          ...style,
        }}
        className="border my-shadow rounded"
      >
        <Switch>
          <Route exact path="/register" component={Inscription} />
          <Route path="/register/verification" component={Verification} />
        </Switch>

        <hr />
        <p className="my-2" style={{ textAlign: "center", color: "#00000080" }}>© {moment().format('YYYY')} Copyright HISPWCA</p>
      </div>
    </div>
  )
}

export default Register
