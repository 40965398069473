import create from "zustand";
import { persist } from "zustand/middleware";

const store = (set) => ({
    currentPath: null,
    addCurrentPath: currentPath => set({ currentPath }),
});

const pathStore = create(persist(store, { name: "currentPath-store" }));

export default pathStore;
