import create from "zustand";
import { ALL } from "../utils/constants";

const store = (set) => ({
  selectedCategorie: [{ value: ALL, label: "Toutes les catégories" }],
  setSelectedCategorie: (selectedCategorie) => set({ selectedCategorie }),
});

const currentCategorieStore = create(
  store
);

export default currentCategorieStore;
