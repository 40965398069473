import { DeleteOutlined, EyeInvisibleOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Switch, Table, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router';
import DeleteConfirmationPopup from '../components/DeleteConfirmationPopup';
import MyPagination from '../components/MyPagination';
import Popovers from '../components/Popover';
import tokenStore from '../stores/tokenStore';
import { GREEN, ORANGE } from '../theme/couleurs';
import { BASE_PATH, LISTE_USERS } from '../utils/api.route';


const AdminUser = () => {
    const token = tokenStore(state => state.token);
    const [loading, setLoading] = useState(true);
    const [users, createUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const pageSize = 4;
    const history = useHistory()

    const handleDeleteUser = (id) => {
        setLoading(true)
        fetch(LISTE_USERS.concat('/').concat(id), {
            method: "delete",
            headers: {
                "content-type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) throw new Error(data.message);

                NotificationManager.success("Suppression réussie...", null, 3000);
                loadUsers()
                return setLoading(false);
            })
            .catch((err) => {
                setLoading(false)
                NotificationManager.error(err.message, null, 3000)
            }
            )
    }

    const loadUsers = () => {
        setLoading(true)

        fetch(LISTE_USERS.concat('?page=').concat(page).concat("&sort=id,DESC"), {
            method: "get",
            headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then(result => result.json())
            .then((response) => {
                if (response.error)
                    throw new Error(response.message ? response.message : "erreur ")
                createUsers(response.content);
                setLoading(false);
                setTotalElements(response.totalElements);
                setTotalPages(response.totalPages);
            })
            .catch((err) => {
                setLoading(false);
                NotificationManager.error(err.message);
            })
    }

    useEffect(() => {
        loadUsers()
    }, [page])

    const Results = () => {
        const token = tokenStore(state => state.token);

        // const handleActiveProfil = (id, active) => {
        //     fetch(LISTE_USERS.concat('/').concat(id).concat("/activeProfil"), {
        //         method: "put",
        //         body: active,
        //         headers: {
        //             "content-type": "application/json",
        //             Authorization: "Bearer " + token,
        //         },
        //     })
        //         .then((response) => response.json())
        //         .then((data) => {
        //             if (data.error) throw new Error(data.message ? data.message : "Erreur d'activation de profil");

        //             NotificationManager.success(
        //                 "Le status du profil est maintenant => " + data,
        //                 null,
        //                 3000
        //             );
        //         })
        //         .catch((err) => {
        //             NotificationManager.error(err.message);
        //         });
        // };

        const handleActiveAccount = (id, active) => {
            fetch(LISTE_USERS.concat('/').concat(id).concat("/activeAccount"), {
                method: "put",
                body: active,
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) throw new Error(data.message ? data.message : "Erreur d'activation de compte");
                    NotificationManager.success(
                        "Le status du compte est maintenant => " + data,
                        null,
                        3000
                    );
                })
                .catch((err) => {
                    NotificationManager.error(err.message);
                });
        };

        const handleBoostedProfil = (id, active) => {
            fetch(LISTE_USERS.concat('/').concat(id).concat("/boosted"), {
                method: "put",
                body: active,
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) throw new Error(data.message);

                    NotificationManager.success(
                        "Le status boost du profile est maintenant: " + active,
                        null,
                        3000
                    );
                })
                .catch((err) => {
                    NotificationManager.error(err.message);
                });
        };

        const columns = [
            {
                key: "id",
                dataIndex: "id",
                title: (
                    <div>
                        ID
                    </div>
                ),
                width: 70,
                render: (id) => <p>{id}</p>,
            },
            {
                key: "image",
                title: (
                    <h4>
                        Image
                    </h4>
                ),
                dataIndex: "image",
                render: (value) => (
                    <div style={{ "display": "flex", alignItems: "center" }}>
                        <p>
                            <Avatar src={value?.userImage ? value?.userImage?.url : BASE_PATH.concat("/img/noProfile.jpg")} size="small" />
                        </p>
                        <p className="px-1">
                            {value?.username}
                        </p>
                    </div>
                ),
            },
            {
                key: "nom",
                title: (
                    <h4>
                        Nom
                    </h4>
                ),
                dataIndex: "nom",
                render: (value) => <p>{value}</p>,
            },
            {
                key: "prenom",
                title: (
                    <h4>
                        Prenom
                    </h4>
                ),
                dataIndex: "prenom",
                render: (value) => <p>{value}</p>,
            },
            {
                key: "profil",
                title: (
                    <h4>
                        Profil
                    </h4>
                ),
                dataIndex: "profil",
                render: (value) => <>
                    {value === "ADMIN" && <Tag color={GREEN}>{value}</Tag>}
                    {value === "VISITEUR" && <Tag color={ORANGE}>{value}</Tag>}
                    {value === "USER" && <Tag color="blue">{value}</Tag>}
                </>,
            },
            {
                key: "accountActive",
                title: (
                    <h4>
                        Status du compte
                    </h4>
                ),
                dataIndex: "accountActive",
                render: (value) => (
                    <Switch
                        defaultChecked={value?.accountActive}
                        size="small"
                        onChange={(active) => {
                            handleActiveAccount(value?.id, active);
                        }}
                    />
                ),
            },
            {
                key: "boosted",
                title: (
                    <h4>
                        Booster le profil
                    </h4>
                ),
                dataIndex: "boosted",
                render: (value) => (
                    <Switch
                        defaultChecked={value?.boosted}
                        size="small"
                        onChange={(active) => {
                            handleBoostedProfil(value?.id, active);
                        }}
                    />
                ),
            },
            {
                key: "createdAt",
                title: (
                    <h4>
                        Date de création
                    </h4>
                ),
                render: (value) => (
                    <p style={{ color: "#00000090" }}>
                        {moment(new Date(value.createdAt)).format("DD/MM/YYYY HH:mm:ss")}
                    </p>
                ),
            },
            {
                key: "actions",
                title: (
                    <h4>
                        Actions
                    </h4>
                ),
                fixed: "right",
                render: (value) => (
                    <div>
                        <EyeInvisibleOutlined
                            style={{ cursor: "pointer" }}
                            onClick={() => history.push('/dashboard/detail/profil/'.concat(value.id))}
                        />
                        <Popovers
                            content={
                                <DeleteConfirmationPopup
                                    id={value?.id}
                                    nom={value?.nom}
                                    handleDelete={() => handleDeleteUser(value?.id)}
                                />
                            }
                        >
                            <DeleteOutlined style={{ cursor: "pointer" }} />
                        </Popovers>
                    </div>
                ),
            },
        ];

        const datas = users?.map((user) => ({
            id: user.id,
            key: user.id,
            image: user,
            nom: user.nom,
            prenom: user.prenom,
            compte: user,
            profil: user.profil.nom,
            profilActive: user,
            accountActive: user,
            boosted: user,
            createdAt: user.createdAt,
            actions: user,
        }));

        return <div>
            <Table bordered size="small" loading={loading} dataSource={datas} columns={columns} scroll={{ x: "900px" }} pagination={false} />
            <div className="mt-2">
                {totalPages > 1 && users.length > 0 && (
                    <MyPagination
                        currentPage={page}
                        setCurrentPage={setPage}
                        totalPages={totalPages}
                    />
                )}
            </div>
        </div>
    }


    return (
        <div style={{ marginTop: 10, background: "#fff", padding: "10px" }}>
            <div style={{ textDecoration: "underline", fontSize: "16px", fontWeight: "bold", margin: "10px 0px", color: ORANGE }}>List des utilisateurs</div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="mb-2">
                    Nombres d'utilisateurs:
                    <span style={{ fontWeight: "bold", marginLeft: "10px" }}>{totalElements} élément(s)</span>
                </div>
            </div>
            <div>
                {Results()}
            </div>
        </div>
    );
};

export default AdminUser