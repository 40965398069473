import React from "react";
import { BASE_PATH } from "../utils/api.route";

const NotFound = () => {
  return <div style={{ textAlign: 'center' }}>
    <img style={{ width: "500px" }} src={BASE_PATH.concat("/img/something-lost.png")} />
    <div style={{ fontWeight: "bold", fontSize: "22px", margin: "0px auto" }}>Oops,Désoler</div>
    <div style={{ fontSize: "20px", margin: "20px auto" }}>Vous n'etes pas autorisé a accéder cette page.</div>
  </div>
};

export default NotFound;
