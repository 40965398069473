import { Popconfirm, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useParams } from 'react-router-dom'
import tokenStore from '../stores/tokenStore'
import { DOMAINES_ROUTE, PUBS_ROUTE } from '../utils/api.route'
import PageNotFound from './404'
import { IMAGES_ROUTE } from '../utils/api.route'
import { GREEN, RED } from '../theme/couleurs'

const AdminPub = () => {
    const token = tokenStore(state => state.token)
    const [banners, setBanners] = useState([])
    const [fileList, setFileList] = useState([
        // {
        //     uid: '-1',
        //     name: 'image.png',
        //     status: 'done',
        //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
    ]);

    const loadBannerPubs = () => {
        fetch(PUBS_ROUTE.concat('?type=').concat('BANNER'), {
            method: "get",
            headers: {
                "Authorization": "Bearer ".concat(token)
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.error)
                    throw new Error(response.message ? response.message : "Erreur de récuperation des bannières publicitaires")
                setBanners(response.pubs)
                if (response.pubs.length > 0) {
                    setFileList(response.pubs.map(pub => ({ uid: pub.id, name: pub.image.name, status: 'done', url: pub.image.url })))
                }
            }).catch(err => {
                
                NotificationManager.error(err.message)
            })
    }

    const onChange = value => {
        setFileList(value.fileList)
        handleUpdateBannieres(value.file && value.file.response && value.file.response.id)
    }

    const handleUpdateBannieres = (imageId) => {
        if (imageId) {
            fetch(PUBS_ROUTE, {
                method: "post",
                body: JSON.stringify({ imageId: imageId, type: "BANNER" }),
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer ".concat(token)
                }
            })
                .then(response => response.json())
                .then(response => {
                    if (response.error)
                        throw new Error(response.message ? response.message : "Erreur d'upload")
                    loadBannerPubs()
                    NotificationManager.success("Upload success")
                })
                .catch(err => {
                    
                    NotificationManager.error(err.message)
                })
        }
    }

    const handleDeletePub = imageId => {
        if (imageId) {
            fetch(PUBS_ROUTE.concat('?pubId=').concat(imageId), {
                method: "delete",
                headers: {
                    "Authorization": "Bearer ".concat(token)
                }
            })
                .then(response => response.json())
                .then(response => {
                    if (response.error)
                        throw new Error(response.message ? response.message : "Erreur d'upload")
                    NotificationManager.success("Upload success")
                    loadBannerPubs()
                })
                .catch(err => {
                    
                    NotificationManager.error(err.message)
                })
        }
    }

    const handleRemovePub = response => {
        handleDeletePub(response.uid)
    }

    useEffect(() => {
        loadBannerPubs()
    }, []);
    return <div>

        <div>
            <h4 className='fs-6 mt-2'>Bannière Publicitaires</h4>
            <div className='mt-4'>
                <div className='mb-4'>
                    <div>Choisir les images pour la bannière Publicitaires </div>
                    <div className='text-muted'>L'image doit avoir une Hauteur de  <span className='fw-bold' style={{ color: RED }}>350</span> et une Largeur de <span className='fw-bold' style={{ color: RED }}>1165</span></div>
                </div>
                <Upload
                    action={IMAGES_ROUTE.concat('/single').concat('?nom=pub')}
                    listType="picture-card"
                    fileList={fileList}
                    onRemove={handleRemovePub}
                    onChange={onChange}
                    headers={
                        {
                            "Authorization": "Bearer ".concat(token)
                        }
                    }
                >

                    {fileList.length < 5 && '+ Upload'}
                </Upload>
            </div>
            {/* <button className='btn btn-sm btn-outline-success' onClick={handleUpdateBannieres}>Mèttre à jour l'images de la bannière </button> */}
        </div>
    </div>
}


const AdminSetting = () => {
    const { page } = useParams()
    const token = tokenStore(state => state.token)

    const initCategorie = () => {
        if (token) {
            fetch(DOMAINES_ROUTE.concat("/initDatas"), { method: "post", headers: { Authorization: "Bearer ".concat(token) } })
                .then(response => response.json())
                .then(response => {
                    if (response.error) throw new Error(response.message ? response.message : " Erreur d'initialisation !")
                    NotificationManager.success(response.message)
                })
                .catch(err => {
                    
                    NotificationManager.error(err.message)
                })
        }
    }


    if (!page)
        return <PageNotFound />

    if (page === "db") {
        return <div>
            <p> Initialiser les catégories dans la base de données !</p>
            <Popconfirm
                onConfirm={initCategorie}
                title="Etes-vous sûr de vouloir Initialiser les catégories ? "
            >
                <button className="btn btn-success btn-sm" > Initialiser</button>
            </Popconfirm>
        </div>
    }

    if (page == "boosts") {
        return <div> Page de boost</div>
    }

    if (page === "pubs") {
        return <AdminPub />
    }

    return <PageNotFound />
}

export default AdminSetting